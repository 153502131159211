<template>
    <div class="card pt-3">
        <Toast />
        <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
            <template #start>
                <div style="display: flex; align-items: flex-start;">
                    <div>
                        <h5 style="margin: 0;">Documentos adjuntos</h5>
                    </div>
                </div>
            </template>
        </Toolbar>
        <DataTable ref="dtPersonal" 
            :value="items"
            dataKey="PersonId"
            :rowHover="true"
            :loading="bCargando"
            :filters="filtros"
            class="p-datatable-sm"
            >
            <Column field="documentIndex" header="#" headerStyle="width: 1rem"></Column>
            <Column field="documentName" header="Documento" ></Column>
            <Column field="documentStatus" header="Estado adjunto" bodyClass="text-left">
                <template #body="slotProps">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.documentStatus, 'pi-times-circle text-red-500': !slotProps.data.documentStatus }"></i>
                </template>
            </Column>
            <Column field="Url" header="Visualización">
                <template #body="slotProps">
                    <Button v-if="slotProps.data.documentStatus === 0" icon="pi pi-cloud-upload" v-tooltip.top="'Subir archivo'" outlined class="p-button-rounded p-button-danger mr-2" 
                    @click="UploadDocument(slotProps.data)"/>
                    <Button v-if="slotProps.data.documentStatus === 1" icon="pi pi-eye" v-tooltip.top="'Ver archivo'" class="p-button-rounded p-button-secondary mr-2" 
                    @click="OpenDocument(slotProps.data)"/>
                    <Button v-if="slotProps.data.documentStatus === true" disabled="true" icon="pi pi-cloud-upload" v-tooltip.top="'Subir archivo'" outlined class="p-button-rounded p-button-default mr-2" 
                    @click="UploadDocument(slotProps.data)"/>
                </template>
            </Column>
            <Column field="lastModification" header="Última modificación">
                <template #body="slotProps">
                    {{ Utilitario.formatoFecha(slotProps.data.lastModification, 'dd/MM/yyyy HH:mm:ss') }}
                </template>
            </Column>
        </DataTable>
    </div>

    <Dialog v-model:visible="modalUploadDocument" modal header="Subir archivo" :style="{ width: '40rem' }">
        <span class="p-text-secondary block mb-5">Seleccione el archivo para el documento.</span>
        <div class="row">
            <FileUpload v-if="!loading"
                name="filename[]" 
                @uploader="UploadDocumentFile" 
                :multiple="false" 
                accept=".pdf"
                :maxFileSize="1000000"
                chooseLabel="Seleccionar"
                uploadLabel="Subir"
                cancelLabel="Cancelar"
                :fileLimit="1"
                invalidFileLimitMessage="Se superó el número máximo de archivos; el límite es 1 como máximo"
                customUpload
            >
                <template #empty>
                    <p>Arrastre y suelte archivos aquí para cargarlos.</p>
                </template>
            </FileUpload>

            <!-- Mostrar spinner de carga mientras el archivo se sube -->
            <ProgressSpinner v-if="loading" style="width: 50px; height: 50px;" />

            <!-- Mensaje de carga completada -->
            <p v-if="loading">Subiendo el archivo...</p>
            <p v-if="progress === 100">¡Carga completada!</p>
        </div>
        <div class="flex justify-content-end gap-2 mt-3">
            <Button type="button" label="Cerrar" severity="secondary" @click="modalUploadDocument = false"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import DocumentService from '@/service/DocumentService';
import FileService from '@/service/FileService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineProps, defineEmits } from 'vue';

const toast = useToast();

const fileService = new FileService();
const documentService = new DocumentService();

const filtros = ref({});
const bCargando = ref(false);
const modalUploadDocument = ref(false);
const oDocument = ref({});

const loading = ref(false);
const progress = ref(0);

const emit = defineEmits(['updateBase']);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

const props = defineProps({
    items: {
        type: Array,
        Required: true
    },
    ppKey: {
        type: Number,
        required: true
    },
    ppPositionId: {
        type: Number,
        required: true
    },
    ppPersonId: {
        type: Number,
        required: true
    },
    ppDocumentId: {
        type: String,
        required: true
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenDocument = async(item) => {
    try{
        // Descargar el archivo
        const blob = await fileService.DowloadViewFileService(item.documentPath, 'V');

        // Si el archivo está en base64, decodificarlo
        let pdfBlob;
        if (blob.type === "application/pdf") {
            // Si el tipo es PDF, creamos un URL para el blob
            pdfBlob = blob;
        } else {
            // Si el archivo está en base64, decodificarlo a binario
            const base64Data = await blob.text(); // Asumimos que el archivo viene como texto base64
            const binary = atob(base64Data);
            const length = binary.length;
            const arrayBuffer = new ArrayBuffer(length);
            const view = new Uint8Array(arrayBuffer);

            // Convertimos el base64 a binario
            for (let i = 0; i < length; i++) {
                view[i] = binary.charCodeAt(i);
            }
            pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
        }

        // Crear una URL para el archivo PDF
        const url = window.URL.createObjectURL(pdfBlob);

        // Abrir el archivo PDF en una nueva ventana o pestaña
        window.open(url, '_blank');
        
    }
    catch (error) {
        console.error('Error en OpenDocument:', error);
    } finally {
        bCargando.value = false;
    }
}

const UploadDocument = (item) => {
    oDocument.value = item;
    modalUploadDocument.value = true;
    progress.value = 0;
    loading.value = false;
}

const UploadDocumentFile = async(event) => {
    try {
        if(props.ppDocumentId === 'PERSON'){
            await UploadDocumentFilePerson(event);
        }
        else if(props.ppDocumentId === 'COURSE'){
            await UploadDocumentFileCourse(event);
        } 
        else if(props.ppDocumentId === 'CERTIFICATE'){
            await UploadDocumentFileCertificate(event);
        }
        else if(props.ppDocumentId === 'EMO'){
            await UploadDocumentFileEmo(event);
        }
        else if(props.ppDocumentId === 'POLICY'){
            await UploadDocumentFilePolicy(event);
        }
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}

const UploadDocumentFilePerson = async(event) => {
    try {
        loading.value = true;
        progress.value = 10;
        let formData = new FormData();
        event.files.forEach((file) => {
            formData.append('File', file, file.name);
            formData.append('PersonId', props.ppPersonId);
            formData.append('DocumentId', oDocument.value.documentId);
        });
        progress.value = 25;
        let response = await documentService.UploadDocumentPersonService(formData);
        
        progress.value = 90;
        if(response.code == 200){
            progress.value = 100;
            loading.value = false;
            modalUploadDocument.value = false;
            emit('updateBase', response);
            toast.add({ severity: 'success', summary: 'Success', detail: 'El archivo se subió correctamente', life: 3000 });
        } 
                    
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}

const UploadDocumentFileCourse = async(event) => {
    try {
        loading.value = true;
        progress.value = 10;
        let formData = new FormData();
        event.files.forEach((file) => {
            formData.append('File', file, file.name);
            formData.append('PersonId', props.ppPersonId);
            formData.append('CourseId', oDocument.value.documentId);
        });
        progress.value = 25;
        let response = await documentService.UploadDocumentCourseService(formData);
        
        progress.value = 90;
        if(response.code == 200){
            progress.value = 100;
            loading.value = false;
            modalUploadDocument.value = false;
            emit('updateBase', response);
            toast.add({ severity: 'success', summary: 'Success', detail: 'El archivo se subió correctamente', life: 3000 });
        } 
                    
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}

const UploadDocumentFileCertificate = async(event) => {
    try {
        loading.value = true;
        progress.value = 10;
        let formData = new FormData();
        event.files.forEach((file) => {
            formData.append('File', file, file.name);
            formData.append('PersonId', props.ppPersonId);
            formData.append('CertificateId', oDocument.value.documentId);
        });
        progress.value = 25;
        let response = await documentService.UploadDocumentCertificateService(formData);
        
        progress.value = 90;
        if(response.code == 200){
            progress.value = 100;
            loading.value = false;
            modalUploadDocument.value = false;
            emit('updateBase', response);
            toast.add({ severity: 'success', summary: 'Success', detail: 'El archivo se subió correctamente', life: 3000 });
        } 
                    
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}

const UploadDocumentFileEmo = async(event) => {
    try {
        loading.value = true;
        progress.value = 10;
        let formData = new FormData();
        event.files.forEach((file) => {
            formData.append('File', file, file.name);
            formData.append('PersonId', props.ppPersonId);
            formData.append('MedicalExaminationId', oDocument.value.documentId);
        });
        progress.value = 25;
        let response = await documentService.UploadDocumentEmoService(formData);
        
        progress.value = 90;
        if(response.code == 200){
            progress.value = 100;
            loading.value = false;
            modalUploadDocument.value = false;
            emit('updateBase', response);
            toast.add({ severity: 'success', summary: 'Success', detail: 'El archivo se subió correctamente', life: 3000 });
        } 
                    
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}

const UploadDocumentFilePolicy = async(event) => {
    try {
        loading.value = true;
        progress.value = 10;
        let formData = new FormData();
        event.files.forEach((file) => {
            formData.append('File', file, file.name);
            formData.append('PersonId', props.ppPersonId);
            formData.append('InsurancePolicyId', oDocument.value.documentId);
        });
        progress.value = 25;
        let response = await documentService.UploadDocumentPolicyService(formData);
        
        progress.value = 90;
        if(response.code == 200){
            progress.value = 100;
            loading.value = false;
            modalUploadDocument.value = false;
            emit('updateBase', response);
            toast.add({ severity: 'success', summary: 'Success', detail: 'El archivo se subió correctamente', life: 3000 });
        } 
                    
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
