export default class MedicalExaminationService {
    async GetMedicalExaminationService(emoId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/MedicalExamination?MedicalExaminationId=${emoId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async CreateMedicalExaminationService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/MedicalExamination/CreateUpdateMedicalExamination', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async UpdateStatusMedicalExaminationService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/MedicalExamination/Status', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async CreateUpdateMedicalExaminationObservationService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/MedicalExamination/CreateUpdateMedicalExaminationObservation', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async UpdateStatusMedicalExaminationObservationService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/MedicalExamination/StatusMedicalExaminationObservation', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }
}