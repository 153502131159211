export default class DocumentService {
    async GetDocumentService() {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async GetDocumentPersonService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document/Person?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async UploadDocumentPersonService(formData) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Document/upload-person', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
            body: formData
        });

        return response.json();
    }

    async GetDocumentCourseService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document/Course?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async UploadDocumentCourseService(formData) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Document/upload-course', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
            body: formData
        });

        return response.json();
    }

    async GetDocumentCertificateService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document/Certificate?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async UploadDocumentCertificateService(formData) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Document/upload-certificate', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
            body: formData
        });

        return response.json();
    }

    async GetDocumentEmoService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document/MedicalExamination?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async UploadDocumentEmoService(formData) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Document/upload-medicalexamination', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
            body: formData
        });

        return response.json();
    }

    async GetDocumentPolicyService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Document/InsurancePolicy?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async UploadDocumentPolicyService(formData) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Document/upload-insurancepolicy', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
            body: formData
        });

        return response.json();
    }
}