<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <Button icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                            <div class="ml-3">
                                <h3 style="margin: 0;">{{ oProject.serviceName }}</h3>
                                <p style="margin: 0;">{{ oProject.projectName  }} - {{ oProject.projectCode }}</p>
                            </div>
                        </div>
                    </template>
                    <template #end>
                        <div class="ml-3">
                            <p style="margin: 0;">Fecha de inicio: {{ Utilitario.formatoFecha(oProject.projectStartDate, "dd/MM/yyyy") }}</p>
                        </div>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <TabView>
                    <TabPanel header="ACREDITACIÓN" v-if="ssPermissionPnlAcre.viewEnabled">
                        <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
                            <div class="grid">
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.percentageRequired }}%</b></div>
                                                <div class="font-medium text-sm">{{ oProject.coincidences }} de {{ oProject.totalRequired }} requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-file text-green-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.totalRequired }}</b></div>
                                                <div class="font-medium text-sm">Requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user text-cyan-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.totalAccredited }}</b></div>
                                                <div class="font-medium text-sm">Acreditado</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.coincidences }}</b></div>
                                                <div class="font-medium text-sm">Coincidencias</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>               
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 xl:col-12 m-0 p-0">
                            <div class="card">
                                <DataTable ref="dtPosition" 
                                    v-model:expandedRows="expandedRows" 
                                    :value="oProject.jobPositions"
                                    dataKey="jobPositionId"
                                    :loading="bCargando"                                    
                                    class="p-datatable-sm"
                                    :filters="filtrosGeneral"
                                    >
                                    <template #header>
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <h5 class="m-0">Personal seleccionado</h5>
                                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                <InputText v-model="filtrosGeneral['global'].value" placeholder="Buscar..." />
                                            </span>
                                        </div>
                                    </template>

                                    <Column expander style="width: 5rem" />
                                    <Column field="name" header="Puesto">
                                        <template #body="slotProps">
                                            {{ slotProps.data.job.name || '' }}
                                        </template>
                                    </Column>
                                    <Column field="requiredQuantity" header="Requerimiento"></Column>
                                    <Column field="experienceTime" header="Personal">
                                        <template #body="slotProps">
                                            {{ slotProps.data.persons.length }}
                                        </template>
                                    </Column>
                                    <Column field="experienceTime" header="Años Exp."></Column>
                                    <Column field="status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="(slotProps.data.requiredQuantity == slotProps.data.persons.length) ? 'pi-check-circle text-green-500 ': 'pi-times-circle text-red-500'"></i>
                                            <!-- <i class="pi" :class="(slotProps.data.status && (slotProps.data.requiredQuantity <= slotProps.data.persons.length)) ? 'pi-check-circle text-green-500 ': 'pi-times-circle text-red-500'"></i> -->
                                        </template>
                                    </Column>

                                    <template #expansion="slotProps">
                                        <Button label="Agregar" icon="pi pi-plus" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                                @click="openFindPersonal(slotProps.data)" v-if="ssPermissionPnlAcreJob.createEnabled" />
                                        <div class="p-4" v-if="slotProps.data.persons.length > 0">
                                            <DataTable 
                                                :value="slotProps.data.persons" 
                                                :filters="filtros"
                                                :rowHover="true">
                                                <template #header>
                                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                                        <h5 class="m-0">Personal seleccionado</h5>
                                                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                            <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                                        </span>
                                                    </div>
                                                </template>

                                                <Column style="text-align: center;" headerStyle="min-width:4rem;">
                                                    <template #body="slotPropsDetail">
                                                        <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                                                                @click="ViewAccreditationPersonal(slotPropsDetail.data, slotProps.data)" v-if="ssPermissionPnlAcreJob.viewEnabled" />
                                                        <Button @click="DeletePersonal(slotProps.data, slotPropsDetail.data)" icon="pi pi-trash" v-tooltip.top="'Eliminar'" 
                                                                class="p-button-rounded mt-2" :class="'p-button-rounded p-button-danger'" v-if="ssPermissionPnlAcreJob.deleteEnabled" />
                                                    </template>
                                                </Column>
                                                <Column field="documentNumber" header="DNI" >
                                                    <template #body="slotPropsDetail">
                                                        <span v-tooltip.top="`Fecha Vencimiento:\n${ slotPropsDetail.data.ExpirationDate }`">{{ slotPropsDetail.data.documentNumber }}</span>
                                                    </template>
                                                </Column>
                                                <Column field="personFullName" header="Nombre y apellido">
                                                    <template #body="slotPropsDetail">
                                                        <Button :label="slotPropsDetail.data.personFullName" :class="'text-left'" link @click="ViewAccreditationPersonal(slotPropsDetail.data, slotProps.data)" />
                                                    </template>
                                                </Column>
                                                <Column field="typeOfContract" header="Tipo trabajador"></Column>
                                                <Column field="mineCondition" header="Condición de mina"></Column>
                                                <Column field="DocumentNumber" header="Docs." bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.documents.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.documents.every(item => item.status === true) }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.documents)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="DocumentNumber" header="Cursos" bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.courses.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.courses.every(item => item.status === true) }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.courses)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="DocumentNumber" header="Certi." bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.certificates.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.certificates.every(item => item.status === true) }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.certificates)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="Status" header="EMOs" bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.medicalExaminations.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.medicalExaminations.every(item => item.status === true) }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.medicalExaminations)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="statusPersonName" header="Estado" bodyClass="text-center">
                                                    <template #body="slotPropsDet">
                                                        <div class="centered-content">
                                                            <Tag v-if="slotPropsDet.data.statusPersonName=='Disponible'" value="Disponible" severity="success" />
                                                            <Tag v-else-if="slotPropsDet.data.statusPersonName=='Acreditado'" value="Acreditado" severity="info" />
                                                            <Tag v-else-if="slotPropsDet.data.statusPersonName=='Vigente'" value="Vigente" severity="warning" />
                                                            <Tag v-else :value="slotPropsDet.data.statusPersonName" severity="secondary" />
                                                        </div>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </template>                                    
                                </DataTable>
                            </div>
                            
                            <OverlayPanel ref="op" appendTo="body">
                                <DataTable :value="documents" selectionMode="single" >
                                    <Column field="name" header="Documento" style="min-width: 12rem"></Column>
                                    <Column field="status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.status, 'pi-times-circle text-red-500': !slotProps.data.status }"></i>
                                        </template>
                                    </Column>
                                </DataTable>
                            </OverlayPanel>

                        </div>
                    </TabPanel>
                    <TabPanel header="GASTOS DE MOVILIZACIÓN" v-if="ssPermissionAccreditationCoasts.viewEnabled">
                        <AccreditationCoasts :ppKey="Number(pKey)" :ppProjectId="Number(projectIdEdit)" />
                    </TabPanel>
                </TabView>
            </div>
        </div>
        
        <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar personal" :style="{ width: '70rem' }">
            <SearchPersonal @closeModal="closeModal" @sendList="AddPersonal" :ppProjectId="Number(projectIdEdit)" :ppKey="Number(pKey)" :ppJobPositionId="Number(positiojJobSelected)" :ppJobPositionName="positionJobNameSelected" /> 
        </Dialog>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SearchPersonal from '../Personal/SearchPersonal.vue';
import AccreditationCoasts from '../Accreditation/AccreditationCoasts.vue';
import ProjectService from '@/service/ProjectService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { useStore } from 'vuex';
import { useConfirm } from 'primevue/useconfirm';
import PersonService from '@/service/PersonService';

const router = useRouter();
const route = useRoute();
const store = useStore();
const confirm = useConfirm();

const projectService = new ProjectService();
const personService = new PersonService();

const userStore = JSON.parse(store.state.datauser);
const ssPermissionPnlAcre = ref({});
const ssPermissionPnlAcreJob = ref({});
const ssPermissionAccreditationCoasts = ref({});

const filtros = ref({});
const filtrosGeneral = ref({});

const oProject = ref({
    projectId: 0,
    projectCode: '',
    projectName: '',
    projectStartDate: '',
    percentageRequired: 0.0,
    totalRequired: 0,
    coincidences: 0,
    totalAccredited: 0
});

const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListPersonalSelected = ref([]);
const expandedRows = ref({});
const op = ref();
const documents = ref([]);

const projectIdEdit = ref(0);
const pKey = ref(0);
const positiojJobSelected = ref(0);
const positionJobNameSelected = ref('');

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadAccreditation();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };

    filtrosGeneral.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermissionPnlAcre.value = Utilitario.obtenerPermisos('PR00003');
    ssPermissionAccreditationCoasts.value = Utilitario.obtenerPermisos('PR00004');
    ssPermissionPnlAcreJob.value = Utilitario.obtenerPermisos('PR00005');
}

const IrAtras = () => {
    router.push({ path: `/portal` });
}

const closeModal = () => {
    modalSearchPersonal.value = false;
    LoadAccreditation();
}


const LoadAccreditation = async() => {
    bCargando.value = true;
    projectIdEdit.value = route.params.id ? Crypto.Desencriptar(route.params.id) : 0;
    const response = await projectService.GetProjectAccreditationService(projectIdEdit.value).then();
    if(response.code == 200){
        oProject.value = response.data;
        bCargando.value = false;
        pKey.value++;
    }

}

const toggle = (event, olista) => {
    documents.value = olista;
    op.value.toggle(event);
};

const AddPersonal = (list) => {
    console.log(list.value);
  oListPersonalSelected.value = list.value; // Asigna la lista recibida a la variable reactiva
};

const ViewAccreditationPersonal = (item, itemPrimary) => {
    const projectId = encodeURIComponent(Crypto.Encriptar(projectIdEdit.value));
    const positionId = encodeURIComponent(Crypto.Encriptar(itemPrimary.jobPositionId));
    const personId = encodeURIComponent(Crypto.Encriptar(item.personId));
    router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}/${personId}` });
}


const openFindPersonal = (item) => {
    positiojJobSelected.value = item.jobPositionId;
    positionJobNameSelected.value = item.name;
    modalSearchPersonal.value = true
    pKey.value++;
}

const DeletePersonal = (item, detail) => {

    const request = {
        jobPositionId: item.jobPositionId,
        personId: detail.personId,
        status: false,
        creationUser: userStore.user.idUser
    };
  
  confirm.require({
      message: `¿Está seguro de eliminar a ${detail.personFullName} del puesto ${item.name}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await personService.UpdateStatusToJobPositionService(request).then();
          if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se eliminó al personal correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    LoadAccreditation();
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
