<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" 
                            @click="OpenServiceNew" v-if="ssPermission.createEnabled" />
                    </template>
                </Toolbar>
                
                <DataTable ref="dtService" 
                    :value="oListService"
                    :dataKey="ServiceId"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Servicio</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column style="text-align: left;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success ml-5" 
                                @click="EditProject(slotProps.data.projectId)" v-if="ssPermission.editEnabled"  />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.Status ? 'Desactivar' : 'Activar'" 
                                class="p-button-rounded mt-2" :class="slotProps.data.Status ? 'p-button-danger' : 'p-button-secondary'" 
                                @click="UpdateStatusProject(slotProps.data)" v-if="ssPermission.deleteEnabled"  />
                            <Button icon="pi pi-clone" v-tooltip.top="'Duplicar proyecto'" class="p-button-rounded p-button-success mr-2" 
                                @click="DuplicateProject(slotProps.data)" v-if="slotProps.data.isCompleted && ssPermissionClone.viewEnabled" />
                        </template>
                    </Column>
                    <Column field="code" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.service?.name || '' }}</b><br/>
                            <span style="font-size: 8.5pt;">{{ slotProps.data.name }}</span>
                        </template>
                    </Column>
                    <Column field="enterpriseName" header="Empresa"></Column>
                    <Column field="Status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import { useLayout } from '@/layout/composables/layout';
import ProjectService from '@/service/ProjectService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
const confirm = useConfirm();
const { onMenuToggleFalse } = useLayout();

const projectService = new ProjectService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});
const ssPermissionClone = ref({});

const bCargando = ref(false);
const filtros = ref({});

const oListService = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    onMenuToggleFalse(false);
    LoadPermission();
    InitializeFilters();
    LoadProject();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00012');
    ssPermissionClone.value = Utilitario.obtenerPermisos('PR00013');
}

const OpenServiceNew = () => {
    router.push({ path: `/portal/proyecto/nuevo` });
}

const LoadProject = async() => {
    const request = userStore.enterprise.enterpriseId;
    const response = await projectService.GetProjectByEnterpriseService(request).then();
    if(response.code == 200 && response.data.length > 0){
        oListService.value = response.data;
    }
    bCargando.value = false;
}

const EditProject = (projectId) => {
    const id = encodeURIComponent(Crypto.Encriptar(projectId));
    const step = encodeURIComponent(Crypto.Encriptar(0));
    router.push({ path: `/portal/proyecto/editar/${id}/${step}` });
}

const UpdateStatusProject = (item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';
    const request = {
        projectId: item.projectId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} el proyecto ${item.name}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await projectService.UpdateStatusProjectService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el proyecto ${item.name} correctamente`,
                accept: async() => {
                    LoadProject();
                    bCargando.value = false;
                }
            });
        }
      }
    });
}

const DuplicateProject = (item) => {
    const request = {
        projectId: item.projectId,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de duplicar el proyecto ${item.name}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await projectService.DuplicateProjectService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se duplicó el proyecto ${item.name} correctamente`,
                accept: async() => {
                    LoadProject();
                    bCargando.value = false;
                }
            });
        }
      }
    });
}

//#endregion
</script>