<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nuevo Usuario</h1>
                    </template>

                    <template #end>
                    </template>
                </Toolbar>
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.documentNumber" 
                                id="txtDocumentNumber" autocomplete="off" :class="{ 'p-invalid' : validatedDocumentNumber }" />
                                <label for="lblDocumentNumber">DNI</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-8"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.firstName" 
                                    id="txtFirstName" autocomplete="off" :class="{ 'p-invalid' : validatedFirstName }" />
                                <label for="lblFirstName">Nombres</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.lastName" id="txtLastName" autocomplete="off" />
                                <label for="lblLastName">Apellidos</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.email" id="txtEmail" autocomplete="off" />
                                <label for="lblEmail">Correo corporativo</label>
                            </FloatLabel>
                        </div>                        
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.cellPhone" id="txtCellphone" autocomplete="off" />
                                <label for="lblCellphone">Número de contacto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListRole" v-model="oUser.idUserType" :class="{ 'p-invalid' : validatedRole }"
                                    optionLabel="name" optionValue="idUserType" class="w-full"  />
                                <label for="lblRole">Rol de usuario</label>
                            </FloatLabel>
                        </div>
                
                        <Divider layout="horizontal" />
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oUser.login"
                                    id="txtLogin" autocomplete="off" :class="{ 'p-invalid' : validatedUser }" />
                                <label for="lblLogin">Usuario</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" v-model="oUser.expiredDate"
                                    inputId="dtpExpiredDate" dateFormat="dd/mm/yy" />
                                <label for="lblExpiredDate">Fecha expiración</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        
                    </div>
                </div>

                <Divider layout="horizontal" />
                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="SaveUser()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>


  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import RoleService from '@/service/RoleService';
import UserService from '@/service/UserService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const userService = new UserService();
const roleService = new RoleService();

const userStore = JSON.parse(store.state.datauser);

const oListRole = ref([]);
const bCargando = ref(false);
const oUser = ref({});

const validatedDocumentNumber = ref(false);
const validatedFirstName = ref(false);
const validatedUser = ref(false);
const validatedRole = ref(false);

const userEditId = ref('');


//#region Eventos
onMounted(() => {
    Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
    LoadRole();
    CambiarEspanol();
    userEditId.value = Crypto.Desencriptar(route.params.id);
    if(!Utilitario.StringIsNullOrEmpty(userEditId.value)){
        LoadUsers(userEditId.value);
    }
} 

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";

}

const BackTo = () => {
    router.push({ path: `/portal/seguridad/usuario/mantenedor` });
}

const LoadUsers = async(id) => {
  bCargando.value = true;
    const response = await userService.GetUserService(id).then();
    if(response.code == 200){
        oUser.value = response.data;
        oUser.value.expiredDate = response.data.expiredDate == '0001-01-01T00:00:00' ? null : Utilitario.formatoDate(response.data.expiredDate);
        bCargando.value = false;
    }
}

const LoadRole = async() => {
    bCargando.value = true;
    const response = await roleService.GetRoleService(0).then();
    if(response.code == 200){
      oListRole.value = response.data;
      bCargando.value = false;
    }
}

const SaveUser = async() => {
    if(await ValidateUser()){
        CreateUpdateUser();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const ValidateUser = async() => {
  const { ...model } = oUser.value;
  const valid = ref(false);
    
  if(!model?.documentNumber){
    validatedDocumentNumber.value = true;
    valid.value = true;
  }
  else{
    validatedDocumentNumber.value = false;
  }

  if(!model?.firstName){
    validatedFirstName.value = true;
    valid.value = true;
  }
  else{
    validatedFirstName.value = false;
  }
  
  if(!model?.idUserType){
    validatedRole.value = true;
    valid.value = true;
  }
  else{
    validatedRole.value = false;
  }

  if(!model?.login){
    validatedUser.value = true;
    valid.value = true;
  }
  else{
    validatedUser.value = false;
  }
  
  return !valid.value;
}

const CreateUpdateUser = async() => {
  const { ...model } = oUser.value;
  
  const request = {
    ...model,
    action: model.idUser ? 'U' : 'N',
    creationUser: userStore.user.idUser
  };
  
  confirm.require({
      message: `¿Está seguro de guardar el Usuario?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await userService.CreateUpdateUserService(request).then();
          if(response.code == 200){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se guardó el Usuario ${request.login} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    BackTo();
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}

//#endregion

</script>