<template>
    <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>S/. {{ oProject.costSoles }}</b></div>
                            <div class="font-medium text-sm">Gastos en Soles</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-file text-green-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>$ {{ oProject.costDollars }}</b></div>
                            <div class="font-medium text-sm">Gastos en Dólares</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-user text-cyan-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <div class="text-900 font-bold text-2xl"><b>{{ oProject.totalAccreditated }}</b></div>
                            <div class="font-medium text-sm">Acreditado</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-12 m-0 p-0">
        <div class="card">
            <DataTable ref="dtPersonal" 
                :value="oProject.persons"
                dataKey="PersonId"
                :rowHover="true"
                :loading="bCargando"
                :filters="filtros"
                class="p-datatable-sm"
                >

                <template #header>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Personal acreditado</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                        </span>
                    </div>
                </template>

                <Column style="text-align: center;" headerStyle="min-width:5rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                            @click="ViewAccreditationPersonal(slotProps.data)" v-if="ssPermission.viewEnabled" />
                    </template>
                </Column>
                <Column field="documentNumber" header="DNI" >
                    <template #body="slotProps">
                        <span>{{ slotProps.data.documentNumber }}</span>
                    </template>
                </Column>
                <Column field="personFullName" header="Nombre y Apellido">
                    <template #body="slotProps">
                        <Button :label="slotProps.data.personFullName" link @click="ViewAccreditationPersonal(slotProps.data)" />
                    </template>
                </Column>
                <Column field="typeOfWorkerName" header="Tipo trabajador"></Column>
                <Column field="mineCondition" header="Condición mina"></Column>
                <Column field="originPlace" header="Lugar Origen"></Column>
                <Column field="period" header="Período"></Column>
                <Column field="statusPersonName" header="Estado" bodyClass="text-center">
                    <template #body="slotProps">
                        <div class="centered-content">
                            <Tag :value="slotProps.data.statusPersonName" severity="success" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        
        <OverlayPanel ref="op" appendTo="body">
            <DataTable :value="documents" selectionMode="single" >
                <Column field="Name" header="Documento" style="min-width: 12rem"></Column>
                <Column field="Status" header="Estado" bodyClass="text-center">
                    <template #body="slotProps">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Status, 'pi-times-circle text-red-500': !slotProps.data.Status }"></i>
                    </template>
                </Column>
            </DataTable>
        </OverlayPanel>

    </div>
</template>


<script setup>
import ProjectService from '@/service/ProjectService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const projectService = new ProjectService();

const ssPermission = ref({});

const filtros = ref({});
const oProject = ref({});
const bCargando = ref(false);

const op = ref();
const documents = ref([]);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppProjectId: {
    type: Number,
    required: true
  }
});


//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadPersonal();
    }
});

//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00006');
}

const LoadPersonal = async() => {
    bCargando.value = true;
    const response = await projectService.GetProjectMovilizationService(props.ppProjectId).then();
    if(response.code == 200){
        oProject.value = response.data;
        bCargando.value = false;
    }
}


const ViewAccreditationPersonal = (item) => {
    const projectId = encodeURIComponent(Crypto.Encriptar(props.ppProjectId));
    const positionId = encodeURIComponent(Crypto.Encriptar(item.jobPositionId));
    const personId = encodeURIComponent(Crypto.Encriptar(item.personId));
    router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}/${personId}` });
}
//#endregion
</script>
