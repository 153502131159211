<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <h1>Nuevo Certificado</h1>
          </template>

          <template #end> </template>
        </Toolbar>
        <ConfirmDialog></ConfirmDialog>
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4" v-if="certificateEditId != ''">
              <FloatLabel>
                <InputText id="txtCode" autocomplete="off" v-model="oCertificate.code" readonly />
                <label for="lblCode">Código</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <InputText id="txtName" autocomplete="off" v-model="oCertificate.name" :class="{ 'p-invalid' : validatedName }" />
                <label for="lblName">Nombre</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>

            <div class="field col-12 md:col-8">
              <FloatLabel>
                <Textarea id="txtDescription" rows="2" cols="30" v-model="oCertificate.description" />
                <label for="lblDescription">Descripción</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Calendar
                  showIcon
                  iconDisplay="input"
                  inputId="dtpFechaFin"
                  dateFormat="dd/mm/yy"
                  v-model="oCertificate.startDate"
                  :class="{ 'p-invalid' : validatedStartDate }"
                />
                <label for="lblFechaFin">Fecha Inicio</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Calendar
                  showIcon
                  iconDisplay="input"
                  inputId="dtpFechaFin"
                  dateFormat="dd/mm/yy"
                  v-model="oCertificate.endDate"
                  :class="{ 'p-invalid' : validatedEndDate }"
                />
                <label for="lblFechaFin">Fecha Fin</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-2">
                <FloatLabel>
                    <InputNumber v-model="oCertificate.validationPeriodQuantity" inputId="minmax" showButtons mode="decimal" :min="0" :max="1000" 
                    :class="{ 'p-invalid' : validatedValidationPeriodQuantity }" />
                    <label for="lblValidationPeriodQuantity">Cantidad</label>
                </FloatLabel>
            </div>
            <div class="field col-12 md:col-2">
              <FloatLabel>
                <Dropdown
                  v-model="oCertificate.validationPeriodTypeId"
                  :options="oListPeriodType"
                  optionLabel="name"
                  optionValue="validationPeriodTypeId"
                  placeholder=""
                  class="w-full"
                  :class="{ 'p-invalid' : validatedValidationPeriodTypeId }"
                />
                <label for="lblPeriodType">Período de Vigencia</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  :options="oListMode"
                  optionLabel="name"
                  optionValue="modeId"
                  placeholder=""
                  class="w-full"
                  v-model="oCertificate.modeId"
                  :class="{ 'p-invalid' : validatedMode }"
                />
                <label for="lblMode">Modalidad</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-12">
              <div class="flex justify-content">
                <Checkbox v-model="checked" :binary="true" />
                <label for="ingredient4" class="ml-2">
                  Tiene Pre-Requisitos
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="checked">
          <Divider layout="horizontal" />
          <h5>Pre-Requisitos</h5>
          <div class="flex flex-column h-500rem mt-5">
            <div class="p-fluid p-formgrid grid">
              <div class="field col-12 md:col-2">
                <Button type="button" label="Agregar" icon="pi pi-plus"
                  class="p-button-info mr-2" @click="modalSearchCertificates = true" ></Button>
              </div>
              <div class="field col-12 md:col-8"></div>
              <div class="col-12 md:col-8">
                <DataTable
                  ref="dtDetalle"
                  :value="oCertificate.listPreRequisites"
                  dataKey="DetalleId"
                  :paginator="false"
                  :rows="10"
                  :rowsPerPageOptions="[10, 25, 50, 100]"
                  :loading="bCargando"
                  class="p-datatable-sm"
                >
                  <template #header>
                    <div
                      class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                    >
                      <h5 class="m-0">Detalle Requisitos</h5>
                    </div>
                  </template>

                  <Column style="text-align: center" headerStyle="min-width:12rem;">
                    <template #body="slotProps">
                      <Button icon="pi pi-trash" class="p-button-rounded mt-2 p-button-danger" @click="RemovePreRequisite(slotProps.data)" />
                    </template>
                  </Column>
                  <Column field="prerequisiteName" header="Nombre" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.prerequisiteName }}
                    </template>
                  </Column>
                  <Column field="mode" header="Modalidad">
                    <template #body="slotProps">
                      {{ slotProps.data.prerequisiteModeName }}
                    </template>
                  </Column>
                  <Column field="validityTime" header="Vigencia">
                    <template #body="slotProps">
                      {{ slotProps.data.prerequisiteValidityTimeName }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider layout="horizontal" />
        <div class="flex justify-content-start">
          <div class="flex justify-content-start">
            <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="SaveCertificate()" :loading="bCargando" />
            <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo" ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <Dialog v-model:visible="modalSearchCertificates" modal header="Buscar Certificado" :style="{ width: '70rem' }">
      <SearchCertificate @closeModal="closeModal" @sendList="AddCertificatePre" /> 
  </Dialog>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import CertificateService from "@/service/CertificateService";
import ModeService from "@/service/ModeService";
import Crypto from "@/utilitarios/Crypto";
import Utilitario from "@/utilitarios/Utilitario";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import SearchCertificate from "./SearchCertificate/SearchCertificate.vue";
import CommonService from "@/service/CommonService";

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const modeService = new ModeService();
const certificateService = new CertificateService();
const commonService = new CommonService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const certificateEditId = ref('');
const oListMode = ref([]);
const oListPeriodType = ref([]);

const validatedName = ref(false);
const validatedStartDate = ref(false);
const validatedEndDate = ref(false);
const validatedMode = ref(false);
const validatedValidationPeriodQuantity = ref(false);
const validatedValidationPeriodTypeId = ref(false);


const oCertificate = ref({
  certificateId: 0,
  code: '',
  description: ''
});

const modalSearchCertificates = ref(false);

const checked = ref(false);

//#region Eventos
onMounted(() => {
  Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
  LoadMode();
  LoadPeriodType();
  certificateEditId.value = Crypto.Desencriptar(route.params.id);
  if(!Utilitario.StringIsNullOrEmpty(certificateEditId.value)){
    LoadCertificate(certificateEditId.value);
  }
};

const BackTo = () => {
  router.push({ path: `/portal/certificaciones/mantenedor` });
};

const closeModal = () => {
  modalSearchCertificates.value = false;
}

const LoadPeriodType = async() => {
  bCargando.value = true;
    const response = await commonService.GetValidationPeriodTypeService().then();
    if(response.code == 200){
      oListPeriodType.value = response.data;
      bCargando.value = false;
    }
}

const LoadMode = async() => {
    bCargando.value = true;
    const response = await modeService.GetModeService().then();
    if(response.code == 200){
      oListMode.value = response.data;
      bCargando.value = false;
    }
}

const LoadCertificate = async(id) => {
  bCargando.value = true;
    const response = await certificateService.GetCertificateService(id).then();
    if(response.code == 200){
        oCertificate.value = response.data;
        oCertificate.value.startDate = Utilitario.formatoDate(response.data.startDate);
        oCertificate.value.endDate = Utilitario.formatoDate(response.data.endDate);
        oCertificate.value.validationPeriodTypeId = response.data.validationPeriodType.validationPeriodTypeId;
        oCertificate.value.modeId = response.data.mode.modeId;

        checked.value = response.data?.listPreRequisites.length > 0 ? true : false;
        bCargando.value = false;
    }
}

const SaveCertificate = async() => {
  if(await ValidateCertificate()){
    CreateUpdateCertificate();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const ValidateCertificate = async() => {
  const { ...model } = oCertificate.value;
  const valid = ref(false);
  
  if(!model?.name){
    validatedName.value = true;
    valid.value = true;
  }
  else{
    validatedName.value = false;
  }

  if(!model?.startDate){
    validatedStartDate.value = true;
    valid.value = true;
  }
  else{
    validatedStartDate.value = false;
  }

  if(!model?.endDate){
    validatedEndDate.value = true;
    valid.value = true;
  }
  else{
    validatedEndDate.value = false;
  }

  if(!model?.validationPeriodQuantity){
    validatedValidationPeriodQuantity.value = true;
    valid.value = true;
  }
  else{
    validatedValidationPeriodQuantity.value = false;
  }

  if(!model?.validationPeriodTypeId){
    validatedValidationPeriodTypeId.value = true;
    valid.value = true;
  }
  else{
    validatedValidationPeriodTypeId.value = false;
  }

  if(!model?.modeId){
    validatedMode.value = true;
    valid.value = true;
  }
  else{
    validatedMode.value = false;
  }

  return !valid.value;
}

const CreateUpdateCertificate = async() => {
  const { ...model } = oCertificate.value;
  
  if(model?.listPreRequisites){
    if(model.listPreRequisites.length > 0){
      model.listPreRequisites = model.listPreRequisites.map((item) => ({
        prerequisiteId: item.prerequisiteId,
        prerequisiteStatus: 1
      }));
    }
    else{
      model.listPreRequisites = [];
    }
  }else{
    model.listPreRequisites = [];
  }
  

  const request = {
    ...model,
    mode: {
      modeId: model.modeId,
      name: ''
    },
    renewalTime: {
      renewalTimeId: 1,
      name: ''
    },
    validationPeriodType: {
      validationPeriodTypeId: model.validationPeriodTypeId,
      name: ''
    },
    creationUser: userStore.user.idUser
  };
  
  confirm.require({
      message: `¿Está seguro de guardar el Certificado?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await certificateService.CreateUpdateCertificateService(request).then();
          if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se guardó el Certificado #${response.data.code} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    router.push({ path: `/portal/certificaciones/mantenedor` });
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}

const AddCertificatePre = async(data) => {
  const newList = ref([]);
  data.map(item => {
    const exists = oCertificate.value.listPreRequisites.find(x => x.prerequisiteId === item.certificateId);
    if(!exists){
      newList.value.push({
        prerequisiteId: item.certificateId,
        prerequisiteName: item.name,
        prerequisiteModeName: item.mode?.name || '',
        prerequisiteValidityTimeName: item.validityTime?.name  || ''
      })
    } 
  });

  oCertificate.value.listPreRequisites.push(...newList.value);
  
}

const RemovePreRequisite = (itemRemove) => {
  let index = oCertificate.value.listPreRequisites.findIndex(item => item.prerequisiteId === itemRemove.prerequisiteId);
  if(index !== -1){
    oCertificate.value.listPreRequisites.splice(index, 1);
  }
}
//#endregion
</script>