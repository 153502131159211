<template>
  <div class="layout-topbar">
    <button v-if="company" @click="onMenuToggle()"
      class="p-link layout-menu-button layout-topbar-button">
      <i class="pi pi-bars"></i>
    </button>

    <router-link to="/portal" class="layout-topbar-logo">
      <img :src="logoUrl" alt="logo" />
      <span></span>
    </router-link>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses" >
      <!--<button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">-->
      <!--<button class="w-full p-link flex align-items-center text-color hover:surface-200 border-noround">
        <i class="pi pi-calendar" v-badge="'5'" style="font-size: 1.5rem;"/>
      </button>-->
      <div v-if="company" class="w-full p-link flex align-items-center text-color hover:surface-200 border-noround">
        <i class="pi pi-calendar" v-badge="`${quantityAlertPendients}`" style="font-size: 1.5rem;"
          aria-haspopup="true" aria-controls="overlay_panel" @click="toggleAlertPendient($event)"></i>
      </div>
      <OverlayPanel ref="opAlertPendient" appendTo="body" >
        <div style="height: 250px; overflow-y: auto;">
        <DataTable :value="oListAlertPendients" selectionMode="single" >
            <Column field="firstName" header="Persona" style="min-width: 4rem">
                <template #body="slotProps">
                  {{ slotProps.data.firstName }}  {{ slotProps.data.lastName }}
                  <!--<Button :label="`${slotProps.data.firstName} ${slotProps.data.lastName}`" link @click="ViewAccreditationPersonal(slotProps.data)" />-->
                </template>
            </Column>
            <Column field="firstName" header="Docs" style="min-width: 1rem">
                <template #body="slotProps">
                  <div style="text-align: center;">                  
                    <i v-if="slotProps.data.documents.length > 0" class="pi" v-tooltip.top="`Tiene ${slotProps.data.documents.length} documento(s) vencido(s)`" 
                      :class="slotProps.data.documents.length > 0 ? 'pi-times-circle text-red-500 ' : 'pi-check-circle text-green-500'"></i> 
                    <i v-if="slotProps.data.documents.length == 0" class="pi" :class="'pi-check-circle text-green-500'"></i> 
                  </div>
                </template>
            </Column>
            <Column field="firstName" header="Cursos" style="min-width: 1rem">
              <template #body="slotProps">
                <div style="text-align: center;">
                  <i v-if="slotProps.data.courses.length > 0" class="pi" v-tooltip.top="`Tiene ${slotProps.data.courses.length} curso(s) vencido(s)`" 
                    :class="slotProps.data.courses.length > 0 ? 'pi-times-circle text-red-500 ' : 'pi-check-circle text-green-500'"></i> 
                  <i v-if="slotProps.data.courses.length == 0" class="pi" :class="'pi-check-circle text-green-500'"></i> 
                  </div>
              </template>
            </Column>
            <Column field="firstName" header="Certificados" style="min-width: 1rem">
              <template #body="slotProps">
                <div style="text-align: center;">
                  <i v-if="slotProps.data.certificates.length > 0" class="pi" v-tooltip.top="`Tiene ${slotProps.data.certificates.length} certificado(s) vencido(s)`" 
                    :class="slotProps.data.certificates.length > 0 ? 'pi-times-circle text-red-500 ' : 'pi-check-circle text-green-500'"></i> 
                  <i v-if="slotProps.data.certificates.length == 0" class="pi" :class="'pi-check-circle text-green-500'"></i> 
                  </div>
              </template>
            </Column>
            <Column field="firstName" header="Emos" style="min-width: 1rem">
                <template #body="slotProps">
                  <div style="text-align: center;">
                    <i v-if="slotProps.data.medicalExaminations.length > 0" class="pi" v-tooltip.top="`Tiene ${slotProps.data.medicalExaminations.length} emo(s) vencido(s)`" 
                      :class="slotProps.data.medicalExaminations.length > 0 ? 'pi-times-circle text-red-500 ' : 'pi-check-circle text-green-500'"></i> 
                    <i v-if="slotProps.data.medicalExaminations.length == 0" class="pi" :class="'pi-check-circle text-green-500'"></i> 
                    </div>
                </template>
            </Column>
        </DataTable>
      </div>
        <button class="w-full p-link align-items-center p-2 mt-2 text-color hover:surface-200 border-noround" style="text-align: center;" @click="ViewReportalertAll()">
          <i class="pi pi-bell mr-2"></i> Ver todas las alertas
        </button>
      
      </OverlayPanel>

      <Menu ref="menu" :model="overlayMenuItems" :popup="true" style="font-size: 9pt;">
        <template #start>
          <button
            class="w-full p-link flex align-items-center p-2 pl-6 text-color hover:surface-200 border-noround"
          >
            <div class="flex flex-column align-items-center">
              <span class="font-bold">{{ username }}</span>
              <span>{{ tipoUsuario }}</span>
              <Divider layout="horizontal"  v-if="company" />
              <div class="flex align-items-center"  v-if="company">
                <img :src="countryImage" :alt="country" width="20" class="mr-2">
                <span>{{ country }}</span>
              </div>
              <Divider layout="horizontal" />
            </div>
          </button>
        </template>
        
        <template #end>
          <button
            @click="changeCompany"  v-if="company"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround">
            <i class="pi pi-sync" />
            <span class="ml-2">{{ company }}</span>
          </button>
          <button
            @click="closeSesion"
            class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround">
            <i class="pi pi-sign-out" />
            <span class="ml-2">Cerrar sesión</span>
          </button>
        </template>

      </Menu>
       <button @click="toggleMenu" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>  

      <!-- <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
      <Button
        type="button"
        label="Perfil"
        icon="pi pi-angle-down"
        @click="toggleMenu"
        style="width: auto"
      /> -->

      <!-- <button @click="onSettingsClick()" class="p-link layout-topbar-button">
                <i class="pi pi-cog"></i>
                <span>Settings</span>
            </button> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import AlertService from "@/service/AlertService";
import Crypto from "@/utilitarios/Crypto";
const store = useStore();

const { onMenuToggle } = useLayout();

const username = ref("");
const tipoUsuario = ref("");
const company = ref('');
const country = ref('');
const countryImage = ref('');
const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();

const alertService = new AlertService();

const menu = ref(null);

const overlayMenuItems = ref([
  /*{
    label: "Cambiar contraseña",
    icon: "pi pi-key",
    to: "/system/change-password"
  }*/
]);

const toggleMenu = (event) => {
  menu.value.toggle(event);
};

onMounted(() => {
  bindOutsideClickListener();
  let res = JSON.parse(store.state.datauser);
  if(res){
    username.value = res.user.firstName + " " + res.user.lastName;
    tipoUsuario.value = res.user.userType;
    if(res.enterprise){
      company.value = res.enterprise.name;
      country.value = res.enterprise.countryName;
      countryImage.value = res.enterprise.countryImage;
    }
  }
  LoadAlertPendient();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const logoUrl = computed(() => {
  // return `layout/images/${
  //   layoutConfig.darkTheme.value ? "logo-white" : "logo-dark"
  // }.svg`;

  return `${process.env.BASE_URL}layout/images/logo.png`;
});


const opAlertPendient = ref();
const oListAlertPendients = ref([]);
const quantityAlertPendients = ref(0);

const toggleAlertPendient = (event) => {
    opAlertPendient.value.toggle(event);
};

const LoadAlertPendient = async() => {
    const response = await alertService.GetAlertExpiredDocumentsService().then();
    if(response.code == 200){
      oListAlertPendients.value = response.data;
      quantityAlertPendients.value = oListAlertPendients.value.length;
    }
};

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};

const onSettingsClick = () => {
  topbarMenuActive.value = false;
  router.push("/documentation");
};

const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};

const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const closeSesion = () => {
  store.commit("logout");
  router.push({ path: "/" });
};

const changeCompany = () => {
  store.commit('selectedCompany', null);
  router.push({ path: "/seleccion" });
};

const ViewAccreditationPersonal = (item) => {
  
    const projectId = encodeURIComponent(Crypto.Encriptar(1));
    const positionId = encodeURIComponent(Crypto.Encriptar(58));
    const personId = encodeURIComponent(Crypto.Encriptar(item.personId));
    router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}/${personId}` });
}

const ViewReportalertAll = () => {
  router.push({ path: `/portal/Reporte/AlertaPendientes` });
}
</script>

<style lang="scss" scoped>
.layout-topbar .layout-menu-button {
    margin-left: 0;
}
</style>
