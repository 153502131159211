<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Contrato</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" :icon="`pi pi-plus`" v-tooltip.top="`Nuevo contrato`" class="p-button-success ml-2" 
                                    @click="openContration()" v-if="oListContract.length == 0 && ssPermission.createEnabled" />
                            <Button label="" :icon="`pi pi-pencil`" v-tooltip.top="`Editar contrato`" class="p-button-success ml-2" 
                                @click="openContration()" v-if="oListContract.length > 0 && ssPermission.editEnabled" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtContrar" 
                    :value="oListContract"
                    dataKey="contractId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column field="contractType" header="Tipo contrato">
                        <template #body="slotProps">
                            {{ slotProps.data.contractType.name || '' }}
                        </template>
                    </Column>
                    <Column field="startDate" header="Fecha inicio">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.startDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="expirationDate" header="Fecha vencimiento">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.expirationDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="workShift" header="Turno">
                        <template #body="slotProps">
                            {{ slotProps.data.workShift?.name }}
                        </template>
                    </Column>
                    <Column field="isSigned" header="Estado">
                        <template #body="slotProps">
                            {{ slotProps.data.contractStatus.name || '' }}
                        </template>
                    </Column>
                </DataTable>
                
                <br /><br />

                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado General</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtContractGeneral" 
                    :value="oListContract"
                    dataKey="contractId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="isNewPhotocheck" header="Trámite fotocheck">
                        <template #body="slotProps">
                            {{ slotProps.data.photocheckStatus.name || '' }}
                        </template>
                    </Column>
                    <Column field="photocheckNumber" header="N° fotocheck"></Column>
                    <Column field="TarjetaBloqueo" header="Estado tarjeta bloqueo">
                        <template #body="slotProps">
                            {{ slotProps.data.lockCardStatus.name || '' }}
                        </template>
                    </Column>
                    <Column field="lockCardNumber" header="Número tarjeta bloqueo"></Column>
                    <Column field="AsignacionFamiliar" header="Asignación familiar">
                        <template #body="slotProps">
                            {{ slotProps.data.enableFamilyAllowance ? 'Si' : 'No' }}
                        </template>
                    </Column>
                </DataTable>
            </div>
            
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Pólizas</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Nueva póliza`" class="p-button-success ml-2" 
                                @click="openPolicy(1)" v-if="ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPolicy" 
                    :value="oListPolicy"
                    dataKey="insurancePolicyId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar póliza`" class="p-button-secondary-outlined ml-2" 
                                @click="openPolicy(slotProps.data)" v-if="ssPermission.editEnabled" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar póliza`" text rounded class="p-button-danger ml-2" 
                                @click="UpdateStatusPolicy(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="policyName" header="Póliza" ></Column>
                    <Column field="policyNumber" header="N° póliza"></Column>
                    <Column field="policyEnterprise" header="Empresa"></Column>
                    <Column field="expirationDate" header="Fecha vencimiento">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.expirationDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="isActive" header="Estado">
                        <template #body="slotProps">
                            {{ slotProps.data.isActive ? 'Vigente' : 'No vigente' }}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Desempeño</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar desempeño`" class="p-button-success ml-2" 
                                @click="openPerformance(1)" v-if="ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPerformance" 
                    :value="oListPerformance"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar desempeño`" class="p-button-secondary-outlined ml-2" 
                                @click="openPerformance(slotProps.data)" v-if="ssPermission.editEnabled" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar desempeño`" text rounded class="p-button-danger ml-2" 
                                @click="UpdateStatusPerformance(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="supervisorName" header="Supervisor/evaluador" ></Column>
                    <Column field="evaluationDate" header="Fecha evaluación">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.evaluationDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="performanceLevel" header="Valoración desempeño">
                        <template #body="slotProps">
                            {{ slotProps.data.performanceLevel.name }}
                        </template>
                    </Column>
                    <Column field="comment" header="Comentarios"></Column>
                    <Column field="Category" header="Categoría">
                        <template #body="slotProps">
                            <Tag v-if="slotProps.data.isRecommended" :value="'Recomendado'" severity="success" />
                            <Tag v-else :value="'No recomendado'" severity="danger" />
                        </template>
                    </Column>
                    <Column field="reason" header="Motivo"></Column>
                </DataTable>

            </div>
            <DocumentsAccreditation :items="oListDocumentsPolicy" :ppDocumentId="'POLICY'" @updateBase = "refreshBase"
                    :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppPersonId="Number(pPersonId)" />
        </div>

    </div>

    <Dialog v-model:visible="modalManagmentContration" modal :header="modalManagmentContrationText" :style="{ width: '45rem' }">
        <TabView>
            <TabPanel header="Datos contrato">
                <div class="grid mt-3">
                    <div class="col-12 lg:col-6 xl:col-12">
                        <div class="flex flex-column h-500rem">
                            <div class="p-fluid p-formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Dropdown :options="oListContractType" v-model="oContract.contractTypeId"
                                            optionLabel="name" optionValue="contractTypeId" class="w-full" 
                                            :class="{ 'p-invalid' : validatedContractType }" />
                                        <label for="lblContractType">Tipo de contrato</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6"></div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Calendar
                                            showIcon
                                            iconDisplay="input"
                                            inputId="dtpFechaFin"
                                            dateFormat="dd/mm/yy"
                                            v-model="oContract.startDate"
                                            :class="{ 'p-invalid' : validatedIssueDateContract }"
                                            />
                                        <label for="lblFechaFin">Fecha inicio</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Calendar
                                            showIcon
                                            iconDisplay="input"
                                            inputId="dtpFechaFin"
                                            dateFormat="dd/mm/yy"
                                            v-model="oContract.expirationDate"
                                            :class="{ 'p-invalid' : validatedExpirationDateContract }"
                                            />
                                        <label for="lblFechaFin">Fecha vencimiento</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Dropdown
                                        v-model="oContract.workShiftId"
                                        :options="oListTurn"
                                        optionLabel="name"
                                        optionValue="workShiftId"
                                        placeholder=""
                                        class="w-full"
                                        :class="{ 'p-invalid' : validatedWorkShiftId }"
                                        />
                                        <label for="lblValidityTime">Turno</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Dropdown :options="oListContractStatus" v-model="oContract.contractStatusId"
                                            optionLabel="name" optionValue="contractStatusId" class="w-full" 
                                            :class="{ 'p-invalid' : validatedContractStatus }" />
                                        <label for="lblContractType">Estado de contrato</label>
                                    </FloatLabel>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Estado general">
                <div class="grid mt-3">
                    <div class="col-12 lg:col-6 xl:col-12">
                        <div class="flex flex-column h-500rem">
                            <div class="p-fluid p-formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Dropdown :options="oListPhotocheckStatus" v-model="oContract.photocheckStatusId"
                                            optionLabel="name" optionValue="photocheckStatusId" class="w-full" 
                                            :class="{ 'p-invalid' : validatedPphotocheckStatus }" />
                                        <label for="lblContractType">Estado de fotocheck</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <InputText id="txtPhotocheckNumber" autocomplete="off" v-model="oContract.photocheckNumber" />
                                        <label for="lblPhotocheckNumber">N° fotocheck</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <Dropdown :options="oListLockCardStatus" v-model="oContract.lockCardStatusId"
                                            optionLabel="name" optionValue="lockCardStatusId" class="w-full" 
                                            :class="{ 'p-invalid' : validatedLockStatus }" />
                                        <label for="lblContractType">Estado tarjeta de bloqueo</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <FloatLabel>
                                        <InputText id="txtLockCardNumber" autocomplete="off" v-model="oContract.lockCardNumber" />
                                        <label for="lblLockCardNumber">N° tarjeta bloqueo</label>
                                    </FloatLabel>
                                </div>
                                <div class="field col-12 md:col-12">
                                    <FloatLabel>
                                        <Checkbox v-model="oContract.enableFamilyAllowance" inputId="chkEnableFamilyAllowance" name="chkEnableFamilyAllowance" :binary="true"/>
                                        <label for="lblEnableFamilyAllowance" class="ml-3">Asignación familiar</label>
                                    </FloatLabel>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
        
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentContration = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveContractPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalManagmentPolicy" modal :header="modalManagmentPolicyText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtPolicyName" autocomplete="off" 
                                    v-model="oPolicy.policyName" 
                                    :class="{ 'p-invalid' : validatedPolicyName }" />
                                <label for="lblPolicyName">Nombre póliza</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtPolicyNumber" autocomplete="off" 
                                    v-model="oPolicy.policyNumber" 
                                    :class="{ 'p-invalid' : validatedPolicyNumber }"/>
                                <label for="lblPolicyNumber">N° póliza</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtPolicyEnterprise" autocomplete="off" 
                                    v-model="oPolicy.policyEnterprise" 
                                    :class="{ 'p-invalid' : validatedPolicyEnterprise }"/>
                                <label for="lblPolicyEnterprise">Nombre aseguradora</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtPolicyBroker" autocomplete="off" 
                                    v-model="oPolicy.policyBroker" />
                                <label for="lblPolicyBroker">Broker</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpExpirationDate" dateFormat="dd/mm/yy"
                                    v-model="oPolicy.expirationDate"
                                    :class="{ 'p-invalid' : validatedExpirationDate }" />
                                <label for="lblExpirationDate">Fecha vencimiento</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Checkbox v-model="oPolicy.isActive" inputId="chkIsActive" name="chkIsActive" :binary="true"/>
                                <label for="lblIsActive" class="ml-3">Vigente</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentPolicy = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SavePolicyPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalManagmentPerformance" modal :header="modalManagmentPerformanceText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtSupervisorName" autocomplete="off" 
                                    v-model="oPerformance.supervisorName"
                                    :class="{ 'p-invalid' : validatedSupervisorNamePerformance }" />
                                <label for="lblSupervisorName">Supervisor/evaluador</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpEvaluationDate" dateFormat="dd/mm/yy"
                                    v-model="oPerformance.evaluationDate"
                                    :class="{ 'p-invalid' : validatedEvaluationDatePerformance }" />
                                <label for="lblEvaluationDate">Fecha evaluación</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oPerformance.performanceLevelId"
                                :options="oListPerformanceAppraisal"
                                optionLabel="name"
                                optionValue="performanceLevelId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedPerformanceLevelId }"
                                />
                                <label for="lblValidityTime">Valoración desempeño</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtComment" rows="2" cols="30" v-model="oPerformance.comment" />
                                <label for="lblComment">Comentarios</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Checkbox v-model="oPerformance.isRecommended" inputId="chkIsRecommended" name="chkIsRecommended" :binary="true"/>
                                <label for="lblIsRecommended" class="ml-3">Recomendado</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtReason" rows="2" cols="30" v-model="oPerformance.reason" />
                                <label for="lblReason">Motivo</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentPerformance = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SavePerformancePosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import CommonService from '@/service/CommonService';
import ContractService from '@/service/ContractService';
import DocumentService from '@/service/DocumentService';
import InsurancePolicyService from '@/service/InsurancePolicyService';
import PerformanceService from '@/service/PerformanceService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineProps, watch } from 'vue';
import { useStore } from 'vuex';

const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const commonService = new CommonService();
const contractService = new ContractService();
const policyService = new InsurancePolicyService();
const performanceService = new PerformanceService();
const documentService = new DocumentService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});

const filtros = ref({});
const bCargando = ref(false);
const oListDocumentsPolicy = ref([]);

const oListContract = ref([]);
const oContractLoad = ref({});
const oContract = ref({});

const oListContractType = ref([]);
const oListContractStatus = ref([]);
const oListPhotocheckStatus = ref([]);
const oListLockCardStatus = ref([]);

const modalManagmentContration = ref(false);
const modalManagmentContrationText = ref('');
const oListPolicy = ref([]);
const oListTurn = ref([]);
const validatedContractType = ref(false);
const validatedIssueDateContract = ref(false);
const validatedExpirationDateContract = ref(false);
const validatedWorkShiftId = ref(false);
const validatedContractStatus = ref(false);
const validatedPphotocheckStatus = ref(false);
const validatedLockStatus = ref(false);

const modalManagmentPolicy = ref(false);
const modalManagmentPolicyText = ref('');
const oPolicy = ref({});
const validatedPolicyName = ref(false);
const validatedPolicyNumber = ref(false);
const validatedPolicyEnterprise = ref(false);
const validatedExpirationDate = ref(false);

const modalManagmentPerformance = ref(false);
const modalManagmentPerformanceText = ref('');
const oListPerformance = ref([]);
const oPerformance = ref({});
const oListPerformanceAppraisal = ref([]);
const validatedSupervisorNamePerformance = ref(false);
const validatedEvaluationDatePerformance = ref(false);
const validatedPerformanceLevelId = ref(false);

const pKey = ref(0);
const pPersonId = ref(0);
const pPositionId = ref(0);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppPersonId: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadContract();
        LoadPolicy();
        LoadPerformance();
        LoadDocumentPolicy();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadContract();
    LoadTurn();
    LoadContractType();
    LoadContractStatus();
    LoadPhotocheckStatus();
    LoadLockCardStatus();
    LoadPerformanceLevel();
    LoadPolicy();
    LoadPerformance();
    LoadDocumentPolicy();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00010');
}

const LoadContractType = async() => {
    bCargando.value = true;
    const response = await commonService.GetContractTypeService() .then();
    if(response.code == 200){
        oListContractType.value = response.data;
        bCargando.value = false;
    }
}

const LoadContractStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetContractStatusService() .then();
    if(response.code == 200){
        oListContractStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadPhotocheckStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetPhotocheckStatusService() .then();
    if(response.code == 200){
        oListPhotocheckStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadLockCardStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetLockCardStatusService() .then();
    if(response.code == 200){
        oListLockCardStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadTurn = async() => {
    bCargando.value = true;
    const response = await commonService.GetWorkShiftService() .then();
    if(response.code == 200){
        oListTurn.value = response.data;
        bCargando.value = false;
    }
}

const LoadPerformanceLevel = async() => {
    bCargando.value = true;
    const response = await commonService.GetPerformanceLevelService() .then();
    if(response.code == 200){
        oListPerformanceAppraisal.value = response.data;
        bCargando.value = false;
    }
}

const LoadContract = async() => {
    bCargando.value = true;
    oListContract.value = [];
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await contractService.GetContractByPositionService(pPersonId.value, pPositionId.value) .then();
        if(response.code == 200){
            if(response.data){
                oContractLoad.value = response.data;
                oContractLoad.value.contractTypeId = response.data.contractType?.contractTypeId;
                oContractLoad.value.workShiftId = response.data.workShift?.workShiftId;
                oContractLoad.value.startDate = response.data.startDate ? Utilitario.formatoDate(response.data.startDate) : null;
                oContractLoad.value.expirationDate = response.data.expirationDate ? Utilitario.formatoDate(response.data.expirationDate) : null;
                oContractLoad.value.isNewPhotocheck = response.data.isNewPhotocheck == 1 ? true : false
                oContractLoad.value.contractStatusId = response.data.contractStatus?.contractStatusId;
                oContractLoad.value.photocheckStatusId = response.data.photocheckStatus?.photocheckStatusId;
                oContractLoad.value.lockCardStatusId = response.data.lockCardStatus?.lockCardStatusId;

                oListContract.value = [];
                oListContract.value.push(oContractLoad.value);
            }
            bCargando.value = false;
        }
    }    
}

const LoadPolicy = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await policyService.GetInsurancePolicyService(pPersonId.value, pPositionId.value) .then();
        if(response.code == 200){
            oListPolicy.value = response.data;
            bCargando.value = false;
        }
    }    
}

const LoadPerformance = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await performanceService.GetPerformancePersonService(pPersonId.value, pPositionId.value) .then();
        if(response.code == 200){
            oListPerformance.value = response.data;
            bCargando.value = false;
        }
    }    
}

const openContration = (action) => {
    modalManagmentContrationText.value = action == 1 ? 'Nuevo contrato' : 'Editar contrato';
    
    oContract.value = { ...oContractLoad.value };
    
    modalManagmentContration.value = true;
}

const openPolicy = (action) => {
    modalManagmentPolicyText.value = action == 1 ? 'Nueva póliza' : 'Editar póliza';
    
    if(action != 1){
        oPolicy.value = { ...action };
        oPolicy.value.expirationDate = Utilitario.formatoDate(action.expirationDate);
    }
    else{
        oPolicy.value = {};
    }
    
    modalManagmentPolicy.value = true;
}

const openPerformance = (action) => {
    modalManagmentPerformanceText.value = action == 1 ? 'Nuevo desempeño' : 'Editar desempeño';
    
    if(action != 1){
        oPerformance.value = { ...action };
        oPerformance.value.evaluationDate = Utilitario.formatoDate(oPerformance.value.evaluationDate);
        oPerformance.value.performanceLevelId = oPerformance.value.performanceLevel.performanceLevelId;
    }
    else{
        oPerformance.value = {};
    }
    
    modalManagmentPerformance.value = true;
}

const SaveContractPosition = async() => {
    if(await ValidateContractPosition()){
       await CreateUpdateContractPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateContractPosition = async() => {
    const { ...model } = oContract.value;
    const valid = ref(false);

    if(!model?.contractTypeId){
        validatedContractType.value = true;
        valid.value = true;
    }
    else{
        validatedContractType.value = false;
    }

    if(!model?.startDate){
        validatedIssueDateContract.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDateContract.value = false;
    }

    if(!model?.expirationDate){
        validatedExpirationDateContract.value = true;
        valid.value = true;
    }
    else{
        validatedExpirationDateContract.value = false;
    }

    if(!model?.workShiftId){
        validatedWorkShiftId.value = true;
        valid.value = true;
    }
    else{
        validatedWorkShiftId.value = false;
    }
    
    if(!model?.contractStatusId){
        validatedContractStatus.value = true;
        valid.value = true;
    }
    else{
        validatedContractStatus.value = false;
    }
    
    if(!model?.photocheckStatusId){
        validatedPphotocheckStatus.value = true;
        valid.value = true;
    }
    else{
        validatedPphotocheckStatus.value = false;
    }
    
    if(!model?.lockCardStatusId){
        validatedLockStatus.value = true;
        valid.value = true;
    }
    else{
        validatedLockStatus.value = false;
    }

    return !valid.value;
}

const CreateUpdateContractPosition = async() => {
    const { ...model } = oContract.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        jobPositionId: pPositionId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el contrato?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await contractService.CreateUpdateContractService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el contrato correctamente`,
                    accept: async() => {
                        LoadContract();
                        modalManagmentContration.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Contrato:`, error);
        }
      }});
}

const SavePolicyPosition = async() => {
    if(await ValidatePolicyPosition()){
       await CreateUpdatePolicyPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidatePolicyPosition = async() => {
    const { ...model } = oPolicy.value;
    const valid = ref(false);

    if(!model?.policyName){
        validatedPolicyName.value = true;
        valid.value = true;
    }
    else{
        validatedPolicyName.value = false;
    }

    if(!model?.policyNumber){
        validatedPolicyNumber.value = true;
        valid.value = true;
    }
    else{
        validatedPolicyNumber.value = false;
    }

    if(!model?.policyEnterprise){
        validatedPolicyEnterprise.value = true;
        valid.value = true;
    }
    else{
        validatedPolicyEnterprise.value = false;
    }

    if(!model?.expirationDate){
        validatedExpirationDate.value = true;
        valid.value = true;
    }
    else{
        validatedExpirationDate.value = false;
    }

    return !valid.value;
}

const CreateUpdatePolicyPosition = async() => {
    const { ...model } = oPolicy.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        jobPositionId: pPositionId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la póliza?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await policyService.CreateUpdateInsurancePolicyService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la póliza correctamente`,
                    accept: async() => {
                        LoadPolicy();
                        modalManagmentPolicy.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Póliza:`, error);
        }
      }});
}

const UpdateStatusPolicy = async(item) => {
    
    const actionName = 'eliminar';
    const confirmationName = 'eliminó';
    const request = {
        insurancePolicyId: item.insurancePolicyId,
        status: false,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} la póliza ${ item.policyName }?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await policyService.UpdateStatusInsurancePolicyService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} la póliza ${item.policyName} correctamente`,
                accept: async() => {
                    LoadPolicy();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 

const SavePerformancePosition = async() => {
    if(await ValidatePerformancePosition()){
       await CreateUpdatePerformancePosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidatePerformancePosition = async() => {
    const { ...model } = oPerformance.value;
    const valid = ref(false);

    if(!model?.supervisorName){
        validatedSupervisorNamePerformance.value = true;
        valid.value = true;
    }
    else{
        validatedSupervisorNamePerformance.value = false;
    }

    if(!model?.evaluationDate){
        validatedEvaluationDatePerformance.value = true;
        valid.value = true;
    }
    else{
        validatedEvaluationDatePerformance.value = false;
    }

    if(!model?.performanceLevelId){
        validatedPerformanceLevelId.value = true;
        valid.value = true;
    }
    else{
        validatedPerformanceLevelId.value = false;
    }

    return !valid.value;
}

const CreateUpdatePerformancePosition = async() => {
    const { ...model } = oPerformance.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        jobPositionId: pPositionId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el desempeño?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            
            const response = await performanceService.CreateUpdatePerformancePersonService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el desempeño correctamente`,
                    accept: async() => {
                        LoadPerformance();
                        modalManagmentPerformance.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Póliza:`, error);
        }
      }});
}

const UpdateStatusPerformance = async(item) => {
    
    const actionName = 'eliminar';
    const confirmationName = 'eliminó';
    const request = {
        performancePersonId: item.performancePersonId,
        status: false,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} el desempeño?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await performanceService.UpdateStatusPerformancePersonService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el desempeño correctamente`,
                accept: async() => {
                    LoadPerformance();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 
//#endregion


//#region Documents
const LoadDocumentPolicy = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await documentService.GetDocumentPolicyService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListDocumentsPolicy.value = response.data.map((item, index) => ({
                ...item,
                documentIndex: index + 1,
                documentId: item.insurancePolicyId,
                documentName: item.policyName
            }));
            pKey.value++;
            bCargando.value = false;
        } 
    }    
}

const refreshBase = () => {
    LoadDocumentPolicy();
}
//#endregion

</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
