<template>
  <div class="card mb-0">
    <div class="field col-12 md:col-12 mb-1 pb-3">
      <h5>Reporte de personal acreditado</h5>
    </div>
    <Toast />
    <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
      <div class="grid">
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>{{ totalTemporary }}</b></div>
                <div class="font-medium text-sm">Planilla</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-green-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user text-green-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>{{ totalPayroll }}</b></div>
                <div class="font-medium text-sm">Temporal</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-red-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user-plus text-red-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>{{ totalTemporary + totalPayroll }}</b></div>
                <div class="font-medium text-sm">Total</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="flex flex-column h-500rem">
      <div class="p-fluid p-formgrid grid mb-0 mt-0">
              <div class="field col-12 md:col-4">
                  <FloatLabel>
                      <Dropdown :options="oListProjects" 
                          v-model="oReportFilter.projectId" @change="ClearReport()"
                          optionLabel="name" optionValue="projectId"  class="w-full" 
                          :class="{ 'p-invalid' : validatedProject }"/>
                      <label for="lblPerfil">Proyectos</label>
                  </FloatLabel>
              </div>
              <div class="field col-12 md:col-3">
                <FloatLabel>
                  <Dropdown :options="oListWorkerType"
                    v-model="oReportFilter.typeOfWorkerId" @change="ClearReport()"
                    optionLabel="name" optionValue="typeOfWorkerId" class="w-full" />
                  <label for="lblTipoTrabajador">Tipo trabajador</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-3">
                <FloatLabel>
                  <Dropdown :options="oListMineCondition"
                    v-model="oReportFilter.mineConditionId" @change="ClearReport()"
                    optionLabel="name" optionValue="mineConditionId" class="w-full" />
                  <label for="lblTipoTrabajador">Condición mina</label>
                </FloatLabel>
              </div>
              <!-- <div class="field col-12 md:col-4">
                <FloatLabel>
                  <MultiSelect v-model="selectedStatusAptitude" 
                    :options="oListStatusAptitude" optionLabel="name" 
                    optionValue="medicalExaminationStatusAptitudeId" @change="ClearReport"
                    :maxSelectedLabels="3" class="w-full"  />
                    <label for="lblTipoTrabajador">Aptitud emo</label>
                </FloatLabel>
              </div> -->

              <div class="field col-12 md:col-2">
                <Button :loading="bCargando" label="Buscar" class="button-primary"  @click="LoadReport" />
              </div>
            </div>
    </div>

    <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
      <template #start>
        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0"></div>
      </template>
    </Toolbar>
    <div class="m-0">
      <DataTable
        :value="oListReport"
        :rowHover="true"
        :loading="bCargando"
        class="p-datatable-sm"
      >
        <Column field="documentNumber" header="D.N.I."></Column>
        <Column field="originCityTotal" header="Nombres y apellidos">
          <template #body="slotProps">
            {{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
          </template>
        </Column>
        <Column field="jobName" header="Cargo"></Column>
        <Column field="typeOfWorkerName" header="Tipo trabajador"></Column>
        <Column field="mineConditionName" header="Condición mina"></Column>
        <Column field="provinceName" header="Ciudad origen"></Column>
        <Column field="epp" header="EPP"></Column>
        <Column field="medicalExaminationName" header="EMO"></Column>
        <Column field="aptitudEmo" header="Aptitud Emo"></Column>
        <Column field="progress" header="Progreso"></Column>
        <Column field="courses" header="Cursos">
          <template #body="slotProps">
            <Badge :value="slotProps.data.courses" severity="secondary" class="pointer" @click="toggle($event, slotProps.data.lstCouses)"></Badge>
          </template>
        </Column>
        <Column field="coursesMissing" header="Cursos faltantes">
          <template #body="slotProps">
            <Badge :value="slotProps.data.coursesMissing" severity="danger" class="pointer" @click="toggle($event, slotProps.data.lstCouses)"></Badge>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <OverlayPanel ref="op" appendTo="body">
    <DataTable :value="oListCoursesTotal" selectionMode="single" >
        <Column field="name" header="Documento" style="min-width: 12rem">
          <template #body="slotProps">
            {{ slotProps.data.course?.name || '' }}
          </template>
        </Column>
        <Column field="name" header="Fecha" style="min-width: 12rem">
          <template #body="slotProps">
            {{ slotProps.data.issueDate == '0001-01-01T00:00:00' ? '' : Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
          </template>
        </Column>
        <Column field="status" header="Estado" bodyClass="text-center">
            <template #body="slotProps">
                <i class="pi" :class="slotProps.data.issueDate == '0001-01-01T00:00:00' ? 'pi-times-circle text-red-500' : 'pi-check-circle text-green-500 '"></i>
            </template>
        </Column>
    </DataTable>
  </OverlayPanel>
</template>

<script setup>
import CommonService from "@/service/CommonService";
import ProjectService from "@/service/ProjectService";
import ReportService from "@/service/ReportService";
import Utilitario from "@/utilitarios/Utilitario";
import { FilterMatchMode } from "primevue/api";
import { usePrimeVue } from "primevue/config";
import { useToast } from "primevue/usetoast";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const toast = useToast();

const projectService = new ProjectService();
const commonService = new CommonService();
const reportService = new ReportService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const validatedProject = ref(false);
const oListReport = ref([]);
const oListProjects = ref([]);
const oListWorkerType = ref([]);
const oListMineCondition = ref([]);
const oListStatusAptitude = ref([]);
const selectedStatusAptitude = ref([]);

const op = ref();
const oListCoursesTotal = ref([]);

const oReportFilter = ref({
    jobPositionId: 0,
    typeOfWorkerId: 0,
    projectId: 0,
    mineConditionId: 0,
    medicalExaminationStatusAptitudeId: 0,
    statusGeneralId: -1
  });

  const filtros = ref({});

  //#region Eventos
  onMounted(() => {
      Initialize();
      CambiarEspanol();
  });
  
  onBeforeMount(() => {
      InitializeFilters();
  });
  //#endregion
  
  //#region Metodos
  const Initialize = () => {
      InitializeFilters();
      LoadFilters();
  } 
  
  const InitializeFilters = () => {
      filtros.value = {
          global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
      };
  };
  
  const CambiarEspanol = () => {
      const primevue = usePrimeVue();
      primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
      primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
      primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
      primevue.config.locale.firstDayOfWeek = 1;
      primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
      primevue.config.locale.today = "Hoy";
      primevue.config.locale.clear = "Limpiar";
      primevue.config.locale.dateFormat = "dd/mm/yy";
      primevue.config.locale.weekHeader = "Sem";  
  }
  //#endregion

  const LoadFilters = async() => {
      const request = userStore.enterprise.enterpriseId;
      const response = await projectService.GetProjectByEnterpriseService(request).then();
      if(response.code == 200 && response.data.length > 0){
          oListProjects.value = response.data.filter(x => x.isCompleted).map((item, index) => ({
                ...item,
                name: item.service.name + ' - ' + item.code
            }));
          const sinServicio = { projectId: 0, name: "Seleccione un proyecto" };
          oListProjects.value.unshift(sinServicio);
      }
      bCargando.value = false;
      LoadTypeOfWorker();
      LoadMineCondition();
      LoadStatusAptitude();
  }
  
  const LoadTypeOfWorker = async() => {
      bCargando.value = true;
      const response = await commonService.GetTypeOfWorkerService().then();
      if(response.code == 200){
          oListWorkerType.value = response.data;
          const todos = { typeOfWorkerId: 0, name: "Todos" };
          oListWorkerType.value.unshift(todos);
          bCargando.value = false;
      }
  }

  const LoadMineCondition = async() => {
      bCargando.value = true;
      const response = await commonService.GetMineConditionService().then();
      if(response.code == 200){
          oListMineCondition.value = response.data;
          const todos = { mineConditionId: 0, name: "Todos" };
          oListMineCondition.value.unshift(todos);
          bCargando.value = false;
      }
  }

  const LoadStatusAptitude = async() => {
      bCargando.value = true;
      const response = await commonService.GetMedicalExaminationStatusAptitudeService().then();
      if(response.code == 200){
        oListStatusAptitude.value = response.data;

        selectedStatusAptitude.value = oListStatusAptitude.value.map(item => item.medicalExaminationStatusAptitudeId);
          // const todos = { medicalExaminationStatusAptitudeId: 0, name: "Todos" };
          // oListStatusAptitude.value.unshift(todos);
        bCargando.value = false;
      }
  }

  const LoadReport = async() => {
    bCargando.value = true;
    validatedProject.value = false;  

    if(oReportFilter.value.projectId == 0){
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor seleccione un proyecto.', life: 3000 });
      validatedProject.value = true;
      return;
    }
    
    const request = {
                      projectId: oReportFilter.value.projectId,
                      typeOfWorkerId: oReportFilter.value.typeOfWorkerId,
                      mineConditionId: oReportFilter.value.mineConditionId,
                      provinceId: 0,
                      isAccreditated: true,
                      listAptitudEmo: [],
                      listStatusEmo: []
                    };

    const response = await reportService.GetReportMedicalExaminationDetails(request).then();
    
    if(response.code == 200){
      oListReport.value = response.data;
    }

    bCargando.value = false;
  }

const ClearReport = async() => {
      oListReport.value = [];
  }

  const toggle = (event, olista) => {
    oListCoursesTotal.value = olista;
    op.value.toggle(event);
  };
// Cálculo de los totales
const totalTemporary = computed(() => {
  const total = oListReport.value.reduce((sum, item) => {
                    if (item.typeOfWorkerName === 'Planilla') {
                        return sum + 1;
                    }
                    return sum;
                }, 0);
  
  return total;
});

const totalPayroll = computed(() => {
  const total = oListReport.value.reduce((sum, item) => {
                    if (item.typeOfWorkerName === 'Temporal') {
                        return sum + 1;
                    }
                    return sum;
                }, 0);
  
  return total;
});

</script>
