<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card mb-0">
                <div class="field col-12 md:col-12 mb-1 pb-3">
                    <h5>Reporte de personal y tipo de trabajador</h5>
                </div>

                <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
                    <div class="grid">
                        <div class="col-12 lg:col-6 xl:col-4">
                            <div class="card mb-0 pb-0">
                                <div class="flex justify-content-between mb-3">
                                    <div>
                                        <div class="text-900 font-bold text-2xl"><b>{{ oReport.totalQuantityRequired || 0 }}</b></div>
                                        <div class="font-medium text-sm">Total requerido</div>
                                    </div>
                                    <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                        <i class="pi pi-user text-cyan-500 text-xl"></i>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-4">
                                <div class="card mb-0 pb-0">
                                    <div class="flex justify-content-between mb-3">
                                        <div>
                                            <div class="text-900 font-bold text-2xl"><b>{{ oReport.totalQuantityCurrent || 0 }}</b></div>
                                            <div class="font-medium text-sm">Total actual</div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                            <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-4">
                                <div class="card mb-0 pb-0">
                                    <div class="flex justify-content-between mb-3">
                                        <div>
                                            <div class="text-900 font-bold text-2xl"><b>{{ oReport.totalPercentageCurrent || 0 }}</b>%</div>
                                            <div class="font-medium text-sm">Cumplimiento</div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                            <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>

                <br />
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid mb-0 mt-0">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListProjects" 
                                    v-model="oReportFilter.projectId" @change="ClearReport()"
                                    optionLabel="name" optionValue="projectId"  class="w-full" />
                                <label for="lblPerfil">Proyectos</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2">
                            <Button label="Buscar" class="button-primary" @click="LoadReport" />
                        </div>
                    </div>
                </div>

                <div class="m-0">
                    <DataTable
                        :value="oListReport"
                        :rowHover="true"
                        :loading="bCargando"
                        class="p-datatable-sm"
                    >
                        <Column field="jobPositionName" header="Perfil"></Column>
                        <Column field="jobPositionRequiredQuantity" header="Requerido"></Column>
                        <Column field="jobPositionCurrentQuantity" header="Actual"></Column>
                        <Column field="jobPositionQuantityPending" header="Pendiente"></Column>
                    </DataTable>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import ProjectService from '@/service/ProjectService';
import ReportService from '@/service/ReportService';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const projectService = new ProjectService();
const reportService = new ReportService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const oListProjects = ref([]);
const oReportFilter = ref({
    projectId: 0
});
const oReport = ref({});
const oListReport = ref([]);
const filtros = ref({});


//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadFilters = async() => {
    const request = userStore.enterprise.enterpriseId;
    const response = await projectService.GetProjectByEnterpriseService(request).then();
    if(response.code == 200 && response.data.length > 0){
        oListProjects.value = response.data.filter(x => x.isCompleted).map((item, index) => ({
                ...item,
                name: item.service.name + ' - ' + item.code
            }));


        const sinServicio = { projectId: 0, name: "Seleccione un proyecto" };
        oListProjects.value.unshift(sinServicio);
    }
    bCargando.value = false;
}

const LoadReport = async() => {
    const request = oReportFilter.value.projectId;
    const response = await reportService.GetReportQuantityJobPositionsFilled(request).then();
    if(response.code == 200){
        oReport.value = response.data;
        oListReport.value = response.data?.details ?? [];
    }
    bCargando.value = false;
}

const ClearReport = async() => {
    oReport.value = {};
    oListReport.value = [];
}
//#endregion
</script>