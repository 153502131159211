<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenUserNew" />
                    </template>
                </Toolbar>
                
                <DataTable ref="dtUser" 
                    :value="oListUser"
                    dataKey="idUser"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Usuarios</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column style="text-align: center;" headerStyle="min-width:2rem;">
                        <template #body="slotProps">
                            <Button @click="EditUser(slotProps.data.idUser)" icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" />
                            <Button @click="DeleteUser(slotProps.data)" icon="pi pi-power-off" v-tooltip.top="slotProps.data.status ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'"  />
                        </template>
                    </Column>
                    <Column field="login" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
                        </template>
                    </Column>
                    <Column field="login" header="Usuario">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.login }}</b><br/>
                        </template>
                    </Column>
                    <Column field="userType" header="Rol"></Column>
                    <Column field="status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>
<script setup>
import UserService from '@/service/UserService';
import Crypto from '@/utilitarios/Crypto';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const confirm = useConfirm();
const store = useStore();

const userService = new UserService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const filtros = ref({});

const oListUser = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    LoadUsers();
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenUserNew = () => {
    router.push({ path: `/portal/seguridad/usuario/nuevo` });
}

const LoadUsers = () => {
  userService.GetUserService(0).then(response => {
    oListUser.value = response.data;
  });
}

const EditUser = (idUser) => {
    const id = encodeURIComponent(Crypto.Encriptar(idUser));
    router.push({ path: `/portal/seguridad/usuario/editar/${id}` });
}

const DeleteUser = async(item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';

    const request = {
        idUser: item.idUser,
        idUserType: 0,
        firstName: '',
        lastName: '',
        documentNumber: '',
        email: '',
        cellPhone: '',
        expiredDate: new Date,
        login: '',
        action: 'D',
        creationUser: userStore.user.idUser
    };

    confirm.require({
        message: `¿Está seguro de ${actionName} el Usuario ${item.login}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        acceptClass: 'p-button-primary',
        accept: async () => {
        try{

            const response = await userService.CreateUpdateUserService(request).then();
            if(response.code == 200){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el Usuario ${item.login} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    LoadUsers();
                }
            });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
        }
    });

}
//#endregion
</script>