export default class ReportService {
    async GetReportQuantityJobPositionsFilled(projectId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportQuantityJobPositionsFilled?ProjectId=${projectId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }
    
    async GetReportQuantityJobPositionsTypeOfWorker(projectId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportQuantityJobPositionsTypeOfWorker?ProjectId=${projectId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async GetReportQuantityOrigenPlace(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportQuantityOriginPlace`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

    async GetReportQuantityCourseStatus(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportQuantityCourseByStatus`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

    async GetReportNumberMissingCouses(projectId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetNumberOfMissingCouses?ProjectId=${projectId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }
    
    async GetReportMedicalExaminationDetails(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportMedicalExamination`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

    async GetReportCoursesDetails(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Report/GetReportPersonCourseByStatus`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

}