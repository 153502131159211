<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nuevo Rol</h1>
                    </template>

                    <template #end>
                    </template>
                </Toolbar>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText v-model="oRole.name" id="txtName" autocomplete="off" 
                                :class="{ 'p-invalid' : validatedName }" />
                                <label for="lblName">Nombre de rol</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <Divider layout="horizontal" />

                    </div>
                </div>

                <div class="card" v-if="roleEditId">
                    <Accordion :multiple="true">
                        <AccordionTab>
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="pi pi-bars"></i>
                                    <span class="font-bold white-space-nowrap">Opciones de menú</span>
                                </span>
                            </template>                         
                            <div class="col-12 md:col-12 card" style=" height: 25rem; overflow: auto;">
                                <Tree v-model:selectionKeys="selectedKey" lazy 
                                        selectable="true" 
                                        :value="oListMenu" selectionMode="checkbox" 
                                        class="w-full md:w-30rem"
                                        ></Tree>
                                        <!--@nodeSelect="onNodeSelect" 
                                        @nodeUnselect="onNodeUnselect"
                                        @nodeExpand="onNodeExpand" 
                                        @nodeCollapse="onNodeCollapse"-->
                            </div>                        
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="pi pi-desktop"></i>
                                    <span class="font-bold white-space-nowrap">Permisos</span>
                                </span>
                            </template>
                            <div class="col-12 md:col-12 card">
                                <DataTable ref="dtPermit" 
                                    :value="oListPermits"
                                    dataKey="formId"
                                    :rowHover="true"
                                    :paginator="false" 
                                    :rows="10"
                                    :rowsPerPageOptions="[10, 25, 50, 100]"
                                    :loading="bCargando"
                                    :filters="filtros"
                                    v-model:selection="oListSelected"
                                    class="p-datatable-sm"
                                    >

                                    <template #header>
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <h5 class="m-0">Secciones por formulario</h5>
                                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                            </span>
                                        </div>
                                    </template>

                                    <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
                                    <Column field="formCode" header="Código" :sortable="true"></Column>
                                    <Column field="formName" header="Formulario" :sortable="true"></Column>
                                    <Column field="formOption" header="Opción" :sortable="true"></Column>
                                    <Column field="viewEnabled" header="Ver">
                                        <template #body="slotPropsDetail">
                                            <Checkbox v-model="slotPropsDetail.data.viewEnabled" :binary="true"  /> 
                                        </template>
                                    </Column>
                                    <Column field="createEnabled" header="Nuevo">
                                        <template #body="slotPropsDetail">
                                            <Checkbox v-model="slotPropsDetail.data.createEnabled" :binary="true"  /> 
                                        </template>
                                    </Column>
                                    <Column field="editEnabled" header="Editar">
                                        <template #body="slotPropsDetail">
                                            <Checkbox v-model="slotPropsDetail.data.editEnabled" :binary="true"  /> 
                                        </template>
                                    </Column>
                                    <Column field="deleteEnabled" header="Eliminar">
                                        <template #body="slotPropsDetail">
                                            <Checkbox v-model="slotPropsDetail.data.deleteEnabled" :binary="true"  /> 
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </AccordionTab>
                        <AccordionTab >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="pi pi-building"></i>
                                    <span class="font-bold white-space-nowrap">Empresas</span>
                                </span>
                            </template>
                            <div class="col-12 md:col-12 card">
                                <DataTable ref="dtPermitsEnterprise" 
                                    :value="oListPermitsEnterprise"
                                    dataKey="enterpriseId"
                                    :rowHover="true"
                                    :paginator="false" 
                                    :rows="10"
                                    :rowsPerPageOptions="[10, 25, 50, 100]"
                                    :loading="bCargando"
                                    :filters="filtros"
                                    v-model:selection="oListSelected"
                                    class="p-datatable-sm"
                                    >

                                    <template #header>
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <h5 class="m-0">Empresas y país</h5>
                                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                            </span>
                                        </div>
                                    </template>
                                    
                                    <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
                                    <Column field="enterpriseName" header="Empresa" :sortable="true"></Column>
                                    <Column field="countryName" header="País" :sortable="true"></Column>
                                    <Column field="viewEnabled" header="Ver">
                                        <template #body="slotPropsDetail">
                                            <Checkbox v-model="slotPropsDetail.data.viewEnabled" :binary="true"  /> 
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>

                <div class="flex flex-column h-500rem mb-5">
                    <div class="p-fluid p-formgrid grid">
                        
                        
                    </div>
                </div>

                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="SaveRole()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import RoleService from '@/service/RoleService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const roleService = new RoleService();

const oListMenu = ref([]);
const selectedKey = ref([]);
const oListPermits = ref([]);
const oListPermitsEnterprise = ref([]);
const oListSelected = ref([]);
const validatedName = ref(false);
const oRole = ref({});
const bCargando = ref(false);
const filtros = ref({});
const roleEditId = ref('');

const userStore = JSON.parse(store.state.datauser);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    roleEditId.value = Crypto.Desencriptar(route.params.id);
    if(!Utilitario.StringIsNullOrEmpty(roleEditId.value)){
        LoadRole(roleEditId.value);
        LoadMenuByRole(roleEditId.value);
        LoadPermissionByRole(roleEditId.value);
        LoadPermissionEnterpriseByRole(roleEditId.value);
    }
    else{
        LoadMenuByRole(0);
        LoadPermissionByRole(0);
        LoadPermissionEnterpriseByRole(0);
    }
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const BackTo = () => {
    router.push({ path: `/portal/seguridad/rol/mantenedor` });
}

const LoadRole = async(id) => {
  bCargando.value = true;
    const response = await roleService.GetRoleService(id).then();
    if(response.code == 200){
        oRole.value = response.data;
        bCargando.value = false;
    }
}

const LoadMenuByRole = async(id) => {
  bCargando.value = true;
    const response = await roleService.GetMenuByRoleService(id).then();
    if(response.code == 200){
        oListMenu.value = response.data.data;
        selectedKey.value = getSelectedKeys(oListMenu.value);
        bCargando.value = false;
    }
}

const LoadPermissionByRole = async(id) => {
    bCargando.value = true;
    const response = await roleService.GetPermissionByRoleService(id).then();
    if(response.code == 200){
        oListPermits.value = response.data;
        bCargando.value = false;
    }
}

const LoadPermissionEnterpriseByRole = async(id) => {
    bCargando.value = true;
    const response = await roleService.GetPermissionEnterpriseByRoleService(id).then();
    if(response.code == 200){
        oListPermitsEnterprise.value = response.data;
        bCargando.value = false;
    }
}

const getSelectedKeys = (data) => {
  let result = {};

  const traverse = (node, parentKey = '') => {
    if (node.flag) {
      const key = parentKey ? `${parentKey}-${node.idKey}` : `${node.idKey}`;
      result[key] = {
        checked: true,
        partialChecked: node.partialSelected
      };
    }

    if(node.partialSelected){
        const key = parentKey ? `${parentKey}-${node.idKey}` : `${node.idKey}`;
        result[key] = {
            checked: node.flag,
            partialChecked: true 
        };
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => traverse(child, node.key));
    }
  };

  data.forEach(item => traverse(item));

  return result;
}

/*const onNodeSelect = (node) => {
    console.log('onNodeSelect', node);
    console.log(selectedKey.value)
    toast.add({ severity: 'success', summary: 'Node Selected', detail: node.label, life: 3000 });
}

const onNodeUnselect = (node) => {
    console.log('onNodeUnselect', node);
    console.log(selectedKey.value)
    toast.add({ severity: 'success', summary: 'Node Unselected', detail: node.label, life: 3000 });
}

const onNodeExpand = (node) => {
    console.log('onNodeExpand', node);
    toast.add({ severity: 'info', summary: 'Node Expanded', detail: node.label, life: 3000 });
}

const onNodeCollapse = (node) => {
    console.log('onNodeCollapse', node);
    toast.add({ severity: 'error', summary: 'Node Collapsed', detail: node.label, life: 3000 });
}*/

const SaveRole = async() => {
    if(await ValidateRole()) {
        if(await CreateUpdateRole()) {
            if(oRole.value.idUserType) {
                await CreateUpdatePermissionEnterpriseRole();
                await CreateUpdatePermissionRole();
                await CreateUpdateMenuRole();
            }
        }
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateRole = async() => {
  const { ...model } = oRole.value;
  const valid = ref(false);
    
  if(!model?.name){
    validatedName.value = true;
    valid.value = true;
  }
  else{
    validatedName.value = false;
  }
  
  return !valid.value;
}

const CreateUpdateRole = async() => {
    return new Promise((resolve, reject) => {
        const { ...model } = oRole.value;

        const request = {
            ...model,
            nameUserType: model.name,
            action: model.idUserType ? 'U' : 'N',
            creationUser: userStore.user.idUser
        };
        
        confirm.require({
            message: `¿Está seguro de guardar el Rol?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            acceptClass: 'p-button-primary',
            accept: async () => {
                try{

                    const response = await roleService.CreateUpdateRoleService(request).then();
                    if(response.code == 200){
                        bCargando.value = false;
                        const id = encodeURIComponent(Crypto.Encriptar(response.data.idUserType));
                        router.push({ path: `/portal/seguridad/rol/editar/${id}` });
                        roleEditId.value = response.data.idUserType;
                        LoadRole(roleEditId.value);
                        if(!oRole.value.idUserType){
                            confirm.require({
                                group: 'headless',
                                header: 'Felicidades',
                                message: `Se guardó el Rol ${oRole.value.name} correctamente`,
                                accept: async() => {
                                    bCargando.value = false;
                                }
                            });
                        }
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar:`, error);
                    reject(false);
                }
            }
        });

    });  

}

const CreateUpdateMenuRole = async() => {
    const { ...model } = oRole.value;

    const permission = ref([]);
    permission.value = convertToPermissions(selectedKey.value);

    const request = {
        idUserType: model.idUserType,
        permission: permission.value
    };
    
    try{

        const response = await roleService.CreateUpdateMenuRoleService(request).then();
        if(response.code == 200){
            confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardó el Rol ${oRole.value.name} correctamente`,
                            accept: async() => {
                                bCargando.value = false;
                                LoadRole(oRole.value.idUserType);
                                LoadMenuByRole(oRole.value.idUserType);
                            }
                        });
        }
    }
    catch (error) {
        bCargando.value = false;
        console.error(`Error al CreateUpdateMenuRole:`, error);
    }

}

const CreateUpdatePermissionRole = async() => {
    const { ...model } = oRole.value;

    const request = {
        userTypeId: model.idUserType,
        creationUser: userStore.user.idUser,
        permissions: oListPermits.value
    };
    
    try{

        const response = await roleService.CreateUpdatePermissionRoleService(request).then();
        if(response.code == 200){
            LoadPermissionByRole(roleEditId.value);
        }
    }
    catch (error) {
        bCargando.value = false;
        console.error(`Error al CreateUpdatePermissionRole:`, error);
    }

}

const CreateUpdatePermissionEnterpriseRole = async() => {
    const { ...model } = oRole.value;

    const request = {
        userTypeId: model.idUserType,
        creationUser: userStore.user.idUser,
        permissions: oListPermitsEnterprise.value
    };
    
    try{

        const response = await roleService.CreateUpdatePermissionEnterpriseRoleService(request).then();
        if(response.code == 200){
            LoadPermissionEnterpriseByRole(roleEditId.value);
        }
    }
    catch (error) {
        bCargando.value = false;
        console.error(`Error al CreateUpdatePermissionRole:`, error);
    }

}
// Método para convertir el objeto original en la lista deseada
const convertToPermissions = (data) => {
  return Object.keys(data).map(key => ({
    idMenu: getKey(key),
    partialSelected: data[key].partialChecked,
    flag: data[key].checked
  }));
}

const getKey = (keys) => {
    const partes = keys.trim().split('-'); // Separar la cadena por guiones y eliminar espacios extra
    
    return partes[partes.length - 1]; // Retornar el último valor después del último guion
}

//#endregion

</script>