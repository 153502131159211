<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card mb-0">
                <div class="field col-12 md:col-12 mb-1 pb-3">
                    <h5>Reporte de alertas</h5>
                </div>

                <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
                    <div class="grid">
                        <div class="col-12 lg:col-6 xl:col-3">
                            <div class="card mb-0 pb-0">
                                <div class="flex justify-content-between mb-3">
                                    <div>
                                        <div class="text-900 font-bold text-2xl"><b>{{ totalExpiredDocuments || 0 }}</b></div>
                                        <div class="font-medium text-sm">Documentos vencidos</div>
                                    </div>
                                    <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                        <i class="pi pi-calendar-minus text-red-500 text-xl"></i>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <div class="card mb-0 pb-0">
                                    <div class="flex justify-content-between mb-3">
                                        <div>
                                            <div class="text-900 font-bold text-2xl"><b>{{ totalExpiredCourses || 0 }}</b></div>
                                            <div class="font-medium text-sm">Cursos vencidos</div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                            <i class="pi pi-calendar-minus text-red-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <div class="card mb-0 pb-0">
                                    <div class="flex justify-content-between mb-3">
                                        <div>
                                            <div class="text-900 font-bold text-2xl"><b>{{ totalExpiredCertificates || 0 }}</b></div>
                                            <div class="font-medium text-sm">Certificados vencidos</div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                            <i class="pi pi-calendar-minus text-red-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <div class="card mb-0 pb-0">
                                    <div class="flex justify-content-between mb-3">
                                        <div>
                                            <div class="text-900 font-bold text-2xl"><b>{{ totalExpiredMedicalExaminations || 0 }}</b></div>
                                            <div class="font-medium text-sm">Emos vencidos</div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width: 2.5rem; height: 2.5rem" >
                                            <i class="pi pi-calendar-minus text-red-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>

                <br />
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid mb-0 mt-0">
                        <!--<div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListProjects" 
                                    v-model="oReportFilter.projectId" @change="ClearReport()"
                                    optionLabel="name" optionValue="projectId"  class="w-full" />
                                <label for="lblPerfil">Proyectos</label>
                            </FloatLabel>
                        </div>-->
                        <div class="field col-12 md:col-2">
                            <Button label="Buscar" class="button-primary" @click="LoadReport" />
                        </div>
                    </div>
                </div>

                <div class="m-0">
                    <DataTable :value="oListReport" selectionMode="single" >
                        <Column field="firstName" header="Persona" style="min-width: 4rem">
                            <template #body="slotProps">
                                <Button :label="`${slotProps.data.firstName} ${slotProps.data.lastName}`" link @click="ViewAccreditationPersonal(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="firstName" header="Docs" style="min-width: 1rem">
                            <template #body="slotProps">
                                <i @click="toggle($event, slotProps.data.documents)" v-if="!slotProps.data.documents.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.documents.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.documents.every(item => item.status === true) }"></i> 
                                <i v-if="slotProps.data.documents.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.documents.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.documents.every(item => item.status === true) }"></i> 
                            </template>
                        </Column>
                        <Column field="firstName" header="Cursos" style="min-width: 1rem">
                            <template #body="slotProps">
                                <i @click="toggle($event, slotProps.data.courses)" v-if="!slotProps.data.courses.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.courses.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.courses.every(item => item.status === true) }"></i> 
                                <i v-if="slotProps.data.courses.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.courses.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.courses.every(item => item.status === true) }"></i> 
                            </template>
                        </Column>
                        <Column field="firstName" header="Certificados" style="min-width: 1rem">
                            <template #body="slotProps">
                                <i @click="toggle($event, slotProps.data.certificates)" v-if="!slotProps.data.certificates.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.certificates.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.certificates.every(item => item.status === true) }"></i> 
                                <i v-if="slotProps.data.certificates.every(item => item.status === true)"
                                    class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.certificates.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.certificates.every(item => item.status === true) }"></i> 
                            </template>
                        </Column>
                        <Column field="firstName" header="Emos" style="min-width: 1rem">
                            <template #body="slotProps">
                                <i @click="toggle($event, slotProps.data.medicalExaminations)" v-if="!slotProps.data.medicalExaminations.every(item => item.status === true)"
                                class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.medicalExaminations.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.medicalExaminations.every(item => item.status === true) }"></i> 
                                <i v-if="slotProps.data.medicalExaminations.every(item => item.status === true)"
                                    class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.medicalExaminations.every(item => item.status === true), 'pi-times-circle text-red-500': !slotProps.data.medicalExaminations.every(item => item.status === true) }"></i> 
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <OverlayPanel ref="op" appendTo="body">
                    <DataTable :value="documents" selectionMode="single" >
                        <Column field="documentName" header="Documento">
                            <template #body="slotProps">
                                {{ slotProps.data.documentName }}
                                {{ slotProps.data.courseName }}
                                {{ slotProps.data.certificateName }}
                                {{ slotProps.data.medicalExaminationName }}
                            </template>
                        </Column>
                        <Column field="expirationDate" header="Fec. Venc.">
                            <template #body="slotProps">
                                {{ Utilitario.formatoFecha(slotProps.data.expirationDate, 'dd/MM/yyyy') }}
                            </template>
                        </Column>
                    </DataTable>
                </OverlayPanel>
            </div>
        </div>
    </div>
</template>

<script setup>
import AlertService from '@/service/AlertService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const alertService = new AlertService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const oReport = ref({});
const oListReport = ref([]);
const filtros = ref({});
const op = ref();
const documents = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadReport = async() => {
    const response = await alertService.GetAlertExpiredDocumentsService().then();
    if(response.code == 200){
        oListReport.value = response.data;
    }
    bCargando.value = false;
}

const ClearReport = async() => {
    oListReport.value = [];
}

const toggle = (event, olista) => {
    documents.value = olista;
    op.value.toggle(event);
};

const totalExpiredDocuments = computed(() => {
    return oListReport.value.reduce((total, persona) => total + persona.documents.length, 0);
});

const totalExpiredCourses = computed(() => {
    return oListReport.value.reduce((total, persona) => total + persona.courses.length, 0);
});

const totalExpiredCertificates = computed(() => {
    return oListReport.value.reduce((total, persona) => total + persona.certificates.length, 0);
});

const totalExpiredMedicalExaminations = computed(() => {
    return oListReport.value.reduce((total, persona) => total + persona.medicalExaminations.length, 0);
});
//#endregion
</script>