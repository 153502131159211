<template>
    <div class="col-12 md:col-12">
        <h5>Gestión de Certificaciones</h5>
    </div>
    <div class="col-12 md:col-12">
        <DataTable ref="dtPosition" 
            v-model:expandedRows="expandedRows" 
            :value="oListPosition"
            dataKey="jobPositionId"
            :loading="bCargando"
            :filters="filtrosGeneral"
            class="p-datatable-sm"
            >
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 class="m-0">Puestos</h5>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <InputText v-model="filtrosGeneral['global'].value" placeholder="Buscar..." />
                    </span>
                </div>
            </template>

            <Column expander style="width: 5rem" />
            <Column field="jobPositionName" header="Puesto">
                <template #body="slotProps">
                    <b>{{ slotProps.data.job.name || '' }}</b>
                </template>
            </Column>
            <Column field="requiredQuantity" header="Requerimiento"></Column>
            <Column field="status" header="Estado" bodyClass="text-center">
                <template #body="slotProps">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.status, 'pi-times-circle text-red-500': !slotProps.data.status }"></i>
                </template>
            </Column>
            <template #expansion="slotProps">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Agregar" icon="pi pi-plus" class="p-button-success ml-2" 
                        @click="openAddCertification(slotProps.data.jobPositionId)" v-if="ssPermission.createEnabled" />
                    </template>
                </Toolbar>
                <div class="p-4" v-if="slotProps.data.certificates.length > 0">
                    <DataTable ref="dtCertification" 
                        :value="slotProps.data.certificates"
                        dataKey="certificateId"
                        :rowHover="true"
                        :loading="bCargando"
                        :filters="filtros"
                        class="p-datatable-sm"
                        >

                        <template #header>
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <h5 class="m-0">Certificaciones</h5>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                </span>
                            </div>
                        </template>

                        <Column style="text-align: center" headerStyle="min-width:2rem;" >
                            <template #body="slotPropsDetail">
                                <Button icon="pi pi-trash" v-tooltip.top="'Eliminar'" class="p-button-rounded p-button-danger mt-2" 
                                @click="RemoveCertification(slotPropsDetail.data, slotProps.data)" v-if="ssPermission.deleteEnabled" />
                            </template>
                        </Column>
                        <Column field="code" header="Código" ></Column>
                        <Column field="name" header="Nombre"></Column>
                        <Column field="requiredDocument" header="Requiere Documento">
                            <template #body="slotPropsDetail">
                                <Checkbox v-model="slotPropsDetail.data.requiredDocument" :binary="true" @change="emitList" /> 
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
    <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar Certificación" :style="{ width: '70rem' }">
        <SearchCertificate @closeModal="closeModal" @sendList="AddCertification" /> 
    </Dialog>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, defineEmits, defineProps, watch } from 'vue';
import SearchCertificate from '@/components/Certificate/SearchCertificate/SearchCertificate.vue';
import JobPositionService from '@/service/JobPositionService';
import Utilitario from '@/utilitarios/Utilitario';

const jobPositionService = new JobPositionService();
const ssPermission = ref({});

const bCargando = ref(false);
const filtros = ref({});
const filtrosGeneral = ref({});
const modalSearchPersonal = ref(false);
const expandedRows = ref({});
const positionIdParam = ref(0);
const oListPosition = ref([]);
const oListCertification = ref([]);

const emit = defineEmits(['sendListPrimary'])

const props = defineProps({
  ppProjectId: {
    type: Number,
    required: true
  },
  ppKey: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadJobPositionCertification();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadJobPositionCertification();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };

    filtrosGeneral.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00017');
}

const closeModal = () => {
  modalSearchPersonal.value = false;
}

const AddCertification = (newItems) => {
    oListCertification.value = newItems;
    console.log(oListCertification.value)
    
    const index = oListPosition.value.findIndex(item => item.jobPositionId === positionIdParam.value);
    if (index !== -1) {
        if(oListPosition.value[index].certificates.length > 0) {
            oListCertification.value.forEach(item => {
                const exists = oListPosition.value[index].certificates.find(x => x.certificateId === item.certificateId);
                if(!exists){
                    item.requiredDocument = true;
                    oListPosition.value[index].certificates.push(item);      
                }
            });
        }
        else{
            oListPosition.value[index].certificates = oListCertification.value.map(item => ({
                ...item,
                requiredDocument: true
            }));
        }
    }
    emit('sendListPrimary', oListPosition.value);
}

const emitList = () =>{
    emit('sendListPrimary', oListPosition.value);
}

const openAddCertification = (positionId) => {
    positionIdParam.value = positionId;
    modalSearchPersonal.value = true
}

const LoadJobPositionCertification = async() => {
    bCargando.value = true;
    const projectId = props.ppProjectId;
    const response = await jobPositionService.GetJobPositionCertificateService(projectId).then();
    if(response.code == 200){
        oListPosition.value = response.data;
        bCargando.value = false;
        emit('sendListPrimary', oListPosition.value);
    }
}

const RemoveCertification = (itemRemove, itemPrimary) => {
    
    let indexPrimary = oListPosition.value.findIndex(item => item.jobPositionId === itemPrimary.jobPositionId);
    if (indexPrimary !== -1) {
        let index = oListPosition.value[indexPrimary].certificates.findIndex(item => item.certificateId === itemRemove.certificateId);
        if (index !== -1) {
            oListPosition.value[indexPrimary].certificates.splice(index, 1);
        }
    }

    emit('sendListPrimary', oListPosition.value);
}

//#endregion
</script>