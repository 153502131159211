<template>
  <div class="grid">
    <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
      <div class="card mb-0">
        <div class="field col-12 md:col-12 mb-1 pb-3">
          <h5>Reporte de personal y tipo de trabajador</h5>
        </div>
        <Toast />
        <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
          <div class="grid">
            <div class="col-12 lg:col-6 xl:col-4" v-if="oReportFilter.typeOfWorkerId != 2">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>{{ totalPayroll || 0 }}</b></div>
                    <div class="font-medium text-sm">Planilla</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user text-cyan-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4" v-if="oReportFilter.typeOfWorkerId != 1">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>{{ totalTemporary || 0 }}</b></div>
                    <div class="font-medium text-sm">Temporal</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>{{ total || 0 }}</b></div>
                    <div class="font-medium text-sm">Total</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid mb-0 mt-0">
            <div class="field col-12 md:col-4">
                <FloatLabel>
                    <Dropdown :options="oListProjects" 
                        v-model="oReportFilter.projectId" @change="ClearReport()"
                        optionLabel="name" optionValue="projectId"  class="w-full" 
                        :class="{ 'p-invalid' : validatedProject }"/>
                    <label for="lblPerfil">Proyectos</label>
                </FloatLabel>
            </div>
            <div class="field col-12 md:col-3">
              <FloatLabel>
                <Dropdown :options="oListWorkerType"
                  v-model="oReportFilter.typeOfWorkerId"
                  optionLabel="name" optionValue="typeOfWorkerId" class="w-full" />
                <label for="lblTipoTrabajador">Tipo trabajador</label>
              </FloatLabel>
            </div>
            
            <div class="field col-12 md:col-3" v-if="oListProfile.length > 0">
              <FloatLabel>
                <MultiSelect v-model="selectedProfile" 
                  :options="oListProfile" optionLabel="jobPositionName" 
                  optionValue="jobPositionId" @change="LoadReportTemp"
                  placeholder="Seleccioné un puesto" 
                  :maxSelectedLabels="3" class="w-full"  />
                <label for="lblPerfil">Puesto</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-2">
              <Button label="Buscar" class="button-primary"  @click="LoadReport" />
            </div>
          </div>
        </div>

        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0"></div>
          </template>
        </Toolbar>
        <div class="m-0">
          <DataTable
            :value="oListReport"
            :rowHover="true"
            :loading="bCargando"
            class="p-datatable-sm"
          >
            <Column field="jobPositionName" header="Puesto">
              <template #footer>
                <strong class="grid-totals">Total general</strong>
              </template>
            </Column>
            <Column field="planillaWorkerQuantity" header="Planilla" v-if="oReportFilter.typeOfWorkerId != 2" > 
              <template #footer>
                <span class="grid-totals">{{ totalPayroll }}</span>
              </template>
            </Column>
            <Column field="temporalWorkerQuantity" header="Temporal" v-if="oReportFilter.typeOfWorkerId != 1">
              <template #footer>
                <span class="grid-totals">{{ totalTemporary }}</span>
              </template>
            </Column>
            <Column field="generalTotal" header="Total">
              <template #footer>
                <span class="grid-totals">{{ total }}</span>
              </template>
            </Column>
            <!--<Column style="text-align: center" headerStyle="min-width:12rem;">
              <template #body="slotProps">
                <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-success mr-2" />
              </template>
            </Column>-->
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonService from "@/service/CommonService";
import ProjectService from "@/service/ProjectService";
import ReportService from "@/service/ReportService";
import { FilterMatchMode } from "primevue/api";
import { usePrimeVue } from "primevue/config";
import { useToast } from "primevue/usetoast";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const toast = useToast();

const projectService = new ProjectService();
const reportService = new ReportService();
const commonService = new CommonService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const oReportFilter = ref({
  jobPositionId: 0,
  typeOfWorkerId: 0,
  projectId: 0
});

const oListProjects = ref([]);
const oListProfile = ref([]);
const selectedProfile = ref();
const oListWorkerType = ref([]);

const validatedProject = ref(false);
const oReport = ref({});
const oListReportGeneral = ref([]);
const oListReport = ref([]);
const filtros = ref({});

//#region Eventos
onMounted(() => {
    Initialize();
    CambiarEspanol();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadFilters();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";  
}

//#region Cálculo de los totales
const totalPayroll = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.planillaWorkerQuantity, 0);
});

const totalTemporary = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.temporalWorkerQuantity, 0);
});

const total = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.generalTotal, 0);
});
//#endregion

const LoadFilters = async() => {
    const request = userStore.enterprise.enterpriseId;
    const response = await projectService.GetProjectByEnterpriseService(request).then();
    if(response.code == 200 && response.data.length > 0){
        oListProjects.value = response.data.filter(x => x.isCompleted).map((item, index) => ({
                ...item,
                name: item.service.name + ' - ' + item.code
            }));

        const sinServicio = { projectId: 0, name: "Seleccione un proyecto" };
        oListProjects.value.unshift(sinServicio);
    }
    bCargando.value = false;
    LoadTypeOfWorker();
}

const LoadTypeOfWorker = async() => {
    bCargando.value = true;
    const response = await commonService.GetTypeOfWorkerService().then();
    if(response.code == 200){
        oListWorkerType.value = response.data;
        const todos = { typeOfWorkerId: 0, name: "Todos" };
        oListWorkerType.value.unshift(todos);
        bCargando.value = false;
    }
}

const LoadReport = async() => {
  validatedProject.value = false;
  if(oReportFilter.value.projectId == 0){
    toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor seleccione un proyecto.', life: 3000 });
    validatedProject.value = true;
    return;
  }
  if(selectedProfile.value?.length > 0){
    const list = [ ...oListReportGeneral.value ];
    const coindicendes = list.filter(item1 => 
      selectedProfile.value.includes(item1.jobPositionId)
    );

      oListReport.value = coindicendes;
    return
  }

  const request = oReportFilter.value.projectId;
  const response = await reportService.GetReportQuantityJobPositionsTypeOfWorker(request).then();
  if(response.code == 200){
    oReport.value = response.data;
    oListReportGeneral.value = response.data?.details ?? [];

    oListReport.value = [ ...oListReportGeneral.value ];
    const JobPosition = [ ...oListReport.value ];
    oListProfile.value = JobPosition;
    selectedProfile.value = oListProfile.value.map(item => item.jobPositionId);
  }
  bCargando.value = false;
}

const LoadReportTemp = async() => {
  oListReport.value = [];
  if(selectedProfile.value?.length > 0 && oListReportGeneral.value?.length > 0){
    const list = [ ...oListReportGeneral.value ];
    const coindicendes = list.filter(item1 => 
      selectedProfile.value.includes(item1.jobPositionId)
    );

    oListReport.value = coindicendes;
    return
  }
}

const ClearReport = async() => {
    oReport.value = {};
    oListReport.value = [];
}

//#endregion
</script>