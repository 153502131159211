<template>
    <div class="grid">
      <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
        <div class="card mb-0">
          <div class="field col-12 md:col-12 mb-1 pb-3">
            <h5>Reporte de lugar de origen</h5>
          </div>
          <Toast />
          <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
            <div class="grid">
              <div class="col-12 lg:col-6 xl:col-4">
                <div class="card mb-0 pb-0">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="text-900 font-bold text-2xl"><b>{{ total || 0 }}</b></div>
                      <div class="font-medium text-sm">Total</div>
                    </div>
                    <div
                      class="flex align-items-center justify-content-center bg-purple-100 border-round"
                      style="width: 2.5rem; height: 2.5rem"
                    >
                      <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <br />
          <div class="flex flex-column h-500rem">
            <div class="p-fluid p-formgrid grid mb-0 mt-0">
              <div class="field col-12 md:col-4">
                  <FloatLabel>
                      <Dropdown :options="oListProjects" 
                          v-model="oReportFilter.projectId" @change="ClearReport()"
                          optionLabel="name" optionValue="projectId"  class="w-full" 
                          :class="{ 'p-invalid' : validatedProject }"/>
                      <label for="lblPerfil">Proyectos</label>
                  </FloatLabel>
              </div>
              <div class="field col-12 md:col-3">
                <FloatLabel>
                  <Dropdown :options="oListWorkerType"
                    v-model="oReportFilter.typeOfWorkerId" @change="ClearReport()"
                    optionLabel="name" optionValue="typeOfWorkerId" class="w-full" />
                  <label for="lblTipoTrabajador">Tipo trabajador</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-3">
                <FloatLabel>
                  <Dropdown :options="oListMineCondition"
                    v-model="oReportFilter.mineConditionId" @change="ClearReport()"
                    optionLabel="name" optionValue="mineConditionId" class="w-full" />
                  <label for="lblTipoTrabajador">Condición mina</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-4">
                <FloatLabel>
                  <MultiSelect v-model="selectedStatusAptitude" 
                    :options="oListStatusAptitude" optionLabel="name" 
                    optionValue="medicalExaminationStatusAptitudeId" @change="ClearReport"
                    :maxSelectedLabels="3" class="w-full"  />
                    <label for="lblTipoTrabajador">Aptitud emo</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-3" v-if="oListStatusGeneral.length > 0">
                <FloatLabel>
                  <Dropdown :options="oListStatusGeneral" @change="ClearReport()"
                    v-model="oReportFilter.statusGeneralId"
                    optionLabel="name" optionValue="statusGeneralId" class="w-full" />
                  <label for="lblTipoTrabajador">Estatus general curso</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-3" v-if="oListCityOrigin.length > 0">
                <FloatLabel>
                  <MultiSelect v-model="selectedProfile" 
                    :options="oListCityOrigin" optionLabel="originCity" 
                    optionValue="provinceId" @change="LoadReportTemp"
                    placeholder="Seleccione una ciudad" 
                    :maxSelectedLabels="3" class="w-full"  />
                  <label for="lblPerfil">Ciudad origen</label>
                </FloatLabel>
              </div>

              <div class="field col-12 md:col-2">
                <Button label="Buscar" class="button-primary"  @click="LoadReport" />
              </div>
            </div>
          </div>
  
          <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
            <template #start>
              <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0"></div>
            </template>
          </Toolbar>
          <div class="m-0">
            <DataTable
              :value="oListReport"
              :rowHover="true"
              :loading="bCargando"
              class="p-datatable-sm"
            >
              <Column field="originCity" header="Ciudad origen">
                <template #footer>
                  <strong class="grid-totals">Total general</strong>
                </template>
              </Column>
              <Column field="originCityTotal" header="Total"> 
                <template #body="slotProps">
                  <span class="grid-details" @click="ViewDetail(slotProps.data.provinceId, slotProps.data.originCity)" v-tooltip.top="`Ver detalle`">{{ slotProps.data.originCityTotal }}</span>
                </template>
                <template #footer>
                  <span class="grid-totals-link" @click="ViewDetail(0, 'todas las provincias')" v-tooltip.top="`Ver detalle`">{{ total }}</span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

        <div class="card mb-0 mt-2" v-if="oListReportDetail.length > 0">
          <div class="field col-12 md:col-12 mb-1 pb-3">
            <h6>Detalle reporte de <b>{{ nameDetail }}</b></h6>
          </div>
          <div class="m-0">
            <DataTable
              :value="oListReportDetail"
              :rowHover="true"
              :loading="bCargando"
              class="p-datatable-sm"
            >
              <Column field="documentNumber" header="D.N.I."></Column>
              <Column field="originCityTotal" header="Nombres y apellidos">
                <template #body="slotProps">
                  {{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
                </template>
              </Column>
              <Column field="jobName" header="Cargo"></Column>
              <Column field="typeOfWorkerName" header="Tipo trabajador"></Column>
              <Column field="mineConditionName" header="Condición mina"></Column>
              <Column field="provinceName" header="Ciudad origen"></Column>
              <Column field="epp" header="EPP"></Column>
              <Column field="medicalExaminationName" header="EMO"></Column>
              <Column field="aptitudEmo" header="Aptitud Emo"></Column>
              <Column field="progress" header="Progreso"></Column>
              <Column field="courses" header="Cursos"></Column>             
              
            </DataTable>
          </div>
        </div>

      </div>
    </div>
  </template>
  
  <script setup>
  import CommonService from "@/service/CommonService";
  import ProjectService from "@/service/ProjectService";
  import ReportService from "@/service/ReportService";
  import { FilterMatchMode } from "primevue/api";
  import { usePrimeVue } from "primevue/config";
  import { useToast } from "primevue/usetoast";
  import { computed, onBeforeMount, onMounted, ref } from "vue";
  import { useStore } from "vuex";
  
  const store = useStore();
  const toast = useToast();
  
  const projectService = new ProjectService();
  const reportService = new ReportService();
  const commonService = new CommonService();
  
  const userStore = JSON.parse(store.state.datauser);
  
  const bCargando = ref(false);
  const oReportFilter = ref({
    jobPositionId: 0,
    typeOfWorkerId: 0,
    projectId: 0,
    mineConditionId: 0,
    medicalExaminationStatusAptitudeId: 0,
    statusGeneralId: -1
  });
  
  const oListProjects = ref([]);
  const oListCityOrigin = ref([]);
  const selectedProfile = ref();
  const oListWorkerType = ref([]);
  const oListMineCondition = ref([]);
  const oListStatusAptitude = ref([]);
  const selectedStatusAptitude = ref([]);
  const oListStatusGeneral = ref([]);
  
  const validatedProject = ref(false);
  const oReport = ref({});
  const oListReportGeneral = ref([]);
  const oListReport = ref([]);
  const oListReportDetail = ref([]);
  const nameDetail = ref('');

  const filtros = ref({});
  
  //#region Eventos
  onMounted(() => {
      Initialize();
      CambiarEspanol();
  });
  
  onBeforeMount(() => {
      InitializeFilters();
  });
  //#endregion
  
  //#region Metodos
  const Initialize = () => {
      InitializeFilters();
      LoadFilters();
  } 
  
  const InitializeFilters = () => {
      filtros.value = {
          global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
      };
  };
  
  const CambiarEspanol = () => {
      const primevue = usePrimeVue();
      primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
      primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
      primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
      primevue.config.locale.firstDayOfWeek = 1;
      primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
      primevue.config.locale.today = "Hoy";
      primevue.config.locale.clear = "Limpiar";
      primevue.config.locale.dateFormat = "dd/mm/yy";
      primevue.config.locale.weekHeader = "Sem";  
  }
  //#endregion

  //#region Cálculo de los totales
  const total = computed(() => {
    return oListReport.value.reduce((sum, item) => sum + item.originCityTotal, 0);
  });
  //#endregion
  
  const LoadFilters = async() => {
      const request = userStore.enterprise.enterpriseId;
      const response = await projectService.GetProjectByEnterpriseService(request).then();
      if(response.code == 200 && response.data.length > 0){
          oListProjects.value = response.data.filter(x => x.isCompleted).map((item, index) => ({
                ...item,
                name: item.service.name + ' - ' + item.code
            }));
          const sinServicio = { projectId: 0, name: "Seleccione un proyecto" };
          oListProjects.value.unshift(sinServicio);
      }
      bCargando.value = false;
      LoadTypeOfWorker();
      LoadMineCondition();
      LoadStatusAptitude();
  }
  
  const LoadTypeOfWorker = async() => {
      bCargando.value = true;
      const response = await commonService.GetTypeOfWorkerService().then();
      if(response.code == 200){
          oListWorkerType.value = response.data;
          const todos = { typeOfWorkerId: 0, name: "Todos" };
          oListWorkerType.value.unshift(todos);
          bCargando.value = false;
      }
  }

  const LoadMineCondition = async() => {
      bCargando.value = true;
      const response = await commonService.GetMineConditionService().then();
      if(response.code == 200){
          oListMineCondition.value = response.data;
          const todos = { mineConditionId: 0, name: "Todos" };
          oListMineCondition.value.unshift(todos);
          bCargando.value = false;
      }
  }

  const LoadStatusAptitude = async() => {
      bCargando.value = true;
      const response = await commonService.GetMedicalExaminationStatusAptitudeService().then();
      if(response.code == 200){
        oListStatusAptitude.value = response.data;

        selectedStatusAptitude.value = oListStatusAptitude.value.map(item => item.medicalExaminationStatusAptitudeId);
          // const todos = { medicalExaminationStatusAptitudeId: 0, name: "Todos" };
          // oListStatusAptitude.value.unshift(todos);
          bCargando.value = false;
      }
  }
  
  const LoadStatusGeneral = async() => {
    
    if(oReportFilter.value.projectId > 0){
      bCargando.value = true;
      const response = await reportService.GetReportNumberMissingCouses(oReportFilter.value.projectId).then();
      if(response.code == 200){
        oListStatusGeneral.value = response.data.map(id => ({
            statusGeneralId: id,
            name: id == -1 ? 'Todos' : (id == 1 ? '1 curso' : id + ' cursos')
        }));
        
        bCargando.value = false;
      }
    }      
  }

  const LoadReport = async() => {
    validatedProject.value = false;
    if(oReportFilter.value.projectId == 0){
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor seleccione un proyecto.', life: 3000 });
      validatedProject.value = true;
      return;
    }
    if(selectedProfile.value?.length > 0){
      const list = [ ...oListReportGeneral.value ];
      const coindicendes = list.filter(item1 => 
        selectedProfile.value.includes(item1.provinceId)
      );
  
        oListReport.value = coindicendes;
      return
    }
    
    const filterAptitud = selectedStatusAptitude.value.map((item, index) => ({ baseId : item}));


    
    const request = {
                      projectId: oReportFilter.value.projectId,
                      typeOfWorkerId: oReportFilter.value.typeOfWorkerId,
                      mineConditionId: oReportFilter.value.mineConditionId,
                      quantityMissingCourses: oReportFilter.value.statusGeneralId,
                      listAptitudEmo: filterAptitud
                    };
    
    const response = await reportService.GetReportQuantityOrigenPlace(request).then();
    if(response.code == 200){
      oReport.value = response.data;
      oListReportGeneral.value = response.data?.details ?? [];
  
      oListReport.value = [ ...oListReportGeneral.value ];
      const JobPosition = [ ...oListReport.value ];
      oListCityOrigin.value = JobPosition;
      selectedProfile.value = oListCityOrigin.value.map(item => item.provinceId);
    }
    bCargando.value = false;
  }
  
  const LoadReportTemp = async() => {
    oListReport.value = [];
    if(selectedProfile.value?.length > 0 && oListReportGeneral.value?.length > 0){
      const list = [ ...oListReportGeneral.value ];
      const coindicendes = list.filter(item1 => 
        selectedProfile.value.includes(item1.provinceId)
      );
  
      oListReport.value = coindicendes;
      return
    }
  }
  
  const ClearReport = async() => {
      oReport.value = {};
      oListReport.value = [];
      selectedProfile.value = [];
      oListCityOrigin.value = [];
      oListReportDetail.value = [];
      LoadStatusGeneral();
  }

  const ViewDetail = async(provinceId, provinceName) => {
    if(oReportFilter.value.projectId == 0){
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor seleccione un proyecto.', life: 3000 });
      validatedProject.value = true;
      return;
    }
    
    const response = await reportService.GetReportMedicalExaminationDetails(oReportFilter.value.projectId, 
                            oReportFilter.value.typeOfWorkerId,
                            oReportFilter.value.mineConditionId,
                            oReportFilter.value.medicalExaminationStatusAptitudeId,
                            provinceId
                        ).then();
    
    if(response.code == 200){
      oListReportDetail.value = response.data;
      nameDetail.value = provinceName;
    }

    bCargando.value = false;
  }
  
  
  //#endregion
  </script>