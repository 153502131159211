<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
            <template #start>
              <h1>Nuevo puesto de trabajo</h1>
            </template>
  
            <template #end> </template>
          </Toolbar>
          <ConfirmDialog></ConfirmDialog>
          <div class="flex flex-column h-500rem">
            <div class="p-fluid p-formgrid grid">
              <div class="field col-12 md:col-4" v-if="jobEditId != ''">
                <FloatLabel>
                  <InputText id="txtCode" v-model="oJob.code" autocomplete="off" readonly />
                  <label for="lblCode">Código</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-4"></div>
              <div class="field col-12 md:col-4"></div>
              <div class="field col-12 md:col-8">
                <FloatLabel>
                  <InputText id="txtName" v-model="oJob.name" autocomplete="off" :class="{ 'p-invalid' : validatedName }" />
                  <label for="lblName">Nombre</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-4"></div>
              <div class="field col-12 md:col-8">
                <FloatLabel>
                  <Textarea id="txtReferencia" v-model="oJob.description" rows="2" cols="30" />
                  <label for="lblReferencia">Descripción</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-4"></div>
            </div>
          </div>

          <Divider layout="horizontal" />
          <div class="flex justify-content-start">
            <Button
              label="Guardar"
              icon="pi pi-save"
              severity="primary"
              iconPos="right"
              @click="SaveJob()"
              :loading="bCargando"
            />
            <Button
              label="Atrás"
              icon="pi pi-arrow-left"
              class="ml-2"
              severity="secondary"
              @click="BackTo"
            ></Button>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialog group="headless">
      <template #container="{ message, acceptCallback }">
          <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
              <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                  <i class="pi pi-check text-5xl"></i>
              </div>
              <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
              <p class="mb-0">{{ message.message }}</p>
              <div class="flex align-items-center gap-2 mt-4">
                  <Button label="Aceptar" @click="acceptCallback"></Button>
              </div>
          </div>
      </template>
    </ConfirmDialog>
  </template>
  
  <script setup>
  import { onMounted, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { useToast } from "primevue/usetoast";
  import { useConfirm } from "primevue/useconfirm";
  import Utilitario from "@/utilitarios/Utilitario";
  import Crypto from "@/utilitarios/Crypto";
  import JobService from "@/service/JobService";
  
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
    
  const jobService = new JobService();
  const userStore = JSON.parse(store.state.datauser);
  
  const bCargando = ref(false);
  const oJob = ref({
    name: ''
  });
  
  const validatedName = ref(false);
  const jobEditId = ref('');
  
  //#region Eventos
  onMounted(() => {
    Initialize();
  });
  //#endregion
  
  //#region Metodos
  const Initialize = () => {
    jobEditId.value = Crypto.Desencriptar(route.params.id);
    if(!Utilitario.StringIsNullOrEmpty(jobEditId.value)){
        LoadJob(jobEditId.value);
    }
  };
  
  const BackTo = () => {
    router.push({ path: `/portal/puesto/mantenedor` });
  };
  
  const LoadJob = async(id) => {
      bCargando.value = true;
      const response = await jobService.GetJobByIdService(id).then();
      if(response.code == 200){
        oJob.value = response.data;
          bCargando.value = false;
      }
  }
  
  const SaveJob = async() => {
    
    if(await ValidateJob()){
        CreateUpdateJob();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
  }
  
  const ValidateJob = async() => {
    const { ...model } = oJob.value;
    const valid = ref(false);
    
    if(!model?.name){
      validatedName.value = true;
      valid.value = true;
    }
    else{
      validatedName.value = false;
    }
  
    return !valid.value;
  }
  
  const CreateUpdateJob = async() => {
    const { ...model } = oJob.value;
  
    const request = {
      ...model,
      creationUser: userStore.user.idUser
    };
    
    confirm.require({
        message: `¿Está seguro de guardar el puesto de trabajo?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        acceptClass: 'p-button-primary',
        accept: async () => {
          try{
  
            const response = await jobService.CreateUpdateJobService(request).then();
            if(response.code == 201){
              confirm.require({
                  group: 'headless',
                  header: 'Felicidades',
                  message: `Se guardó el puesto de trabajo #${response.data.code} correctamente`,
                  accept: async() => {
                      bCargando.value = false;
                      router.push({ path: `/portal/puesto/mantenedor` });
                  }
              });
            }
          }
          catch (error) {
              bCargando.value = false;
              console.error(`Error al guardar Servicio:`, error);
          }
        }
    });
  
  }
  //#endregion
  </script>