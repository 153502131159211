export default class RoleService {
    async GetRoleService(roleId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Security/userType?IdUserType=${roleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }
    
    async CreateUpdateRoleService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Security/userType', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async GetMenuByRoleService(roleId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Security/permission/userType?IdUserType=${roleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    
    async CreateUpdateMenuRoleService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Security/permission', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async GetPermissionByRoleService(roleId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Security/permissionForm?UserTypeId=${roleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async CreateUpdatePermissionRoleService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Security/permissionForm', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async GetPermissionEnterpriseByRoleService(roleId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Security//permissionEnterprise?UserTypeId=${roleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async CreateUpdatePermissionEnterpriseRoleService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Security/permissionEnterprise', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }
}