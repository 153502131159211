<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Configurar Proyecto</h1>
                    </template>

                    <template #end>
                    </template>
                </Toolbar>
                <Stepper :linear="false" :activeStep="activeStep">
                    <StepperPanel header="Información" v-if="ssPermissionStep1.viewEnabled">
                        <template #content="{ nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <div class="field col-12 md:col-4">
                                        <FloatLabel>
                                            <Dropdown v-model="oProject.enterpriseId" :options="oListCompany" 
                                                optionLabel="name" optionValue="enterpriseId" class="w-full" 
                                                :class="{ 'p-invalid' : validatedEnterpriseId }" />
                                            <label for="lblUnidadMedida">Empresa</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4" v-if="projectIdEdit > 0">
                                        <FloatLabel>
                                            <InputText id="txtProjectId" type="hidden" />
                                            <InputText id="txtProjectCode" autocomplete="off" v-model="oProject.code" readonly />
                                            <label for="lblProjectCode">Código</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4"></div>
                                    <div class="field col-12 md:col-8">
                                        <FloatLabel>
                                            <Dropdown v-model="oProject.serviceId" :options="oListService" 
                                                optionLabel="name" optionValue="serviceId" class="w-full" 
                                                :class="{ 'p-invalid' : validatedServiceId }" />
                                            <label for="lblService">Servicio</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4"></div>
                                    <div class="field col-12 md:col-8">
                                        <FloatLabel>
                                            <InputText id="txtProjectName" autocomplete="off" v-model="oProject.name" 
                                                :class="{ 'p-invalid' : validatedProjectName }" />
                                            <label for="lblProjectName">Nombre Proyecto</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4"></div>
                                    <div class="field col-12 md:col-8">
                                        <FloatLabel>
                                            <Textarea id="txtProjectDescription" v-model="oProject.description" rows="2" cols="30"
                                                :class="{ 'p-invalid' : validatedDescription }" />
                                            <label for="lblProjectDescription">Descripción</label>
                                        </FloatLabel>
                                    </div>                        
                                    <div class="field col-12 md:col-4"></div>
                                    <div class="field col-12 md:col-4">
                                        <FloatLabel>
                                            <Calendar showIcon iconDisplay="input" v-model="oProject.startDate"
                                                inputId="dtpStartDate" dateFormat="dd/mm/yy" 
                                                :class="{ 'p-invalid' : validatedStartDate }" />
                                            <label for="lblStartDate">Fecha Inicio</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4">
                                        <FloatLabel>
                                            <Calendar showIcon iconDisplay="input" v-model="oProject.endDate"
                                                inputId="dtpEndDate" dateFormat="dd/mm/yy" 
                                                :class="{ 'p-invalid' : validatedEndDate }" />
                                            <label for="lblEndDate">Fecha Fin</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4"></div>
                                    <div class="field col-12 md:col-4">
                                        <FloatLabel>
                                            <Calendar showIcon iconDisplay="input" v-model="oProject.stopDate"
                                                inputId="dtpStopDate" dateFormat="dd/mm/yy" 
                                                :class="{ 'p-invalid' : validatedStopDate }" />
                                            <label for="lblStopDate">Fecha Parada</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4">
                                        <FloatLabel>
                                            <Calendar showIcon iconDisplay="input" v-model="oProject.preStopDate"
                                                inputId="dtpPreStopDate" dateFormat="dd/mm/yy" 
                                                :class="{ 'p-invalid' : validatedPreStopDate }" />
                                            <label for="lblPreStopDate">Fecha Pre-Parada</label>
                                        </FloatLabel>
                                    </div>
                                    <div class="field col-12 md:col-4"></div>
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                                <Button label="Guardar y continuar" icon="pi pi-save" severity="primary" iconPos="right" 
                                    @click="() => GuardarContinuar(nextCallback)" :loading="bCargando" v-if="ssPermissionStep1.createEnabled" />
                            </div>

                        </template>
                    </StepperPanel>
                    <StepperPanel header="Puestos" v-if="ssPermissionStep2.viewEnabled">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <RequiredJob @update-items="updateItems" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)" />
                                </div>
                            </div>
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                                <Button label="Guardar y continuar" icon="pi pi-arrow-right" severity="primary" iconPos="right" :loading="bCargando"
                                    @click="() => SaveContinueRequiredJobs(nextCallback)" v-if="ssPermissionStep2.createEnabled" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Cursos" v-if="ssPermissionStep3.viewEnabled">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <CourseManagement @sendListPrimary="AddCoursePrimary" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)"  />
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                                <Button label="Guardar y continuar" icon="pi pi-arrow-right" severity="primary" iconPos="right" :loading="bCargando" 
                                @click="() => SaveContinueCourses(nextCallback)" v-if="ssPermissionStep3.createEnabled" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Certificaciones" v-if="ssPermissionStep4.viewEnabled">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <CertificationManagment @sendListPrimary="AddCertificationPrimary" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)" />
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                                <Button label="Guardar y continuar" icon="pi pi-arrow-right" severity="primary" iconPos="right"  :loading="bCargando"
                                @click="() => SaveContinueCertification(nextCallback)" v-if="ssPermissionStep4.createEnabled" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Emos" v-if="ssPermissionStep5.viewEnabled">
                        <template #content="{ prevCallback, nextCallback  }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <EmoManagement @sendListPrimary="AddEmoPrimary" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)" />
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback"  />
                                <Button label="Guardar y finalizar" icon="pi pi-save" severity="primary" iconPos="right" :loading="bCargando"
                                @click="() => SaveContinueEmos(nextCallback)" v-if="ssPermissionStep5.createEnabled" />
                            </div>

                        </template>
                    </StepperPanel>
                    <StepperPanel header="Vacunas" v-if="ssPermissionStep6.viewEnabled">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <VaccineManagment @sendListPrimary="AddVaccinePrimary" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)" />
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback"  />
                                <Button label="Guardar y continuar" icon="pi pi-save" severity="primary" iconPos="right" :loading="bCargando"
                                @click="() => SaveContinueVaccine(nextCallback)" v-if="ssPermissionStep6.createEnabled" />
                            </div>

                        </template>
                    </StepperPanel>
                    <StepperPanel header="Documentos" v-if="ssPermissionStep7.viewEnabled">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <DocumentManagment @sendListPrimary="AddDocumentPrimary" :ppProjectId="Number(oProject.projectId)" :ppKey="Number(pKey)" />
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback"  />
                                <Button label="Guardar y continuar" icon="pi pi-save" severity="primary" iconPos="right" :loading="bCargando"
                                @click="() => SaveContinueDocument(nextCallback)" v-if="ssPermissionStep7.createEnabled" />
                            </div>

                        </template>
                    </StepperPanel>
                    <StepperPanel header="Terminar" v-if="ssPermissionStep8.viewEnabled">
                        <template #content="{ prevCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="p-fluid p-formgrid grid mt-3">
                                    <div class="col-12 md:col-12">
                                        <h5>Termino de la configuración</h5>
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <FloatLabel>
                                            <Checkbox v-model="oProject.isCompleted" inputId="chkEnableFamilyAllowance" name="chkEnableFamilyAllowance" :binary="true"/>
                                            <label for="lblEnableFamilyAllowance" class="ml-3">Configuración completa</label>
                                        </FloatLabel>
                                    </div>
                                </div>
                            </div>
                            
                            <Divider layout="horizontal" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                                <Button label="Guardar y finalizar" icon="pi pi-save" severity="primary" iconPos="right" :loading="bCargando"  
                                @click="() => FinishConfiguracion()" v-if="ssPermissionStep8.createEnabled" />
                            </div>

                        </template>
                    </StepperPanel>
                </Stepper>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    <ConfirmDialog group="msgWarning">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CourseManagement from './ProjectStep/CourseManagement.vue';
import ProjectService from '@/service/ProjectService';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useStore } from 'vuex';
import RequiredJob from './ProjectStep/RequiredJob.vue';
import EnterpriseService from '@/service/EnterpriseService';
import EmoManagement from './ProjectStep/EmoManagement.vue';
import CertificationManagment from './ProjectStep/CertificationManagment.vue';
import { usePrimeVue } from 'primevue/config';
import Utilitario from '@/utilitarios/Utilitario';
import Crypto from '@/utilitarios/Crypto';
import JobPositionService from '@/service/JobPositionService';
import VaccineManagment from './ProjectStep/VaccineManagment.vue';
import ServiceService from '@/service/ServiceService';
import { useLayout } from '@/layout/composables/layout';
import DocumentManagment from './ProjectStep/DocumentManagment.vue';

const router = useRouter();
const route = useRoute();
const toast = useToast();
const confirm = useConfirm();
const store = useStore();
const { onMenuToggleFalse } = useLayout();

const enterpriseService = new EnterpriseService();
const projectService = new ProjectService();
const jobPositionService = new JobPositionService();
const serviceService = new ServiceService();

const pKey = ref(0);

const userStore = JSON.parse(store.state.datauser);
const ssPermissionStep1 = ref({});
const ssPermissionStep2 = ref({});
const ssPermissionStep3 = ref({});
const ssPermissionStep4 = ref({});
const ssPermissionStep5 = ref({});
const ssPermissionStep6 = ref({});
const ssPermissionStep7 = ref({});
const ssPermissionStep8 = ref({});

const oListCompany = ref([]);
const oListService = ref([]);
const oProject = ref({
    projectId: route.params.id ? Crypto.Desencriptar(route.params.id) : 0,
    enterpriseId: userStore.enterprise.enterpriseId || 0
});
const oListRequiredJobs = ref([]);
const oListCourses = ref([]);
const oListCertification = ref([]);
const oListEmos = ref([]);
const oListVaccine = ref([]);
const oListDocument = ref([]);

const bCargando = ref(false);
const projectIdEdit = ref(0);
const activeStep = ref(0);

const validatedServiceId = ref(false);
const validatedEnterpriseId = ref(false);
const validatedProjectName = ref(false);
const validatedDescription = ref(false);
const validatedStartDate = ref(false);
const validatedEndDate = ref(false);
const validatedStopDate = ref(false);
const validatedPreStopDate = ref(false);

//#region Eventos
onMounted(() => {
    Initialize();
    CambiarEspanol();
});
//#endregion

//#region Metodos
const Initialize = () => {
    onMenuToggleFalse(true);
    LoadPermission();
    LoadEnterpriseByCountry();
    LoadService();
    projectIdEdit.value = Crypto.Desencriptar(route.params.id);
    activeStep.value = route.params.step ? parseInt(Crypto.Desencriptar(route.params.step)) : 0;
    if(!Utilitario.StringIsNullOrEmpty(projectIdEdit.value)){
        LoadProject(projectIdEdit.value);
    }
} 

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const LoadPermission = () => {
    ssPermissionStep1.value = Utilitario.obtenerPermisos('PR00014');
    ssPermissionStep2.value = Utilitario.obtenerPermisos('PR00015');
    ssPermissionStep3.value = Utilitario.obtenerPermisos('PR00016');
    ssPermissionStep4.value = Utilitario.obtenerPermisos('PR00017');
    ssPermissionStep5.value = Utilitario.obtenerPermisos('PR00018');
    ssPermissionStep6.value = Utilitario.obtenerPermisos('PR00019');
    ssPermissionStep7.value = Utilitario.obtenerPermisos('PR00020');
    ssPermissionStep8.value = Utilitario.obtenerPermisos('PR00021');
}

const BackTo = () => {
    if(activeStep.value==0){
        router.go(-1)
    }
    else{
        router.push({ path: `/portal/proyecto/mantenedor` });
    }    
}

const LoadEnterpriseByCountry = () => {
    const countryId = userStore.enterprise.countryId;
    enterpriseService.GetEnterpriseByCountryService(countryId, userStore.user.idUserType).then(
        response => {
            oListCompany.value = response.data;
        }
    );
}

const LoadService = () => {
    serviceService.GetServiceByIdService(0).then(
        response => {
            oListService.value = response.data.filter(x => x.status);
        }
    );
}

const LoadProject = async(id) => {
    bCargando.value = true;
    const response = await projectService.GetProjectByIdService(id).then();
    if(response.code == 200){
        oProject.value = response.data;
        oProject.value.serviceId = response.data.service?.serviceId || 0;
        oProject.value.startDate = (response.data.startDate == '0001-01-01T00:00:00' ? null : Utilitario.formatoDate(response.data.startDate));
        oProject.value.endDate = (response.data.endDate == '0001-01-01T00:00:00' ? null : Utilitario.formatoDate(response.data.endDate));
        oProject.value.stopDate = (response.data.stopDate == '0001-01-01T00:00:00' ? null : Utilitario.formatoDate(response.data.stopDate));
        oProject.value.preStopDate = (response.data.preStopDate == '0001-01-01T00:00:00' ? null : Utilitario.formatoDate(response.data.preStopDate));
        bCargando.value = false;
    }
}

const GuardarContinuar = async (nextCallback) => {
    if(await ValidetProject()){
        if(await CreateUpdateProject()){
            nextCallback();
        }
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidetProject = async() => {
    const { ...model } = oProject.value;
    const valid = ref(false);
    
    if(!model?.serviceId){
        validatedServiceId.value = true;
        valid.value = true;
    }
    else{
        validatedServiceId.value = false;
    }

    if(!model?.enterpriseId){
        validatedEnterpriseId.value = true;
        valid.value = true;
    }
    else{
        validatedEnterpriseId.value = false;
    }

    if(!model?.name){
        validatedProjectName.value = true;
        valid.value = true;
    }
    else{
        validatedProjectName.value = false;
    }

    if(!model?.description){
        validatedDescription.value = true;
        valid.value = true;
    }
    else{
        validatedDescription.value = false;
    }

    if(!model?.startDate){
        validatedStartDate.value = true;
        valid.value = true;
    }
    else{
        validatedStartDate.value = false;
    }

    if(!model?.endDate){
        validatedEndDate.value = true;
        valid.value = true;
    }
    else{
        validatedEndDate.value = false;
    }

    if(!model?.stopDate){
        validatedStopDate.value = true;
        valid.value = true;
    }
    else{
        validatedStopDate.value = false;
    }

    if(!model?.preStopDate){
        validatedPreStopDate.value = true;
        valid.value = true;
    }
    else{
        validatedPreStopDate.value = false;
    }

    return !valid.value;
}

const CreateUpdateProject = async() => {
    return new Promise((resolve, reject) => {
        const { ...model } = oProject.value;

        const request = { 
            ...model,
            projectStatus: 0,
            quantityRequiredJobs: 0,
            CreationUser: userStore.user.idUser
        }

        confirm.require({
                message: `¿Está seguro de guardar el proyecto?`,
                header: 'Confirmación',
                icon: 'pi pi-info-circle',
                rejectLabel: 'No',
                acceptLabel: 'Si',
                accept: async () => {
                    try{
                        const response = await projectService.CreateUpdateProjectService(request);
                        if(response.code == 201){
                            confirm.require({
                                group: 'headless',
                                header: 'Felicidades',
                                message: `Se guardó el proyecto #${response.data.code} correctamente`,
                                accept: async() => {
                                    bCargando.value = false;
                                    activeStep.value = 1;
                                    oProject.value.projectId = response.data.projectId;
                                    projectIdEdit.value = response.data.projectId;
                                    const id = encodeURIComponent(Crypto.Encriptar(response.data.projectId));
                                    const step = encodeURIComponent(Crypto.Encriptar(1));
                                    router.push({ path: `/portal/proyecto/editar/${id}/${step}` });
                                    resolve(true);
                                }
                            });
                        }
                        else{
                            resolve(false);
                        }
                    }
                    catch (error) {
                        bCargando.value = false;
                        console.error(`Error al Guardar:`, error);
                        reject(false);
                    }
                }
        });

    });
}

const SaveContinueRequiredJobs = async(nextCallback) => {
    if(oListRequiredJobs.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos un puesto`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{
        const newListJobs = [];
        
        newListJobs.value = oListRequiredJobs.value.map(item => ({
            jobId: Number.isInteger(item.jobId) ? item.jobId : 0,
            jobPositionId: Number.isInteger(item.jobPositionId) ? item.jobPositionId : 0,
            name: item.name,
            requiredQuantity: item.requiredQuantity,
            experienceTime: item.experienceTime,
            professionId: item.profession.professionId,
            specialtyId: item.specialty.specialtyId
        }));
        
        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                jobPositions: newListJobs.value
            };
            
        confirm.require({
            message: `¿Está seguro de guardar los puestos requeridos?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron los puestos correctamente`,
                            accept: async() => {
                                pKey.value++;
                                nextCallback();
                                bCargando.value = false;
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar:`, error);
                }
            }
        });
    }
}

const updateItems = (newItems) => {
    oListRequiredJobs.value = newItems;
}

const AddCoursePrimary = (newCourses) => {
    oListCourses.value = newCourses;
}

const AddCertificationPrimary = (newCertification) => {
    oListCertification.value = newCertification;
}

const AddEmoPrimary = (newEmos) => {
    oListEmos.value = newEmos;
}

const AddVaccinePrimary = (newVaccine) => {
    oListVaccine.value = newVaccine;
}

const AddDocumentPrimary = (newDocument) => {
    oListDocument.value = newDocument;
}

const SaveContinueCourses = async(nextCallback) => {
    if(oListCourses.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos un curso`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{
        const newListCourses = [];
        newListCourses.value = oListCourses.value.flatMap(item => 
            item.courses.map(detail => ({
                jobPositionId: item.jobPositionId,
                courseId: detail.courseId,
                requiredDocument: detail.requiredDocument ?? false
            }))            
        );

        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                courses: newListCourses.value
            };
        
        confirm.require({
            message: `¿Está seguro de guardar los cursos por puesto?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionCoursesService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron los cursos correctamente`,
                            accept: async() => {
                                pKey.value++;
                                bCargando.value = false;
                                nextCallback();
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar cursos:`, error);
                }
            }
        });
    }
}

const SaveContinueCertification = async(nextCallback) => {
    if(oListCertification.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos una certificación`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{

        const newListCertificates = [];
        newListCertificates.value = oListCertification.value.flatMap(item => 
            item.certificates.map(detail => ({
                jobPositionId: item.jobPositionId,
                certificateId: detail.certificateId,
                requiredDocument: detail.requiredDocument ?? false
            }))            
        );

        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                certificates: newListCertificates.value
            };

        confirm.require({
            message: `¿Está seguro de guardar y continuar?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionCertificatesService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron las certificaciones correctamente`,
                            accept: async() => {
                                pKey.value++;
                                nextCallback();
                                bCargando.value = false;
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar certificaciones:`, error);
                }
            }
        });
    }
}

const SaveContinueEmos = async(nextCallback) => {
    if(oListEmos.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos un emo`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{
        const newListEmos = [];
        newListEmos.value = oListEmos.value.flatMap(item => 
            item.medicalExaminations.map(detail => ({
                jobPositionId: item.jobPositionId,
                medicalExaminationId: detail.medicalExaminationId,
                requiredDocument: detail.requiredDocument ?? false
            }))
        );

        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                medicalExaminations: newListEmos.value
            };

        confirm.require({
            message: `¿Está seguro de guardar y finalizar?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionEmoService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron los emos correctamente`,
                            accept: async() => {
                                pKey.value++;
                                nextCallback();
                                bCargando.value = false;
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar cursos:`, error);
                }
            }
        });
    }
}

const SaveContinueVaccine = async(nextCallback) => {
    /*if(oListEmos.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos unemo`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{*/
        const newListVaccine = [];
        newListVaccine.value = oListVaccine.value.flatMap(item => 
            item.vaccines.map(detail => ({
                jobPositionId: item.jobPositionId,
                vaccineId: detail.vaccineId,
                requiredDocument: detail.requiredDocument ?? false
            }))
        );

        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                vaccines: newListVaccine.value
            };

        confirm.require({
            message: `¿Está seguro de guardar y continuar?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionVaccinesService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron las vacunas correctamente`,
                            accept: async() => {
                                pKey.value++;
                                nextCallback();
                                bCargando.value = false;
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar vacunas:`, error);
                }
            }
        });
    //}
}

const SaveContinueDocument = async(nextCallback) => {
    if(oListEmos.value.length == 0){
        confirm.require({
                group: 'msgWarning',
                header: 'Advertencia',
                message: `Tiene que ingresar al menos un documento`,
                accept: async() => {
                    bCargando.value = false;
                }
            });
    }    
    else{
        const newListDocument = [];
        newListDocument.value = oListDocument.value.flatMap(item => 
            item.documents.map(detail => ({
                jobPositionId: item.jobPositionId,
                documentId: detail.documentId,
                requiredDocument: detail.requiredDocument ?? false
            }))
        );

        const request = {
                projectId: projectIdEdit.value,
                creationUser: userStore.user.idUser,
                documents: newListDocument.value
            };

        confirm.require({
            message: `¿Está seguro de guardar y continuar?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    const response = await jobPositionService.CreateUpdateJobPositionDocumentsService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron los documentos correctamente`,
                            accept: async() => {
                                pKey.value++;
                                nextCallback();
                                bCargando.value = false;
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar documentos:`, error);
                }
            }
        });
    }
}

const FinishConfiguracion = async () => {
    if(await ValidetProject()){
        if(await CreateUpdateProject()){
            router.push({ path: `/portal/proyecto/mantenedor` });
        }
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

//#endregion

</script>
