export default class FileService {
    async DowloadViewFileService(filename, type) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Files/download-files?FileName=${filename}&Operation=${type}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return response.blob();
    }
}