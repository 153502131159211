<template>
    <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
        <template #start>
            <Button label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" 
                @click="OpenModalRequiredJob()" v-if="ssPermission.createEnabled" />
        </template>
    </Toolbar>

    <!-- List Required Jobs -->
    <div class="col-12 md:col-12">
        <DataTable
            ref="dtRequiredJobs"
            :value="oListRequiredJobs"
            dataKey="requiredJobId"
            :loading="bCargando"
            :filters="filtros"
            class="p-datatable-sm"
            >
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 class="m-0">Puestos requeridos</h5>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                    </span>
                </div>
            </template>

            <Column style="text-align: center" headerStyle="min-width:3rem;" >
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" 
                        @click="EditRequiredJob(slotProps.data)" v-if="ssPermission.editEnabled" />
                    <Button icon="pi pi-trash" v-tooltip.top="'Eliminar'" class="p-button-rounded p-button-danger mt-2" 
                        @click="RemoveRequiredJob(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                </template>
            </Column>
            <Column field="name" header="Nombre">
                <template #body="slotProps">
                <b>{{ slotProps.data.job.name }}</b><br/>
                <span style="font-size: 8.5pt;">{{ slotProps.data.name }}</span>
                </template>
            </Column>
            <Column field="requiredQuantity" header="Cant."></Column>
            <Column field="experienceTime" header="Años experiencia"></Column>
            <Column field="name" header="Profesión">
                <template #body="slotProps">
                    {{ slotProps.data.profession.name }}
                </template>
            </Column>
            <Column field="name" header="Especialidad">
                <template #body="slotProps">
                    {{ slotProps.data.specialty.name }}
                </template>
            </Column>
        </DataTable>
    </div>

    <Dialog v-model:visible="modalAddRequiredJob" modal header="Agregar Puesto" :style="{ width: '30rem' }">
        <div class="grid">
            <div class="col-12">
                <div class="card">
                    <div class="flex flex-column h-500rem">
                        <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Dropdown v-model="oRequiredJobs.jobId" :options="oListJob" 
                                        optionLabel="name" optionValue="jobId" class="w-full" 
                                        :class="{ 'p-invalid' : validatedJobId }" />
                                    <label for="lblJob">Puesto</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <InputText id="txtProjectName" autocomplete="off" v-model="oRequiredJobs.name" 
                                        :class="{ 'p-invalid' : validatedProjectName }" />
                                    <label for="lblProjectName">Nombre Puesto</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputNumber v-model="oRequiredJobs.requiredQuantity" inputId="minmax" showButtons mode="decimal" :min="0" :max="100"  
                                    :class="{ 'p-invalid' : validatedQuantityRequiredJobs }" />
                                    <label for="lblQuantityRequiredJobs">Cant. Req.</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputNumber v-model="oRequiredJobs.experienceTime" inputId="minmax" showButtons mode="decimal" :min="0" :max="100"  
                                    :class="{ 'p-invalid' : validatedQuantityRequiredJobs }" />
                                    <label for="lblQuantityRequiredJobs">Años exp.</label>
                                </FloatLabel>
                            </div>
                            
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Dropdown :options="oListProfession" v-model="oRequiredJobs.professionId"
                                        optionLabel="name" optionValue="professionId" class="w-full" 
                                        :class="{ 'p-invalid' : validatedProfessionId }" 
                                        @change="LoadSpecialty()" />
                                    <label for="lblProfessionId">Profesión</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Dropdown :options="oListSpecialty" v-model="oRequiredJobs.specialtyId"
                                        optionLabel="name" optionValue="specialtyId" class="w-full" 
                                        :class="{ 'p-invalid' : validatedSpecialtyId }" />
                                    <label for="lblSpecialtyId">Especialidad</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="flex justify-end gap-2">
                    <Button type="button" label="Cancelar" severity="secondary" @click="modalAddRequiredJob = false"></Button>
                    <Button type="button" label="Aceptar" class="p-button-primary mr-2" @click="AddRequiredJob()"></Button>
                </div>
            </div>
        </div>
        
    </Dialog>
</template>

<script setup>
import JobPositionService from '@/service/JobPositionService';
import JobService from '@/service/JobService';
import ProfessionService from '@/service/ProfessionService';
import SpecialtyService from '@/service/SpecialtyService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineEmits, defineProps, watch } from 'vue';

const toast = useToast();
const bCargando = ref(false);
const filtros = ref({});
const emit = defineEmits(['update-items'])

const professionService = new ProfessionService();
const specialtyService = new SpecialtyService();
const jobPositionService = new JobPositionService();
const jobService = new JobService();

const ssPermission = ref({});

const oListJob = ref([]);
const oListProfession = ref([]);
const oListSpecialty = ref([]);
const oListRequiredJobs = ref([]);
const oRequiredJobs = ref({
    projectId: 0,
    requiredQuantity: 0,
    experienceTime: 0 
});

const modalAddRequiredJob = ref(false);
const newRequired = ref(false);

const validatedJobId = ref(false);
const validatedProjectName = ref(false);
const validatedQuantityRequiredJobs = ref(false);
const validatedProfessionId = ref(false);
const validatedSpecialtyId = ref(false);

const props = defineProps({
  ppProjectId: {
    type: Number,
    required: true
  },
  ppKey: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadJobPosition();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadJob();
    LoadProfession();
    LoadJobPosition();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00015');
}

const LoadJob = async() => {
    bCargando.value = true;
    const response = await jobService.GetJobByIdService(0).then();
    if(response.code == 200){
        oListJob.value = response.data.filter(x => x.status);
        bCargando.value = false;
    }
}


const LoadProfession = async() => {
    bCargando.value = true;
    const response = await professionService.GetProfessionService().then();
    if(response.code == 200){
        oListProfession.value = response.data;
        bCargando.value = false;
    }
}

const LoadSpecialty = async() => {
    bCargando.value = true;
    const professionId = oRequiredJobs.value.professionId;
    const response = await specialtyService.GetSpecialtyService(professionId).then();
    if(response.code == 200){
        oListSpecialty.value = response.data;
        bCargando.value = false;
    }
}

const LoadJobPosition = async() => {
    bCargando.value = true;
    oRequiredJobs.value.projectId = props.ppProjectId;
    const projectId = oRequiredJobs.value.projectId;
    const response = await jobPositionService.GetJobPositionService(projectId).then();
    if(response.code == 200){
        oListRequiredJobs.value = response.data ? response.data.map(item => ({
            ...item,
            jobId: item.job.jobId
        })) : [];
        bCargando.value = false;
        emit('update-items', oListRequiredJobs.value);        
    }
}

const OpenModalRequiredJob = async() => {
    oRequiredJobs.value = {
        requiredQuantity: 0,
        experienceTime: 0 
    };
    modalAddRequiredJob.value = true;
    newRequired.value = true;
}

const ValidatedSaveRequiredJob = async() => {
    const { ...modelValidate } = oRequiredJobs.value;
    const valid = ref(false);
    
    if(!modelValidate?.jobId){
        validatedJobId.value = true;
        valid.value = true;
    }
    else{
        validatedJobId.value = false;
    }

    if(!modelValidate?.name){
        validatedProjectName.value = true;
        valid.value = true;
    }
    else{
        validatedProjectName.value = false;
    }

    if(!modelValidate?.requiredQuantity){
        validatedQuantityRequiredJobs.value = true;
        valid.value = true;
    }
    else{
        validatedQuantityRequiredJobs.value = false;
    }

    if(!modelValidate?.professionId){
        validatedProfessionId.value = true;
        valid.value = true;
    }
    else{
        validatedProfessionId.value = false;
    }

    if(!modelValidate?.specialtyId){
        validatedSpecialtyId.value = true;
        valid.value = true;
    }
    else{
        validatedSpecialtyId.value = false;
    }

    return !valid.value;
}

const AddRequiredJob = async() => {
    if(await ValidatedSaveRequiredJob()){
        SaveRequiredJob();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const SaveRequiredJob = async() => {  
    const selectedJob = oListJob.value.find(
        (profession) => profession.jobId === oRequiredJobs.value.jobId
        );
    
    const selectedProfession = oListProfession.value.find(
        (profession) => profession.professionId === oRequiredJobs.value.professionId
        );
    
    const selectedSpeciality = oListSpecialty.value.find(
        (specialty) => specialty.specialtyId === oRequiredJobs.value.specialtyId
        );
    
    const model = {
        ...oRequiredJobs.value,
        job: selectedJob,
        profession: selectedProfession,
        specialty: selectedSpeciality,
        jobPositionId: newRequired.value ? Crypto.GenerateUniqueId() : oRequiredJobs.value.jobPositionId
    };
    
    if(newRequired.value){
        oListRequiredJobs.value.push(model);
    }    
    else{
        const index = oListRequiredJobs.value.findIndex(item => item.jobPositionId === model.jobPositionId);
        if (index !== -1) {
            oListRequiredJobs.value.splice(index, 1, model);  
        }
    }
    modalAddRequiredJob.value = false;
    emit('update-items', oListRequiredJobs.value);
}

const EditRequiredJob = (item) => {
    OpenModalRequiredJob();
    newRequired.value = false;
    oRequiredJobs.value.jobId = item.job.jobId;
    oRequiredJobs.value.jobPositionId = item.jobPositionId;
    oRequiredJobs.value.name = item.name;
    oRequiredJobs.value.requiredQuantity = item.requiredQuantity;
    oRequiredJobs.value.experienceTime = item.experienceTime;
    oRequiredJobs.value.professionId = item.profession.professionId;
    LoadSpecialty();
    oRequiredJobs.value.specialtyId = item.specialty.specialtyId;
}

const RemoveRequiredJob = (itemRemove) => {
    let index = oListRequiredJobs.value.findIndex(item => item.jobPositionId === itemRemove.jobPositionId);

    if (index !== -1) {
        // Eliminar el item en ese índice
        oListRequiredJobs.value.splice(index, 1);
    }
}

//#endregion
</script>