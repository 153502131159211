<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado general</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtEmos" 
                    v-model:expandedRows="expandedRowsGeneral" 
                    :value="oListEmos"
                    dataKey="id"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column expander style="width: 1rem" />
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar emo`" class="p-button-secondary-outlined ml-2" 
                            @click="EditEmo(slotProps.data)" v-if="ssPermission.editEnabled" />
                        </template>
                    </Column>
                    <Column field="medicalExamination" header="Exámen Médico">
                        <template #body="slotProps">
                            {{ slotProps.data.medicalExamination.name }}
                        </template>
                    </Column>
                    <Column field="medicalExaminationPlace" header="Lugar" ></Column>
                    <Column field="issueDate" header="Fecha">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="effectiveDate" header="Vigencia">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.effectiveDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="GeneralCondition" header="Estado General EMO">
                        <template #body="slotProps">
                            <Tag v-if="slotProps.data.medicalExaminationStatus.name" :value="slotProps.data.medicalExaminationStatus.name" severity="info" />
                        </template>
                    </Column>
                    <Column field="StatusObservation" header="">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': !slotProps.data.isLocked, 'pi-times-circle text-red-500': slotProps.data.isLocked }"></i>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4">
                            <div v-if="slotProps.data.issueDate" style="display: flex; align-items: flex-end;" class="pb-3 pt-0 mt-0">
                                <Button label="Nueva observación" icon="pi pi-plus" class="p-button-danger p-button-outlined ml-2" 
                                @click="openObservation(1, slotProps.data)" v-if="ssPermission.createEnabled" />
                            </div>
                            <DataTable 
                                :value="slotProps.data.observations" 
                                v-if="slotProps.data.observations.length > 0"
                                :rowHover="true"
                                >
                                <Column header="">
                                    <template #body="slotPropsObs">
                                        <Button icon="pi pi-pencil" v-tooltip.top="`Editar tramo`" class="p-button-secondary-outlined ml-2"
                                             @click="openObservation(slotPropsObs.data, slotProps.data)"  v-if="ssPermission.editEnabled" />
                                        <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar tramo`" text rounded class="p-button-danger ml-2" 
                                            @click="UpdateStatusObservation(slotPropsObs.data)"  v-if="ssPermission.deleteEnabled" />
                                    </template>
                                </Column>
                                <Column field="name" header="Observaciones médicas" ></Column>
                                <Column field="deadline" header="Fecha límite">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.deadline, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="contactName" header="Persona contacto"></Column>
                                <Column field="contactPhone" header="Número contacto"></Column>
                                <Column field="ContactNumber" header="Estado">
                                    <template #body="slotProps">
                                        <Tag severity="secondary" :value="slotProps.data.observationStatus.name"></Tag>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>

            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado de vacunas</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtVaccines" 
                    v-model:expandedRows="expandedRows" 
                    :value="oListVaccinesStatus"
                    dataKey="vaccineId"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <Column expander style="width: 5rem" />
                    <Column field="Vaccines" header="Vacuna">
                        <template #body="slotProps">
                            {{ slotProps.data.vaccine.name }}
                        </template>
                    </Column>
                    <Column field="StatusObservation" header="">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': !slotProps.data.isLocked, 'pi-times-circle text-red-500': slotProps.data.isLocked }"></i>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.doses.length > 0">
                            <!--<div style="display: flex; align-items: flex-end;" class="pb-3 pt-0 mt-0">
                                <Button label="Nueva dosis" icon="pi pi-plus" class="p-button-danger p-button-outlined ml-2" @click="openObservation(1, slotProps.data)" />
                            </div>-->
                            <DataTable 
                                :value="slotProps.data.doses"
                                :rowHover="true" >
                                <Column header="">
                                    <template #body="slotPropsDet">
                                        <Button icon="pi pi-pencil" v-tooltip.top="`Editar vacuna`" class="p-button-secondary-outlined ml-2" 
                                        @click="openVaccine(slotPropsDet.data, slotProps.data)" v-if="ssPermission.editEnabled" />
                                        <!--<Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar tramo`" text rounded class="p-button-danger ml-2" />-->
                                    </template>
                                </Column>
                                <Column field="dose" header="Dósis"></Column>
                                <Column field="doseDate" header="Fecha">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.doseDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>                                
                                <Column field="scheduledDate" header="Fecha Programada">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.scheduledDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="note" header="Observación"></Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
            <DocumentsAccreditation :items="oListDocumentsEmo" :ppDocumentId="'EMO'" @updateBase = "refreshBase"
                    :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppPersonId="Number(pPersonId)" />
        </div>

    </div>

    <Dialog v-model:visible="modalManagmentEmo" modal :header="'Editar EMO'" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtPlace" autocomplete="off" 
                                    v-model="oEmoPosition.place" 
                                    :class="{ 'p-invalid' : validatedPlace }" />
                                <label for="lblPlace">Lugar de EMO</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar
                                    showIcon
                                    iconDisplay="input"
                                    inputId="dtpIssueDate"
                                    dateFormat="dd/mm/yy"
                                    v-model="oEmoPosition.issueDate"
                                    :class="{ 'p-invalid' : validatedIssueDate }"
                                    />
                                <label for="lblIssueDate">Fecha emisión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oEmoPosition.medicalExaminationStatusId"
                                :options="oListMedicalExaminationStatus"
                                optionLabel="name"
                                optionValue="medicalExaminationStatusId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedStatusGeneral }"
                                />
                                <label for="lblMedicalExaminationStatusId">Estado general</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar
                                    showIcon
                                    iconDisplay="input"
                                    inputId="dtpIssueDate"
                                    dateFormat="dd/mm/yy"
                                    v-model="oEmoPosition.schedulingDate"
                                    :class="{ 'p-invalid' : validatedSchedulingDate }"
                                    />
                                <label for="lblIssueDate">Fecha programación</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oEmoPosition.medicalExaminationStatusAptitudeId"
                                :options="oListMedicalExaminationStatusAptitude"
                                optionLabel="name"
                                optionValue="medicalExaminationStatusAptitudeId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedStatusAptitud }"
                                />
                                <label for="lblMedicalExaminationStatusAptitudeId">Estado aptitud</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentEmo = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveEmoPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalObservationEmo" modal :header="modalObservationEmoText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtNameObs" autocomplete="off" v-model="oObservationEmo.name" 
                                :class="{ 'p-invalid' : validatedObsName }" />
                                <label for="lblNameObs">Nombre observación</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpFechaFin" dateFormat="dd/mm/yy"
                                    v-model="oObservationEmo.deadline"
                                    :class="{ 'p-invalid' : validatedObsDeadline }" />
                                <label for="lblFechaFin">Fecha corrección</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtCode" autocomplete="off" v-model="oObservationEmo.contactName" 
                                :class="{ 'p-invalid' : validatedContactName }" />
                                <label for="lblCode">Persona contacto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtCode" autocomplete="off" v-model="oObservationEmo.contactPhone" 
                                :class="{ 'p-invalid' : validatedContactPhone }" />
                                <label for="lblCode">Número contacto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                    v-model="oObservationEmo.observationStatusId"
                                    :options="oListObservationStatus"
                                    optionLabel="name"
                                    optionValue="observationStatusId"
                                    placeholder=""
                                    class="w-full"
                                    :class="{ 'p-invalid' : validatedObservationStatusId }"
                                />
                                <label for="lblValidityTime">Estado</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalObservationEmo = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveObservationPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalVaccine" modal :header="modalVaccineText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtDose" autocomplete="off" v-model="oVaccine.dose" 
                                :class="{ 'p-invalid' : validatedDose }" />
                                <label for="lblDose">Nombre dosis</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpDoseDate" dateFormat="dd/mm/yy"
                                    v-model="oVaccine.doseDate"
                                    :class="{ 'p-invalid' : validatedDoseDate }" />
                                <label for="lblDoseDate">Fecha</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpScheduledDate" dateFormat="dd/mm/yy"
                                    v-model="oVaccine.scheduledDate" />
                                <label for="lblScheduledDate">Fecha Programada</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtNote" rows="2" cols="30" v-model="oVaccine.note" />
                                <label for="lblNote">Notas</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalVaccine = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveVaccineDose()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import CommonService from '@/service/CommonService';
import DocumentService from '@/service/DocumentService';
import MedicalExaminationService from '@/service/MedicalExamination';
import PersonService from '@/service/PersonService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineProps, watch, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const confirm = useConfirm();
const toast = useToast();

const personService = new PersonService();
const commonService = new CommonService();
const medicalExaminationService = new MedicalExaminationService();
const documentService = new DocumentService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});

const filtros = ref({});
const bCargando = ref(false);
const oListDocumentsEmo = ref([]);
const oListEmos = ref([]);
const oListVaccinesStatus = ref([]);
const expandedRowsGeneral = ref({});
const expandedRows = ref({});

const modalManagmentEmo = ref(false);
const oEmoPosition = ref({});
const validatedPlace = ref(false);
const validatedIssueDate = ref(false);
const validatedStatusGeneral = ref(false);
const validatedStatusAptitud = ref(false);
const validatedSchedulingDate = ref(false);
const oListMedicalExaminationStatus = ref([]);
const oListMedicalExaminationStatusAptitude = ref([]);

const modalObservationEmo = ref(false);
const modalObservationEmoText = ref('');
const oObservationEmo = ref({});
const validatedObsName = ref(false);
const validatedObsDeadline = ref(false);
const validatedContactName = ref(false);
const validatedContactPhone = ref(false);
const validatedObservationStatusId = ref(false);
const oListObservationStatus = ref([]);

const modalVaccine = ref(false);
const modalVaccineText = ref('');
const oVaccine = ref({});
const validatedDose = ref(false);
const validatedDoseDate = ref(false);

const pKey = ref(0);
const pPersonId = ref(0);
const pPositionId = ref(0);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppPersonId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['updateBase']);

//#region Eventos
onMounted(() => {
    Initialize();
    CambiarEspanol();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadEmos();
        LoadVaccine();
        LoadDocumentEmo();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadEmos();
    LoadMedicalExaminationStatus();
    LoadMedicalExaminationStatusAptitude();
    LoadObservationStatus();
    LoadVaccine();
    LoadDocumentEmo();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00009');
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const LoadMedicalExaminationStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetMedicalExaminationStatusService() .then();
    if(response.code == 200){
        oListMedicalExaminationStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadMedicalExaminationStatusAptitude = async() => {
    bCargando.value = true;
    const response = await commonService.GetMedicalExaminationStatusAptitudeService() .then();
    if(response.code == 200){
        oListMedicalExaminationStatusAptitude.value = response.data;
        bCargando.value = false;
    }
}

const LoadObservationStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetObservationStatusService() .then();
    if(response.code == 200){
        oListObservationStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadEmos = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0){
        const response = await personService.GetEmoByPositionService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListEmos.value = response.data;

            let idCounter = 1;    
            oListEmos.value.forEach((item) => {
                item.id = 'N'+idCounter++;
            });
            
            bCargando.value = false;
        }
    } 
}

// #region EMO
const EditEmo = async(item) => {
    
    oEmoPosition.value.jobPositionId = item.jobPosition.jobPositionId;
    oEmoPosition.value.medicalExaminationId = item.medicalExamination.medicalExaminationId;
    oEmoPosition.value.place = item.medicalExaminationPlace;
    oEmoPosition.value.issueDate = Utilitario.formatoDate(item.issueDate);
    oEmoPosition.value.medicalExaminationStatusId = item.medicalExaminationStatus ? item.medicalExaminationStatus.medicalExaminationStatusId : 0;
    oEmoPosition.value.schedulingDate = item.schedulingDate ? Utilitario.formatoDate(item.schedulingDate) : null;
    oEmoPosition.value.medicalExaminationStatusAptitudeId = item.medicalExaminationStatusAptitude ? item.medicalExaminationStatusAptitude.medicalExaminationStatusAptitudeId: 0;

    modalManagmentEmo.value = true;
}

const SaveEmoPosition = async() => {
    if(await ValidateEmoPosition()){
       await CreateUpdateEmoPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateEmoPosition = async() => {
    const { ...model } = oEmoPosition.value;
    const valid = ref(false);

    if(!model?.place){
        validatedPlace.value = true;
        valid.value = true;
    }
    else{
        validatedPlace.value = false;
    }

    if(!model?.issueDate){
        validatedIssueDate.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDate.value = false;
    }

    if(!model?.medicalExaminationStatusId){
        validatedStatusGeneral.value = true;
        valid.value = true;
    }
    else{
        validatedStatusGeneral.value = false;
        
        if(model.medicalExaminationStatusId == 5 && !model?.schedulingDate){
            validatedSchedulingDate.value = true;
            valid.value = true;
        }
        else{
            validatedSchedulingDate.value = false;
        }
    }

    if(!model?.medicalExaminationStatusAptitudeId){
        validatedStatusAptitud.value = true;
        valid.value = true;
    }
    else{
        validatedStatusAptitud.value = false;
    }

    return !valid.value;
}

const CreateUpdateEmoPosition = async() => {
    const { ...model } = oEmoPosition.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el exámen médico?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdateEmoPoistionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el exámen médico correctamente`,
                    accept: async() => {
                        emit('updateBase', response);
                        LoadEmos();
                        LoadDocumentEmo();
                        modalManagmentEmo.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Exámen Médico:`, error);
        }
      }});
}
//#endregion

//#region Observation 
const openObservation = (action, emo) => {
    modalObservationEmoText.value = action == 1 ? 'Nueva obervación' : 'Editar observación';
    
    if(action != 1){
        oObservationEmo.value = action;
        
        oObservationEmo.value.medicalExaminationObservationId = action.medicalExaminationObservationId;
        oObservationEmo.value.name = action.name;
        oObservationEmo.value.deadline = Utilitario.formatoDate(action.deadline);
        oObservationEmo.value.contactName = action.contactName;
        oObservationEmo.value.contactPhone = action.contactPhone;
        oObservationEmo.value.observationStatusId = action.observationStatus ? action.observationStatus.observationStatusId : 0;
        oObservationEmo.value.personMedicalExaminationPerJobPositionId = emo.personMedicalExaminationPerJobPositionId;
    }
    else{
        oObservationEmo.value = {
            medicalExaminationObservationId: 0,
            name: '',
            deadline: null,
            contactName: '',
            contactPhone: '',
            observationStatusId: 0,
            personMedicalExaminationPerJobPositionId: emo.personMedicalExaminationPerJobPositionId
        };
    }
    
    modalObservationEmo.value = true;
}

const SaveObservationPosition = async() => {
    if(await ValidateObservationPosition()){
       await CreateUpdateObservationPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateObservationPosition = async() => {
    const { ...model } = oObservationEmo.value;
    const valid = ref(false);

    if(!model?.name){
        validatedObsName.value = true;
        valid.value = true;
    }
    else{
        validatedObsName.value = false;
    }

    if(!model?.deadline){
        validatedObsDeadline.value = true;
        valid.value = true;
    }
    else{
        validatedObsDeadline.value = false;
    }

    if(!model?.contactName){
        validatedContactName.value = true;
        valid.value = true;
    }
    else{
        validatedContactName.value = false;
    }

    if(!model?.contactPhone){
        validatedContactPhone.value = true;
        valid.value = true;
    }
    else{
        validatedContactPhone.value = false;
    }

    if(!model?.observationStatusId){
        validatedObservationStatusId.value = true;
        valid.value = true;
    }
    else{
        validatedObservationStatusId.value = false;
    }

    return !valid.value;
}

const CreateUpdateObservationPosition = async() => {
    const { ...model } = oObservationEmo.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la observación?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await medicalExaminationService.CreateUpdateMedicalExaminationObservationService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la observación correctamente`,
                    accept: async() => {
                        LoadEmos();
                        modalObservationEmo.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Exámen Médico:`, error);
        }
      }});
}

const UpdateStatusObservation = async(item) => {
    const request = {
        medicalExaminationObservationId: item.medicalExaminationObservationId,
        status: false,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de eliminar la observaición ${ item.name } ?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await medicalExaminationService.UpdateStatusMedicalExaminationObservationService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se eliminó la observación ${item.name} correctamente`,
                accept: async() => {
                    LoadEmos();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 

//#endregion

//#region Vacunas

const openVaccine = (action, primary) => {
    modalVaccineText.value = action == 1 ? 'Nueva dosis' : 'Editar dosis';
    if(action != 1){
        oVaccine.value = action;
        
        oVaccine.value.vaccineId = primary.vaccine.vaccineId,
        oVaccine.value.doseDate = action.doseDate ? Utilitario.formatoDate(action.doseDate) : null;
        oVaccine.value.scheduledDate = action.scheduledDate ? Utilitario.formatoDate(action.scheduledDate) : null;
    }
    /*else{
        oObservationEmo.value = {
            medicalExaminationObservationId: 0,
            name: '',
            deadline: null,
            contactName: '',
            contactPhone: '',
            observationStatusId: 0,
            personMedicalExaminationPerJobPositionId: emo.personMedicalExaminationPerJobPositionId
        };
    }*/
    
    modalVaccine.value = true;
}

const LoadVaccine = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0){
        const response = await personService.GetVaccineByPositionService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListVaccinesStatus.value = response.data.map(item => ({
                ...item,
                vaccineId : item.vaccine.vaccineId
            }));
            
            bCargando.value = false;
        }
    } 
}


const SaveVaccineDose = async() => {
    if(await ValidateVaccineDose()){
       await CreateUpdateVaccineDose();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateVaccineDose = async() => {
    const { ...model } = oVaccine.value;
    const valid = ref(false);

    if(!model?.dose){
        validatedDose.value = true;
        valid.value = true;
    }
    else{
        validatedDose.value = false;
    }

    if(!model?.doseDate){
        validatedDoseDate.value = true;
        valid.value = true;
    }
    else{
        validatedDoseDate.value = false;
    }

    return !valid.value;
}

const CreateUpdateVaccineDose = async() => {
    const { ...model } = oVaccine.value;
    
    const request = {
        ...model,
        jobPositionId: pPositionId.value,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la dósis?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdateVaccinePoistionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la dósis correctamente`,
                    accept: async() => {
                        LoadVaccine();
                        modalVaccine.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Vacuna:`, error);
        }
      }});
}

//#endregion

//#region Documents
const LoadDocumentEmo = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await documentService.GetDocumentEmoService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListDocumentsEmo.value = response.data.map((item, index) => ({
                ...item,
                documentIndex: index + 1,
                documentId: item.medicalExaminationId,
                documentName: item.medicalExaminationName
            }));
            pKey.value++;
            bCargando.value = false;
        } 
    }    
}

const refreshBase = () => {
    LoadDocumentEmo();
}
//#endregion

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
