<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Cursos</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtCoursesPosition" 
                    v-model:expandedRows="expandedRows" 
                    :value="oListCoursesPosition"
                    dataKey="course.courseId"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column expander style="width: 1rem" />
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar curso`" class="p-button-secondary-outlined ml-2"
                             @click="EditCourse(slotProps.data)" :disabled="slotProps.data.isLocked" 
                             v-if="ssPermission.editEnabled" />
                        </template>
                    </Column>
                    <Column field="Course" header="Curso">
                        <template #body="slotProps">
                            {{ slotProps.data.course.name }}
                        </template>
                    </Column>
                    <Column field="mode" header="Modalidad"></Column>                    
                    <Column field="IssueDate" header="Fecha emisión">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="VigencyDate" header="Fecha vigencia">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.effectiveDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="note" header="Notas"></Column>  
                    <Column field="Status" header="Estado curso">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.validity" :severity="slotProps.data.validity == 'Vigente' ? 'success' : 'danger'" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.listPreRequisites.length > 0">
                            <DataTable 
                                :value="slotProps.data.listPreRequisites" 
                                :rowHover="true" 
                                >
                                <template #header>
                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Pre requisitos</h5>
                                    </div>
                                </template>

                                <Column style="text-align: center;" headerStyle="min-width:3rem;">
                                    <template #body="slotPropsPre">
                                        <Button icon="pi pi-pencil" v-tooltip.top="`Editar curso`" class="p-button-secondary-outlined ml-2" @click="EditCoursePre(slotPropsPre.data, slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="Course" header="Curso">
                                    <template #body="slotPropsPre">
                                        {{ slotPropsPre.data.courseName }}
                                    </template>
                                </Column>
                                <Column field="modeName" header="Modalidad"></Column>                    
                                <Column field="IssueDate" header="Fecha emisión">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.issueDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="VigencyDate" header="Fecha vigencia">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.effectiveDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="note" header="Notas"></Column>  
                                <Column field="Status" header="Estado curso">
                                    <template #body="slotPropsPre">
                                        <Tag :value="slotPropsPre.data.validity" :severity="slotPropsPre.data.validity == 'Vigente' ? 'success' : 'danger'" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>

            <DocumentsAccreditation :items="oListDocumentsCourse" :ppDocumentId="'COURSE'" @updateBase = "refreshBase"
                    :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppPersonId="Number(pPersonId)" />

            <Divider></Divider>

            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Certificaciones</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtCertificatesPosition" 
                    v-model:expandedRows="expandedRowsCertificate" 
                    :value="oListCertification"
                    dataKey="certificate.certificateId"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column expander style="width: 1rem" />
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar certificado`" class="p-button-secondary-outlined ml-2"
                             @click="EditCertificate(slotProps.data)" :disabled="slotProps.data.isLocked" 
                             v-if="ssPermission.editEnabled" />
                        </template>
                    </Column>
                    <Column field="Course" header="Certificado">
                        <template #body="slotProps">
                            {{ slotProps.data.certificate.name }}
                        </template>
                    </Column>
                    <Column field="mode" header="Modalidad"></Column>                    
                    <Column field="IssueDate" header="Fecha emisión">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="VigencyDate" header="Fecha vigencia">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.effectiveDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="note" header="Notas"></Column>  
                    <Column field="Status" header="Estado certificado">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.validity" :severity="slotProps.data.validity == 'Vigente' ? 'success' : 'danger'" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.listPreRequisites.length > 0">
                            <DataTable 
                                :value="slotProps.data.listPreRequisites" 
                                :rowHover="true" 
                                >
                                <template #header>
                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Pre requisitos</h5>
                                    </div>
                                </template>

                                <Column style="text-align: center;" headerStyle="min-width:3rem;">
                                    <template #body="slotPropsPre">
                                        <Button icon="pi pi-pencil" v-tooltip.top="`Editar curso`" class="p-button-secondary-outlined ml-2" @click="EditCertificatePre(slotPropsPre.data, slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="certificateName" header="Certificado">
                                    <template #body="slotPropsPre">
                                        {{ slotPropsPre.data.certificateName }}
                                    </template>
                                </Column>
                                <Column field="modeName" header="Modalidad"></Column>                    
                                <Column field="IssueDate" header="Fecha emisión">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.issueDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="VigencyDate" header="Fecha vigencia">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.effectiveDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="note" header="Notas"></Column>  
                                <Column field="Status" header="Estado certificado">
                                    <template #body="slotPropsPre">
                                        <Tag :value="slotPropsPre.data.validity" :severity="slotPropsPre.data.validity == 'Vigente' ? 'success' : 'danger'" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
            
            <DocumentsAccreditation :items="oListDocumentsCertificate" :ppDocumentId="'CERTIFICATE'" @updateBase = "refreshBaseCertificate"
                    :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppPersonId="Number(pPersonId)" />
        </div>

    </div>

    <Dialog v-model:visible="modalManagmentCourse" modal :header="modalManagmentCourseText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar
                                    showIcon
                                    iconDisplay="input"
                                    inputId="dtpFechaFin"
                                    dateFormat="dd/mm/yy"
                                    v-model="oCoursePosition.issueDate"
                                    :class="{ 'p-invalid' : validatedIssueDate }"
                                    />
                                <label for="lblFechaFin">Fecha emisión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtDescription" rows="2" cols="30" v-model="oCoursePosition.note" />
                                <label for="lblDescription">Notas</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentCourse = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveCoursePosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalManagmentCertificate" modal :header="modalManagmentCertificateText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar
                                    showIcon
                                    iconDisplay="input"
                                    inputId="dtpFechaFin"
                                    dateFormat="dd/mm/yy"
                                    v-model="oCertificatePosition.issueDate"
                                    :class="{ 'p-invalid' : validatedIssueDateCertificate }"
                                    />
                                <label for="lblFechaFin">Fecha emisión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtDescription" rows="2" cols="30" v-model="oCertificatePosition.note" />
                                <label for="lblDescription">Notas</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentCertificate = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveCertificatePosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import PersonService from '@/service/PersonService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineProps, watch, defineEmits } from 'vue';
import DocumentService from '@/service/DocumentService';
import { useStore } from 'vuex';

const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const personService = new PersonService();
const documentService = new DocumentService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});

const filtros = ref({});
const bCargando = ref(false);
const oListDocumentsCourse = ref([]);
const oListDocumentsCertificate = ref([]);
const oListCoursesPosition = ref([]);
const oListCertification = ref([]);

const expandedRows = ref({});
const expandedRowsCertificate = ref({});

const pPersonId = ref(0);
const pPositionId = ref(0);
const modalManagmentCourse = ref(false);
const modalManagmentCourseText = ref('');
const oCoursePosition = ref({});
const validatedIssueDate = ref(false);

const modalManagmentCertificate = ref(false);
const modalManagmentCertificateText = ref('');
const oCertificatePosition = ref({});
const validatedIssueDateCertificate = ref(false);

const pKey = ref(0);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppPersonId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['updateBase']);

//#region Eventos
onMounted(() => {
    Initialize();
    CambiarEspanol();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadCoursesPosition();
        LoadCertificatesPosition();
        LoadDocumentCourse();
        LoadDocumentCertificate();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadCoursesPosition();
    LoadCertificatesPosition();
    LoadDocumentCourse();
    LoadDocumentCertificate();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00008');
}

//#region Courses 
const LoadCoursesPosition = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0){
        const response = await personService.GetCoursesByPositionService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListCoursesPosition.value = response.data;
            bCargando.value = false;
        }
    }    
}

const EditCourse = async(item) => {
    modalManagmentCourseText.value = 'Editar Curso';
    
    oCoursePosition.value.jobPositionId = item.jobPosition.jobPositionId;
    oCoursePosition.value.courseId = item.course.courseId;
    oCoursePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCoursePosition.value.note = item.note;

    modalManagmentCourse.value = true;
}

const EditCoursePre = async(item, itemPrimary) => {
    modalManagmentCourseText.value = 'Editar Curso';
    
    oCoursePosition.value.jobPositionId = itemPrimary.jobPosition.jobPositionId;
    oCoursePosition.value.courseId = item.courseId;
    oCoursePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCoursePosition.value.note = item.note;

    modalManagmentCourse.value = true;
}

const SaveCoursePosition = async() => {
    if(await ValidateCoursePosition()){
       await CreateUpdateCoursePosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateCoursePosition = async() => {
    const { ...model } = oCoursePosition.value;
    const valid = ref(false);

    if(!model?.issueDate){
        validatedIssueDate.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDate.value = false;
    }

    return !valid.value;
}

const CreateUpdateCoursePosition = async() => {
    const { ...model } = oCoursePosition.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el curso?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdateCoursePoistionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el curso correctamente`,
                    accept: async() => {
                        emit('updateBase', response);
                        LoadCoursesPosition();
                        LoadDocumentCourse();
                        modalManagmentCourse.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Escala salarial:`, error);
        }
      }});
}

//#endregion

//#region Certificates
const LoadCertificatesPosition = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0){
        const response = await personService.GetCertificatesByPositionService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListCertification.value = response.data;
            bCargando.value = false;
        }
    }    
}

const EditCertificate = async(item) => {
    modalManagmentCertificateText.value = 'Editar certificado';
    
    oCertificatePosition.value.jobPositionId = item.jobPosition.jobPositionId;
    oCertificatePosition.value.certificateId = item.certificate.certificateId;
    oCertificatePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCertificatePosition.value.note = item.note;

    modalManagmentCertificate.value = true;
}

const EditCertificatePre = async(item, itemPrimary) => {
    modalManagmentCertificateText.value = 'Editar certificado';
    
    oCertificatePosition.value.jobPositionId = itemPrimary.jobPosition.jobPositionId;
    oCertificatePosition.value.certificateId = item.certificateId;
    oCertificatePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCertificatePosition.value.note = item.note;

    modalManagmentCertificate.value = true;
}

const SaveCertificatePosition = async() => {
    if(await ValidateCertificatePosition()){
       await CreateUpdateCertificatePosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateCertificatePosition = async() => {
    const { ...model } = oCertificatePosition.value;
    const valid = ref(false);

    if(!model?.issueDate){
        validatedIssueDateCertificate.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDateCertificate.value = false;
    }

    return !valid.value;
}

const CreateUpdateCertificatePosition = async() => {
    const { ...model } = oCertificatePosition.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el certificado?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdateCertificatePoistionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el certificado correctamente`,
                    accept: async() => {
                        emit('updateBase', response);
                        LoadCertificatesPosition();
                        LoadDocumentCertificate();
                        modalManagmentCertificate.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Escala salarial:`, error);
        }
      }});
}
//#endregion

//#region Documents
const LoadDocumentCourse = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await documentService.GetDocumentCourseService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListDocumentsCourse.value = response.data.map((item, index) => ({
                ...item,
                documentIndex: index + 1,
                documentId: item.courseId,
                documentName: item.courseName
            }));
            pKey.value++;
            bCargando.value = false;
        } 
    }    
}

const refreshBase = () => {
    LoadDocumentCourse();
}

const LoadDocumentCertificate = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await documentService.GetDocumentCertificateService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListDocumentsCertificate.value = response.data.map((item, index) => ({
                ...item,
                documentIndex: index + 1,
                documentId: item.certificateId,
                documentName: item.certificateName
            }));
            pKey.value++;
            bCargando.value = false;
        } 
    }    
}

const refreshBaseCertificate = () => {
    LoadDocumentCertificate();
}
//#endregion

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
