<template>
  <div class="surface-ground md:flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <Toast />
    <div v-if="isLoadinga">
      <div class="progress-spinner-custom">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>

    <div class="md:flex flex-column align-items-center justify-content-center" style="position: relative;" >
      <div 
      >
        <div class="w-full surface-card py-8 px-5 sm:px-8">
          <div class="text-left">
            <img src="layout/images/logo.png" alt="Logo Ipsycom" class="mb-5 w-6rem flex-shrink-0" style="width: 180px !important;" /> 
          </div>

          <div>
            <label class="block text-900 text-xl font-medium mb-2">Usuario</label>
            <InputText
              id="email"
              type="text"
              placeholder="Usuario"
              class="w-full md:w-30rem mb-5"
              style="padding: 0.75rem"
              v-model="email"
              @keyup.enter="login"
            />
            <label class="block text-900 font-medium text-xl mb-2"
              >Contraseña</label
            >
            <Password
              id="password"
              v-model="password"
              placeholder="Contraseña"
              class="w-full mb-3"
              inputClass="w-full"
              :inputStyle="{ padding: '0.75rem' }"
              :feedback="false" 
              toggleMask
              style="display: block;"
              hideIcon="pi pi-eye-slash"
              @keyup.enter="login"
            >
          </Password>
            <div
              class="flex align-items-center justify-content-between mb-5 gap-5"
            >
            
            </div>
            <Button
              label="Ingresar"
              class="w-full p-3 text-xl p-button-primary"
              @click="login"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
import UserService from "@/service/UserService";
const router = useRouter();
const toast = useToast();
const store = useStore()

const email = ref("");
const password = ref(null);
const isLoadinga = ref(false);
const response = ref(null);

const userService = new UserService();

const login = async () => {
  try {
    isLoadinga.value = true;
    
    const request = {
      User: email.value,
      Password: password.value
    };
    
    response.value = await userService.loginService(request);
    if (!response.value.data.user.idUser > 0) {
      isLoadinga.value = false;
      if (response.value.code !== 200 && response.value.code !== undefined) {
        console.log(response.value.Message);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: 'No pudo iniciar sesión.\n Usuario o clave incorrectos',
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error en inicio de sesión",
          life: 3000,
        });
      }

      return;
    }
    
    store.commit('login',JSON.stringify(response.value.data))
    isLoadinga.value = false;
    router.push({ path: "/seleccion" });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 3000,
    });
  } finally {
    isLoadinga.value = false;
  }
};
</script>
<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

</style>