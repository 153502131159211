import { createRouter, createWebHashHistory } from 'vue-router'

import PasswordChangeView from '../views/PasswordChangeView'
import DashboardView from '../views/DashboardView.vue'
import PageNotFoundView from '../views/PageNotFoundView'
import InitView from '../views/InitView'
import AppLayout from '@/layout/AppLayout.vue';
import LoginView from '../views/LoginView';
import LandingView from '../views/LandingView';
import ModulesView from '../views/ModulesView';

import { useStore } from "vuex";
import CompanyMaintance from '@/components/Company/CompanyMaintance.vue'
import CompanyNew from '@/components/Company/CompanyNew.vue'
import ProjectMaintance from '@/components/Project/ProjectMaintance.vue'
import ProjectNew from '@/components/Project/ProjectNew.vue'
import CourseMaintance from '@/components/Courses/CourseMaintance.vue'
import CourseNew from '@/components/Courses/CourseNew.vue'
import CertificateMaintance from '@/components/Certificate/CertificateMaintance.vue'
import CertificateNew from '@/components/Certificate/CertificateNew.vue'
import EmosMaintance from '@/components/Emos/EmosMaintance.vue'
import EmosNew from '@/components/Emos/EmosNew.vue'
import SelectionView from '@/views/SelectionView.vue'
import UserMaintance from '@/components/User/UserMaintance.vue'
import UserNew from '@/components/User/UserNew.vue'
import Accreditation from '@/components/Accreditation/Accreditation.vue'
import ReportServiceEmployee from '@/components/Reports/Services/ReportServiceEmployee.vue'
import ReportServiceCourse from '@/components/Reports/Services/ReportServiceCourse.vue'
import ReportServiceAccredited from '@/components/Reports/Services/ReportServiceAccredited.vue'
import ReportServiceEmo from '@/components/Reports/Services/ReportServiceEmo.vue'
import ReportServiceCrossed from '@/components/Reports/Services/ReportServiceCrossed.vue'
import ReportServiceGeneralCourse from '@/components/Reports/Services/ReportServiceGeneralCourse.vue'
import AccreditationPersonal from '@/components/Accreditation/AccreditationPersonal.vue'
import ReportProject from '@/components/Reports/Services/ReportProject.vue'
import ReportPlaceOrigin from '@/components/Reports/Services/ReportPlaceOrigin.vue'
import VaccineMaintance from '@/components/Vaccine/VaccineMaintance.vue'
import VaccineNew from '@/components/Vaccine/VaccineNew.vue'
import ServiceMaintance from '@/components/Service/ServiceMaintance.vue'
import JobPositionMaintance from '@/components/JobPosition/JobPositionMaintance.vue'
import ServiceNew from '@/components/Service/ServiceNew.vue'
import JobPositionNew from '@/components/JobPosition/JobPositionNew.vue'
import ReportAlertPendient from '@/components/Reports/Alert/ReportAlertPendient.vue'
import RoleMaintance from '@/components/Role/RoleMaintance.vue'
import RoleNew from '@/components/Role/RoleNew.vue'

const routes = [{
        path: '/web',
        component: LandingView
    },
    {
        path: '/',
        component: LoginView,
        name: 'Login'
    },
    {
      path: '/seleccion',
      component: SelectionView,
      name: 'Selection'
    },
    {
        path: '/portal',
        component: AppLayout,
        children: [
          {
                path: '/portal',
                component: InitView,
                name: "Init",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system-dashboard',
                component: DashboardView,
                name: "Dashboard",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/modules',
                component: ModulesView,
                meta: {
                    requiresAuthentication: true,
                }
            },            
            {
                path: '/system/change-password',
                component: PasswordChangeView,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
              path: '/portal/empresa/mantenedor',
              component: CompanyMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Empresa/Nuevo',
              component: CompanyNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Empresa/Editar/:id',
              component: CompanyNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/proyecto/mantenedor',
              component: ProjectMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/proyecto/nuevo',
              component: ProjectNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/proyecto/editar/:id/:step',
              component: ProjectNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/cursos/mantenedor',
              component: CourseMaintance,
              meta: {
                requiresAuthentication: true
              }
            },            
            {
              path: '/portal/Curso/Nuevo',
              component: CourseNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Curso/Editar/:id',
              component: CourseNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/certificaciones/mantenedor',
              component: CertificateMaintance,
              meta: {
                requiresAuthentication: true
              }
            },            
            {
              path: '/portal/Certificado/Nuevo',
              component: CertificateNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Certificado/Editar/:id',
              component: CertificateNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/emos/mantenedor',
              component: EmosMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Emos/Nuevo/',
              component: EmosNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Emos/Editar/:id',
              component: EmosNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/acreditacion/:id',
              component: Accreditation,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Employee',
              component: ReportServiceEmployee,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Course',
              component: ReportServiceCourse,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Cruzado',
              component: ReportServiceCrossed,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Accredited',
              component: ReportServiceAccredited,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/reporte/emo',
              component: ReportServiceEmo,
              meta: {
                requiresAuthentication: true
              }
            }, 
            {
              path: '/portal/Reports/GeneralCourse',
              component: ReportServiceGeneralCourse,
              meta: {
                requiresAuthentication: true
              }
            },           
            {
              path: '/portal/Accreditation/Personal/:projectId/:positionId/:id?',
              component: AccreditationPersonal,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reports/Project',
              component: ReportProject,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reporte/LugarOrigen',
              component: ReportPlaceOrigin,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/Reporte/AlertaPendientes',
              component: ReportAlertPendient,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/vacuna/mantenedor',
              component: VaccineMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/vacuna/nuevo',
              component: VaccineNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/vacuna/editar/:id',
              component: VaccineNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/servicio/mantenedor',
              component: ServiceMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/servicio/nuevo',
              component: ServiceNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/servicio/editar/:id',
              component: ServiceNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/puesto/mantenedor',
              component: JobPositionMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/puesto/nuevo',
              component: JobPositionNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/puesto/editar/:id',
              component: JobPositionNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/usuario/mantenedor',
              component: UserMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/usuario/nuevo',
              component: UserNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/usuario/editar/:id',
              component: UserNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/rol/mantenedor',
              component: RoleMaintance,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/rol/nuevo',
              component: RoleNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/seguridad/rol/editar/:id',
              component: RoleNew,
              meta: {
                requiresAuthentication: true
              }
            },
            {
                path: "*",
                component: PageNotFoundView,
            }
        ]
    }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL), 
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let store = useStore();
  let dUser = JSON.parse(store.getters["isAuthenticated"]);
  const isAuthenticated = dUser;
  if (from.name !== "Login") {
    if (to.matched.some((record) => record.meta.requiresAuthentication)) {
      if (isAuthenticated) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      if (isAuthenticated && to.meta.disallowAuthed) {
        next({ name: "Init" });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
