export default class VaccineService {
    async GetVaccineService(vaccineId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Vaccine?VaccineId=${vaccineId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }

    async CreateUpdateVaccineService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Vaccine/CreateUpdateVaccine', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async UpdateStatusVaccineService(request) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Vaccine/Status', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${data.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }
}