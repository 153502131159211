<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>Nueva Empresa</h1>
                    </template>

                    <template #end>                        
                    </template>
                </Toolbar>
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Dropdown :options="oListCountry" 
                                    optionLabel="name" optionValue="countryId" 
                                    placeholder="País" class="w-full"  
                                    v-model="oEnterprise.countryId" :class="{ 'p-invalid' : validatedCountryId }"/>
                                <label for="lblCountry">País</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtName" autocomplete="off" v-model="oEnterprise.name" :class="{ 'p-invalid' : validatedName }" />
                                <label for="lblName">Nombre Empresa</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" v-model="oEnterprise.ruc" :class="{ 'p-invalid' : validatedRuc }" />
                                <label for="lblCodigoProducto">R.U.C.</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtCodigoProducto" autocomplete="off" v-model="oEnterprise.companyName" :class="{ 'p-invalid' : validatedCompanyName }" />
                                <label for="lblCodigoProducto">Razón Social</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>             
                        <div class="field col-12 md:col-8 pb-0">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Dirección</label>
                            </FloatLabel>
                        </div>
                        <Divider layout="horizontal" class="p-0 field col-12 md:col-8" />
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtSponsor1" autocomplete="off" v-model="oEnterprise.sponsor1"  />
                                <label for="lblSponsor1">Sponsor 1</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtPosition1" autocomplete="off" v-model="oEnterprise.position1"/>
                                <label for="lblPosition1">Cargo 1</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtSponsor2" autocomplete="off" v-model="oEnterprise.sponsor2"  />
                                <label for="lblSponsor2">Sponsor 2</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtPosition2" autocomplete="off" v-model="oEnterprise.position2" />
                                <label for="lblPosition2">Cargo 2</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtPosition2" autocomplete="off" v-model="oEnterprise.learningPlatform" />
                                <label for="lblPosition2">Plataforma de aprendizaje</label>
                            </FloatLabel>
                            <!-- <FloatLabel>
                                <Dropdown :options="oListLearningPlatform" 
                                    optionLabel="name" optionValue="learningPlatformId" 
                                    placeholder="País" class="w-full"  v-model="oEnterprise.learningPlatformId" :class="{ 'p-invalid' : validatedPlaformId }" />
                                <label for="lblUnidadMedida">Plataforma de aprendizaje</label>
                            </FloatLabel> -->
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" 
                                    inputId="dtpFechaFin" dateFormat="dd/mm/yy" 
                                    :class="{ 'p-invalid' : validatedStartDate }" 
                                    v-model="oEnterprise.starDateOperations" />
                                <label for="lblFechaFin">Inicio de operaciones</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>

                        <Divider layout="horizontal" class="p-0 field col-12 md:col-8 mb-5" />
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtLegalRepresentativeName" autocomplete="off" v-model="oEnterprise.legalRepresentativeName" :class="{ 'p-invalid' : validatedLegal }" />
                                <label for="lblLegalRepresentativeName">Representante legal</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText id="txtLegalRepresentativeDni" autocomplete="off" v-model="oEnterprise.legalRepresentativeDni" :class="{ 'p-invalid' : validatedLegalDni }" />
                                <label for="lblLegalRepresentativeDni">DNI</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-4"></div>

                        <!--<div class="field col-12 md:col-4">
                            <FileUpload name="demo[]" url="/api/upload" @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">
                                <template #empty>
                                    <p>Arrastré aquí el logo de su empresa.</p>
                                </template>
                            </FileUpload>
                        </div>-->
                        <div class="field col-12 md:col-4"></div>
                        <div class="field col-12 md:col-4"></div>
                    </div>
                </div>

                <Divider layout="horizontal" />
                <div class="flex justify-content-start">
                    <Button label="Guardar" icon="pi pi-save" severity="primary" iconPos="right" @click="SaveEnterprise()" :loading="bCargando" />
                    <Button label="Atrás" icon="pi pi-arrow-left" class="ml-2" severity="secondary" @click="BackTo"></Button>
                </div>

            </div>
        </div>
    </div>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import CountryService from '@/service/CountryService';
import EnterpriseService from '@/service/EnterpriseService';
import LearningPlatformService from '@/service/LearningPlatformService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const userStore = JSON.parse(store.state.datauser);

const countryService = new CountryService();
const learningPlatformService = new LearningPlatformService();
const enterpriseService = new EnterpriseService();

const bCargando = ref(false);
const enterpriseEditId = ref(0);
const oListCountry = ref([]);
const oListLearningPlatform = ref([]);
const oEnterprise = ref({
    enterpriseId: 0,
    address: '',
    sponsor1: '',
    position1: '',
    sponsor2: '',
    position2: '',
    urlImage: ''
});

const validatedCountryId = ref(false);
const validatedName = ref(false);
const validatedRuc = ref(false);
const validatedCompanyName = ref(false);
const validatedPlaformId = ref(false);
const validatedStartDate = ref(false);
const validatedLegal = ref(false);
const validatedLegalDni = ref(false);


//#region Eventos
onMounted(() => {
    Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
    CambiarEspanol();
    LoadCountry();
    LoadLearningPlatform();
    enterpriseEditId.value = Crypto.Desencriptar(route.params.id);
    if(!Utilitario.StringIsNullOrEmpty(enterpriseEditId.value)){
      LoadEnterprise(enterpriseEditId.value);
    }
} 

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.choose = "Buscar";
    primevue.config.locale.upload = "Subir";
    primevue.config.locale.cancel = "Cancelar";
}

const BackTo = () => {
    router.push({ path: `/portal/empresa/mantenedor` });
}

const LoadCountry = async() => {
    bCargando.value = true;
    const response = await countryService.GetCountryByRole(0).then();
    if(response.code == 200){
      oListCountry.value = response.data;
      bCargando.value = false;
    }
}

const LoadLearningPlatform = async() => {
    bCargando.value = true;
    const response = await learningPlatformService.GetLearningPlatformService().then();
    if(response.code == 200){
      oListLearningPlatform.value = response.data;
      bCargando.value = false;
    }
}

const LoadEnterprise = async(id) => {
    bCargando.value = true;
    const response = await enterpriseService.GetEnterpriseByIdService(id).then();
    if(response.code == 200){
        console.log(response.data)
        oEnterprise.value = response.data;
        oEnterprise.value.countryId = response.data.country.countryId;
        oEnterprise.value.starDateOperations = Utilitario.formatoDate(response.data.starDateOperations);
        bCargando.value = false;
    }
}


const SaveEnterprise = async() =>{
    if(await ValidateCountry()){
        CreateUpdateCountry();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const ValidateCountry = async() => {
  const { ...model } = oEnterprise.value;
  const valid = ref(false);
  
  if(!model?.countryId){
    validatedCountryId.value = true;
    valid.value = true;
  }
  else{
    validatedCountryId.value = false;
  }

  if(!model?.name){
    validatedName.value = true;
    valid.value = true;
  }
  else{
    validatedName.value = false;
  }

  if(!model?.ruc){
    validatedRuc.value = true;
    valid.value = true;
  }
  else{
    validatedRuc.value = false;
  }

  if(!model?.companyName){
    validatedCompanyName.value = true;
    valid.value = true;
  }
  else{
    validatedCompanyName.value = false;
  }

//   if(!model?.learningPlatformId){
//     validatedPlaformId.value = true;
//     valid.value = true;
//   }
//   else{
//     validatedPlaformId.value = false;
//   }
  
  if(!model?.starDateOperations){
    validatedStartDate.value = true;
    valid.value = true;
  }
  else{
    validatedStartDate.value = false;
  }

  if(!model?.legalRepresentativeName){
    validatedLegal.value = true;
    valid.value = true;
  }
  else{
    validatedLegal.value = false;
  }

  if(!model?.legalRepresentativeDni){
    validatedLegalDni.value = true;
    valid.value = true;
  }
  else{
    validatedLegalDni.value = false;
  }

  return !valid.value;
}

const CreateUpdateCountry = async() => {
  const { ...model } = oEnterprise.value;
  
  const request = {
    ...model,
    creationUser: userStore.user.idUser
  };
  
  confirm.require({
      message: `¿Está seguro de guardar la empresa?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await enterpriseService.CreateEnterpriseService(request).then();
          if(response.code == 201){
            console.log(response.data)
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se guardó la empresa #${response.data.name} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    router.push({ path: `/portal/empresa/mantenedor` });
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}
//#endregion

</script>