<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <Button icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                            <div class="ml-3">
                                <h3 style="margin: 0;">{{ oPerson.firstName }} {{ oPerson.lastName }} 
                                    <Tag v-if="statusPersonName != ''" :value="statusPersonName" severity="success" />
                                </h3>
                                <p style="margin: 0;"><b>{{ oProject.serviceName }}</b> > {{ oPosition.name }}</p>
                            </div>
                        </div>
                    </template>
                    <template #end>
                        <div style="display: flex; align-items: flex-end;">
                            <MeterGroup :value="progressAccreditation" />
                        </div>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <TabView class="tabview-custom">
                <TabPanel v-if="ssPermissionSelection.viewEnabled">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-file-edit"></i>
                            <span class="font-bold white-space-nowrap">Selección</span>
                        </div>
                    </template>
                    <AccreditationPersonalSelection :listPerson="oListPerson" @closeModalPrimary="closePerson" 
                        :ppKey="Number(pKey)" :ppPositionId="Number(positionId)" :ppProjectId="Number(projectId)" />
                </TabPanel>
                <TabPanel v-if="ssPermissionCourses.viewEnabled">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-book"></i>
                            <span class="font-bold white-space-nowrap">Gestión de cursos</span>
                        </div>
                    </template>
                    <AccreditationCourses @updateBase = "refreshBase"
                        :ppKey="Number(pKey)" :ppPositionId="Number(positionId)" :ppPersonId="Number(personId)" />
                </TabPanel>
                <TabPanel v-if="ssPermissionEmo.viewEnabled">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-heart"></i>
                            <span class="font-bold white-space-nowrap">Gestión de EMOs</span>
                        </div>
                    </template>
                    <AccreditationEmos @updateBase = "refreshBase" 
                        :ppKey="Number(pKey)" :ppPositionId="Number(positionId)" :ppPersonId="Number(personId)" />
                </TabPanel>
                <TabPanel v-if="ssPermissionContration.viewEnabled">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-bolt"></i>
                            <span class="font-bold white-space-nowrap">Contratación</span>
                        </div>
                    </template>
                    <AccreditationHiring :ppKey="Number(pKey)" :ppPositionId="Number(positionId)" :ppPersonId="Number(personId)" />
                </TabPanel>
                <TabPanel v-if="ssPermissionViaticum.viewEnabled">
                    <template #header>
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-car"></i>
                            <span class="font-bold white-space-nowrap">Itinerario y viáticos</span>
                        </div>
                    </template>
                    <AccreditationItinerary :ppKey="Number(pKey)" :ppPositionId="Number(positionId)" :ppPersonId="Number(personId)" />
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AccreditationPersonalSelection from './Personal/AccreditationPersonalSelection.vue';
import AccreditationCourses from './Courses/AccreditationCourses.vue';
import AccreditationEmos from './Emos/AccreditationEmos.vue';
import AccreditationHiring from './Hiring/AccreditationHiring.vue';
import AccreditationItinerary from './Itinerary/AccreditationItinerary.vue';
import PersonService from '@/service/PersonService';
import Crypto from '@/utilitarios/Crypto';
import ProjectService from '@/service/ProjectService';
import Utilitario from '@/utilitarios/Utilitario';

const router = useRouter();
const route = useRoute();

const positionService = new ProjectService();
const personService = new PersonService();

const ssPermissionSelection = ref({});
const ssPermissionCourses = ref({});
const ssPermissionEmo = ref({});
const ssPermissionContration = ref({});
const ssPermissionViaticum = ref({});

const filtros = ref({});
const oPerson = ref({});
const oProject = ref({});
const oPosition = ref({});
const oListPerson = ref([]);
const bCargando = ref(false);
const projectId = ref(0);
const positionId = ref(0);
const personId = ref(0);
const statusPersonName = ref('');
const progressAccreditation = ref([]);

const pKey = ref(0);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    projectId.value = route.params.projectId ? Crypto.Desencriptar(route.params.projectId) : 0;
    positionId.value = route.params.projectId ? Crypto.Desencriptar(route.params.positionId) : 0;
    personId.value = route.params.id ? Crypto.Desencriptar(route.params.id) : 0;
    pKey.value++;
    LoadPosition();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    const projectId = encodeURIComponent(route.params.projectId);
    router.push({ path: `/portal/acreditacion/${projectId}` });
}

const LoadPermission = () => {
    ssPermissionSelection.value = Utilitario.obtenerPermisos('PR00007');
    ssPermissionCourses.value = Utilitario.obtenerPermisos('PR00008')
    ssPermissionEmo.value = Utilitario.obtenerPermisos('PR00009');
    ssPermissionContration.value = Utilitario.obtenerPermisos('PR00010');
    ssPermissionViaticum.value = Utilitario.obtenerPermisos('PR00011');
}

const LoadPosition = async() => {
    bCargando.value = true;
    if(projectId.value > 0){
        const response = await positionService.GetProjectAccreditationService(projectId.value).then();
        if(response.code == 200){
            oProject.value = response.data;
            oPosition.value = response.data.jobPositions.find(x => x.jobPositionId == positionId.value);
            const personSelected = oPosition.value.persons.find(x => x.personId == personId.value);
            statusPersonName.value = personSelected ? personSelected?.statusPersonName : '';
            bCargando.value = false;
        }
    }    
}

const LoadPersonal = async() => {
    bCargando.value = true;
    if(personId.value > 0){
        const response = await personService.GetPersonByIdService(personId.value, positionId.value).then();
        if(response.code == 200){
            oPerson.value = response.data;
            oListPerson.value.push(oPerson.value);
            progressAccreditation.value = [{ label: 'Progreso de acreditación', color: '#34d399', value: response.data.accreditationPercentage }]
            pKey.value++;
            bCargando.value = false;
        }
    }    
}

const closePerson = (data) => {
    oListPerson.value = [];
    personId.value = data;
    LoadPersonal();
}

const refreshBase = (data) => {
    oListPerson.value = [];
    LoadPersonal();
    LoadPosition();
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
