<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="grid">
                <div class="col-12 lg:col-6 xl:col-4">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <!--<span class="block font-semibold">Cumplimiento</span>-->
                                <div class="text-900 font-bold text-2xl"><b>S/. {{ totalSoles || 0 }}</b></div>
                                <div class="font-medium text-sm">Gastos en soles</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-file text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <!--<span class="block font-semibold">Cumplimiento</span>-->
                                <div class="text-900 font-bold text-2xl"><b>S/. {{ totalSolesPendientRefund || 0 }}</b></div>
                                <div class="font-medium text-sm">Pendiente reembolso</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-star text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>S/. {{ totalSolesRefund || 0 }}</b></div>
                                <div class="font-medium text-sm">Reembolsado</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-star-fill text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>S/. {{ totalSolesEmployee || 0 }}</b></div>
                                <div class="font-medium text-sm">A trabajador</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-wrench text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>S/. {{ totalSolesProvider || 0 }}</b></div>
                                <div class="font-medium text-sm">A proveedor</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-truck text-green-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="grid">
                <div class="col-12 lg:col-6 xl:col-4">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>$ {{ totalDolars || 0 }}</b></div>
                                <div class="font-medium text-sm">Gastos en dólares</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-file text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <!--<span class="block font-semibold">Cumplimiento</span>-->
                                <div class="text-900 font-bold text-2xl"><b>$ {{ totalDolarsPendientRefund || 0 }}</b></div>
                                <div class="font-medium text-sm">Pendiente reembolso</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-star text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>$ {{ totalDolarsRefund || 0 }}</b></div>
                                <div class="font-medium text-sm">Reembolsado</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-star-fill text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>$ {{ totalDolarsEmployee || 0 }}</b></div>
                                <div class="font-medium text-sm">A trabajador</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-wrench text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-2">
                    <div class="card mb-0 pb-0 pt-3">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <div class="text-900 font-bold text-2xl"><b>$ {{ totalDolarsProvider || 0 }}</b></div>
                                <div class="font-medium text-sm">A proveedor</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style="width: 2.5rem; height: 2.5rem">
                                <i class="pi pi-truck text-cyan-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Tramos de transporte</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar itierario`" class="p-button-success ml-2" 
                                @click="openTravel(1)" v-if="ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtTransportSection" 
                    :value="oListTransportSection"
                    dataKey="transportSectionId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column header="" headerStyle="min-width: 1rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar tramo`" class="p-button-secondary-outlined ml-2" 
                                @click="openTravel(slotProps.data)" v-if="ssPermission.editEnabled" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar tramo`" text rounded class="p-button-danger ml-2" 
                                @click="UpdateStatusTravel(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="meanOfTransport" header="Medio transporte">
                        <template #body="slotProps">
                            {{ slotProps.data.meanOfTransport.name }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">Tramo {{ slotProps.data.index }}</div>
                        </template>
                    </Column>
                    <Column field="stateOrigin" header="Partida">
                        <template #body="slotProps">
                            {{ slotProps.data.provinceOrigin.name }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ Utilitario.formatoFecha(slotProps.data.originDate, 'dd/MM/yyyy HH:mm') }}</div>
                        </template>
                    </Column>
                    <Column field="provinceDestination" header="Destino" >
                        <template #body="slotProps">
                            {{ slotProps.data.provinceDestination.name }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ Utilitario.formatoFecha(slotProps.data.destinationDate, 'dd/MM/yyyy HH:mm') }}</div>
                        </template>
                    </Column>
                    <Column field="investment" header="Inversión" >
                        <template #body="slotProps">
                            {{ slotProps.data.currency.symbol }} {{ slotProps.data.investment }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.currency.name }}</div>
                        </template>
                    </Column>
                    <Column field="transportSectionStatus" header="Estado" >
                        <template #body="slotProps">
                            <div style="padding-bottom: 1rem;">
                                <Tag :value="slotProps.data.transportSectionStatus.name" severity="primary" />
                            </div>                            
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Viáticos</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar viático`" class="p-button-success ml-2" 
                                @click="openViaticum(1)" v-if="ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtViaticum" 
                    :value="oListViaticum"
                    dataKey="viaticumId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar viático`" class="p-button-secondary-outlined ml-2" 
                                @click="openViaticum(slotProps.data)" v-if="ssPermission.editEnabled" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar viático`" text rounded class="p-button-danger ml-2" 
                                @click="UpdateStatusViaticum(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="section" header="Tramo">
                        <template #body="slotProps">
                            {{ slotProps.data.section }}
                        </template>
                    </Column>
                    <Column field="breakfast" header="Concepto">
                        <template #body="slotProps">
                            {{ slotProps.data.conceptViaticum.name }} 
                        </template>
                    </Column>
                    
                    <Column field="Total" header="Pago a" >
                        <template #body="slotProps">
                            {{ slotProps.data.viaticumDestination.name }}
                        </template>
                    </Column>
                    <Column field="breakfast" header="Importe">
                        <template #body="slotProps">
                            {{ slotProps.data.currency.symbol }} {{ slotProps.data.amount }}
                            <div style="font-size: 7pt; padding-bottom: 1rem;">{{ slotProps.data.currency.name }}</div>
                        </template>
                    </Column>
                    <Column field="Status" header="Estado" >
                        <template #body="slotProps">
                            <div style="padding-bottom: 1rem;">
                                <Tag :value="slotProps.data.viaticumStatus.name" severity="secondary" />
                            </div>                            
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

    </div>

    <Dialog v-model:visible="modalManagmentTravel" modal :header="modalManagmentTravelText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oTravel.meanOfTransportId"
                                :options="oListMeansTransport"
                                optionLabel="name"
                                optionValue="meanOfTransportId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedMeanOfTransportId }"
                                />
                                <label for="lblMeanOfTransportId">Medio transporte</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListState" v-model="oTravel.stateOriginId"
                                    optionLabel="name" optionValue="stateId" class="w-full" @change="LoadProvince()" 
                                    :class="{ 'p-invalid' : validatedStateOriginId }" />
                                <label for="lblDepartamentoOrigen">Departamento Partida</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListProvince" v-model="oTravel.provinceOriginId"
                                    optionLabel="name" optionValue="provinceId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedProvinceOriginId }" />
                                <label for="lblProvinciaOrigen">Provincia partida</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpOriginDate" dateFormat="dd/mm/yy"
                                    v-model="oTravel.originDate"
                                    :class="{ 'p-invalid' : validatedOriginDate }" />
                                <label for="lbloriginDate">Fecha partida</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListState" v-model="oTravel.stateDestinationId"
                                    optionLabel="name" optionValue="stateId" class="w-full" @change="LoadProvinceDestination()" 
                                    :class="{ 'p-invalid' : validatedStateDestinationId }" />
                                <label for="lblDepartamentoOrigen">Departamento destino</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListProvinceDestination" v-model="oTravel.provinceDestinationId"
                                    optionLabel="name" optionValue="provinceId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedProvinceDestinationId }" />
                                <label for="lblProvinciaOrigen">Provincia destino</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" inputId="dtpDestinationDate" dateFormat="dd/mm/yy"
                                    v-model="oTravel.destinationDate"
                                    :class="{ 'p-invalid' : validatedDestinationDate }" />
                                <label for="lblDestinationDate">Fecha destino</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oTravel.currencyId"
                                :options="oListCurrency"
                                optionLabel="name"
                                optionValue="currencyId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedCurrencyId }"
                                />
                                <label for="lblCurrencyId">Moneda</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" >
                            <FloatLabel>
                                <InputNumber v-model="oTravel.investment" inputId="txtInvestment" 
                                    showButtons mode="decimal" :min="0" :max="100000" step="0.5" locale="en-US" :minFractionDigits="2" 
                                    :class="{ 'p-invalid' : validatedInvestment }" />
                                <label for="lblInvestment">Inversión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oTravel.transportSectionStatusId"
                                :options="oListTransportSectionStatus"
                                optionLabel="name"
                                optionValue="transportSectionStatusId"
                                class="w-full"
                                :class="{ 'p-invalid' : validatedTransportSectionStatusId }"
                                />
                                <label for="lblTransportSectionStatusId">Estado</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentTravel = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveTransportPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalManagmentViaticum" modal :header="modalManagmentViaticumText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtSection" autocomplete="off" 
                                        v-model="oViaticum.section" 
                                        :class="{ 'p-invalid' : validatedSection }" />
                                <label for="lblSection">Tramo</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown v-model="oViaticum.currencyId" :options="oListCurrency"
                                    optionLabel="name" optionValue="currencyId" class="w-full"
                                    :class="{ 'p-invalid' : validatedViaticumCurrencyId }" />
                                <label for="lblCurrencyId">Moneda</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown v-model="oViaticum.conceptViaticumId" :options="oListConceptViaticum"
                                    optionLabel="name" optionValue="conceptViaticumId" class="w-full"
                                    :class="{ 'p-invalid' : validatedConceptViaticumId }" />
                                <label for="lblConceptViaticumId">Concepto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" >
                            <FloatLabel>
                                <InputNumber v-model="oViaticum.amount" inputId="txtBreakfast" 
                                    showButtons mode="decimal" :min="0" :max="100000" step="0.5" locale="en-US" :minFractionDigits="2" 
                                    :class="{ 'p-invalid' : validatedBreakfast }" />
                                <label for="lblBreakfast">Importe</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown v-model="oViaticum.viaticumDestinationId" :options="oListViaticumDestination"
                                    optionLabel="name" optionValue="viaticumDestinationId" class="w-full"
                                    :class="{ 'p-invalid' : validatedViaticumDestinationId }" />
                                <label for="lblViaticumDestinationId">Pago a</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" v-if="oViaticum.viaticumDestinationId == 2">
                            <FloatLabel>
                                <InputText id="txtSection" autocomplete="off" 
                                        v-model="oViaticum.provider" 
                                        :class="{ 'p-invalid' : validatedProvider }" />
                                <label for="lblSection">Proveedor</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown
                                v-model="oViaticum.viaticumStatusId"
                                :options="oListViaticumStatus"
                                optionLabel="name"
                                optionValue="viaticumStatusId"
                                placeholder=""
                                class="w-full"
                                :class="{ 'p-invalid' : validatedViaticumStatusId }"
                                />
                                <label for="lblValidityTime">Estado</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentViaticum = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveViaticumPosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

</template>

<script setup>
import CommonService from '@/service/CommonService';
import CountryService from '@/service/CountryService';
import TransportSectionService from '@/service/TransportSectionService';
import ViaticumService from '@/service/ViaticumService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, watch, defineProps, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const transportSectionService = new TransportSectionService();
const commonService = new CommonService();
const countryService = new CountryService();
const viaticumService = new ViaticumService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});

const filtros = ref({});
const bCargando = ref(false);

const oListTransportSection = ref([]);

const oListState = ref([]);
const oListProvince = ref([]);
const oListCurrency = ref([]);

const validatedMeanOfTransportId = ref(false);
const validatedStateOriginId = ref(false);
const validatedProvinceOriginId = ref(false);
const validatedOriginDate = ref(false);
const validatedStateDestinationId = ref(false);
const validatedProvinceDestinationId = ref(false);
const validatedDestinationDate = ref(false);
const validatedCurrencyId = ref(false);
const validatedInvestment = ref(false);
const validatedTransportSectionStatusId = ref(false);

const oListStateDestination = ref([]);
const oListProvinceDestination = ref([]);

const modalManagmentTravel = ref(false);
const modalManagmentTravelText = ref('');
const oTravel = ref({});
const oListViaticumStatus = ref([]);

const oListMeansTransport = ref([]);
const oListTransportSectionStatus = ref([]);

const modalManagmentViaticum = ref(false);
const modalManagmentViaticumText = ref('');
const oListViaticum = ref([]);
const oListConceptViaticum = ref([]);
const oListViaticumDestination = ref([]);
const oViaticum = ref({});
const validatedSection = ref(false);
const validatedViaticumCurrencyId = ref(false);
const validatedConceptViaticumId = ref(false);
const validatedBreakfast = ref(false);
const validatedViaticumStatusId = ref(false);
const validatedViaticumDestinationId = ref(false);
const validatedProvider = ref(false);

const pPersonId = ref(0);
const pPositionId = ref(0);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppPersonId: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadTransportSection();
        LoadViaticum();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadCurrency();
    LoadTransportSectionStatus();
    LoadMeanOfTransport();
    LoadTransportSection();
    LoadState();
    LoadViaticum();
    LoadViaticumStatus();
    LoadConceptViaticum();
    LoadViaticumDestination();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00011');
}

const LoadCurrency = async() => {
    bCargando.value = true;
    const response = await commonService.GetCurrencyService().then();
    if(response.code == 200){
        oListCurrency.value = response.data;
        bCargando.value = false;
    }
}

const LoadConceptViaticum = async() => {
    bCargando.value = true;
    const response = await commonService.GetConceptViaticumService().then();
    if(response.code == 200){
        oListConceptViaticum.value = response.data;
        bCargando.value = false;
    }
}

const LoadViaticumDestination = async() => {
    bCargando.value = true;
    const response = await commonService.GetViaticumDestinationService().then();
    if(response.code == 200){
        oListViaticumDestination.value = response.data;
        bCargando.value = false;
    }
}

const LoadTransportSectionStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetTransportSectionStatusService().then();
    if(response.code == 200){
        oListTransportSectionStatus.value = response.data;
        bCargando.value = false;
    }
}

const LoadMeanOfTransport = async() => {
    bCargando.value = true;
    const response = await commonService.GetMeanOfTransportService() .then();
    if(response.code == 200){
        oListMeansTransport.value = response.data;
        bCargando.value = false;
    }
}

const LoadState = async() => {
    bCargando.value = true;
    const countryId = userStore.enterprise.countryId;
    const response = await countryService.GetState(countryId).then();
    if(response.code == 200){
        oListState.value = response.data;
        oListStateDestination.value = [ ...response.data ];
        bCargando.value = false;
    }
}

const LoadProvince = async() => {
    bCargando.value = true;
    const response = await countryService.GetProvince(oTravel.value.stateOriginId).then();
    if(response.code == 200){
        oListProvince.value = response.data;
        bCargando.value = false;
    }
}

const LoadProvinceDestination = async() => {
    bCargando.value = true;
    const response = await countryService.GetProvince(oTravel.value.stateDestinationId).then();
    if(response.code == 200){
        oListProvinceDestination.value = response.data;
        bCargando.value = false;
    }
}

const LoadTransportSection = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await transportSectionService.GetTransportSectionService(pPersonId.value, pPositionId.value) .then();
        if(response.code == 200){
            if(response.data){
                oListTransportSection.value = response.data.map((item, index) => {
                                    return { ...item, index: (index + 1) };
                                });
            }
            
            bCargando.value = false;
        }
    }
}

const LoadViaticum = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await viaticumService.GetViaticumService(pPersonId.value, pPositionId.value) .then();
        if(response.code == 200){
            oListViaticum.value = response.data;            
            bCargando.value = false;
        }
    }
}

const LoadViaticumStatus = async() => {
    bCargando.value = true;
    const response = await commonService.GetViaticumStatusService().then();
    if(response.code == 200){
        oListViaticumStatus.value = response.data;
        bCargando.value = false;
    }
}


const openTravel = (action) => {
    modalManagmentTravelText.value = action == 1 ? 'Nuevo tramo de viaje' : 'Editar tramo de viaje';
    
    if(action != 1){
        oTravel.value = { ...action };
        
        oTravel.value.meanOfTransportId = action.meanOfTransport.meanOfTransportId;
        oTravel.value.stateOriginId = action.stateOrigin.stateId;
        LoadProvince();
        oTravel.value.provinceOriginId = action.provinceOrigin.provinceId;
        oTravel.value.stateDestinationId = action.stateDestination.stateId;
        LoadProvinceDestination();
        oTravel.value.provinceDestinationId = action.provinceDestination.provinceId;
        oTravel.value.originDate = Utilitario.formatoDate(action.originDate);
        oTravel.value.destinationDate = Utilitario.formatoDate(action.destinationDate);
        oTravel.value.currencyId = action.currency.currencyId;
        oTravel.value.transportSectionStatusId = action.transportSectionStatus.transportSectionStatusId;
    }
    else{
        oTravel.value = {};
    }
    
    modalManagmentTravel.value = true;
}

const openViaticum = (action) => {
    modalManagmentViaticumText.value = action == 1 ? 'Nuevo viático' : 'Editar viático';
    
    if(action != 1){
        oViaticum.value = { ...action };
        oViaticum.value.currencyId = action.currency.currencyId;
        oViaticum.value.viaticumDestinationId = action.viaticumDestination.viaticumDestinationId;
        oViaticum.value.conceptViaticumId = action.conceptViaticum.conceptViaticumId;
        oViaticum.value.viaticumStatusId = action.viaticumStatus.viaticumStatusId;
    }
    else{
        oViaticum.value = {};
    }
    
    modalManagmentViaticum.value = true;
}

const SaveTransportPosition = async() => {
    if(await ValidateTransportPosition()){
       await CreateUpdateTransportPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateTransportPosition = async() => {
    const { ...model } = oTravel.value;
    const valid = ref(false);

    if(!model?.meanOfTransportId){
        validatedMeanOfTransportId.value = true;
        valid.value = true;
    }
    else{
        validatedMeanOfTransportId.value = false;
    }

    if(!model?.stateOriginId){
        validatedStateOriginId.value = true;
        valid.value = true;
    }
    else{
        validatedStateOriginId.value = false;
    }

    if(!model?.provinceOriginId){
        validatedProvinceOriginId.value = true;
        valid.value = true;
    }
    else{
        validatedProvinceOriginId.value = false;
    }

    if(!model?.originDate){
        validatedOriginDate.value = true;
        valid.value = true;
    }
    else{
        validatedOriginDate.value = false;
    }

    if(!model?.stateDestinationId){
        validatedStateDestinationId.value = true;
        valid.value = true;
    }
    else{
        validatedStateDestinationId.value = false;
    }

    if(!model?.provinceDestinationId){
        validatedProvinceDestinationId.value = true;
        valid.value = true;
    }
    else{
        validatedProvinceDestinationId.value = false;
    }

    if(!model?.destinationDate){
        validatedDestinationDate.value = true;
        valid.value = true;
    }
    else{
        validatedDestinationDate.value = false;
    }

    if(!model?.currencyId){
        validatedCurrencyId.value = true;
        valid.value = true;
    }
    else{
        validatedCurrencyId.value = false;
    }

    if(!model?.investment){
        validatedInvestment.value = true;
        valid.value = true;
    }
    else{
        validatedInvestment.value = false;
    }

    if(!model?.transportSectionStatusId){
        validatedTransportSectionStatusId.value = true;
        valid.value = true;
    }
    else{
        validatedTransportSectionStatusId.value = false;
    }

    return !valid.value;
}

const CreateUpdateTransportPosition = async() => {
    const { ...model } = oTravel.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        jobPositionId: pPositionId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el tramo de viaje?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await transportSectionService.CreateUpdateTransportSectionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el tramo de viaje correctamente`,
                    accept: async() => {
                        LoadTransportSection();
                        modalManagmentTravel.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar tramo de viaje:`, error);
        }
      }});
}

const UpdateStatusTravel = async(item) => {       
    const actionName = 'eliminar';
    const confirmationName = 'eliminó';
    const request = {
        transportSectionId: item.transportSectionId,
        status: false,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} el tramo de viaje?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await transportSectionService.UpdateStatusTransportSectionService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el tramo de viaje correctamente`,
                accept: async() => {
                    LoadTransportSection();
                    bCargando.value = false;
                }
            });
        }
      }
    });
}

const SaveViaticumPosition = async() => {
    if(await ValidateViaticumPosition()){
       await CreateUpdateViaticumPosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateViaticumPosition = async() => {
    const { ...model } = oViaticum.value;
    const valid = ref(false);

    if(!model?.section){
        validatedSection.value = true;
        valid.value = true;
    }
    else{
        validatedSection.value = false;
    }

    if(!model?.currencyId){
        validatedViaticumCurrencyId.value = true;
        valid.value = true;
    }
    else{
        validatedViaticumCurrencyId.value = false;
    }
    
    if(!model?.conceptViaticumId){
        validatedConceptViaticumId.value = true;
        valid.value = true;
    }
    else{
        validatedConceptViaticumId.value = false;
    }

    if(!model?.amount){
        validatedBreakfast.value = true;
        valid.value = true;
    }
    else{
        validatedBreakfast.value = false;
    }
    
    if(!model?.viaticumDestinationId){
        validatedViaticumDestinationId.value = true;
        valid.value = true;
    }
    else{
        validatedViaticumDestinationId.value = false;
    }
    
    if(model?.viaticumDestinationId == 2){
        if(!model?.provider){
            validatedProvider.value = true;
            valid.value = true;
        }
        else{
            validatedProvider.value = false;
        }
    }    

    if(!model?.viaticumStatusId){
        validatedViaticumStatusId.value = true;
        valid.value = true;
    }
    else{
        validatedViaticumStatusId.value = false;
    }

    return !valid.value;
}

const CreateUpdateViaticumPosition = async() => {
    const { ...model } = oViaticum.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        jobPositionId: pPositionId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar el viático de viaje?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await viaticumService.CreateUpdateViaticumService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el viático de viaje correctamente`,
                    accept: async() => {
                        LoadViaticum();
                        modalManagmentViaticum.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar viático de viaje:`, error);
        }
    }});
}

const UpdateStatusViaticum = async(item) => {
    const actionName = 'eliminar';
    const confirmationName = 'eliminó';
    const request = {
        viaticumId: item.viaticumId,
        status: false,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} el viático de viaje?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await viaticumService.UpdateStatusViaticumService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el viático de viaje correctamente`,
                accept: async() => {
                    LoadViaticum();
                    bCargando.value = false;
                }
            });
        }
      }
    });
}

  //#region Cálculo de los totales
  const totalSoles = computed(() => {
    //oListViaticum.value.reduce((sum, item) => sum + item.investment, 0);
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalDolars = computed(() => {
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalSolesPendientRefund = computed(() => {
    
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.transportSectionStatus.transportSectionStatusId == 5) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

                        
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.viaticumStatus.viaticumStatusId == 5) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalDolarsPendientRefund = computed(() => {
    
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.transportSectionStatus.transportSectionStatusId == 5) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

                        
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.viaticumStatus.viaticumStatusId == 5) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalSolesRefund = computed(() => {
    
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.transportSectionStatus.transportSectionStatusId == 6) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

                        
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.viaticumStatus.viaticumStatusId == 6) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalDolarsRefund = computed(() => {
    
    const tramosTotal = oListTransportSection.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.transportSectionStatus.transportSectionStatusId == 5) {
                                return sum + item.investment;
                            }
                            return sum;
                        }, 0);

                        
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.viaticumStatus.viaticumStatusId == 5) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return tramosTotal + viaticosTotal;
  });

  const totalSolesEmployee = computed(() => {
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.viaticumDestination.viaticumDestinationId == 1) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return viaticosTotal;
  });

  const totalDolarsEmployee = computed(() => {
    
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.viaticumDestination.viaticumDestinationId == 1) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return viaticosTotal;
  });

  const totalSolesProvider = computed(() => {
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 1 && item.viaticumDestination.viaticumDestinationId == 2) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return viaticosTotal;
  });

  const totalDolarsProvider = computed(() => {
    
    const viaticosTotal = oListViaticum.value.reduce((sum, item) => {
                            if (item.currency.currencyId === 2 && item.viaticumDestination.viaticumDestinationId == 2) {
                                return sum + item.amount;
                            }
                            return sum;
                        }, 0);

    return viaticosTotal;
  });
  //#endregion

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
