<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Datos personales</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <!-- <Button label="" :icon="oListPersonalData && oListPersonalData.length > 0 ? 'pi pi-pencil' : 'pi pi-plus'" 
                                v-tooltip.top="oListPersonalData && oListPersonalData.length > 0 ? `Editar datos personales` : `Registrar datos personales`" class="p-button-success ml-2" 
                                @click="openEditPersonal()" /> -->
                            <Button label="" :icon="`pi pi-plus`" v-tooltip.top="`Registrar datos personales`" class="p-button-success ml-2" 
                                @click="openEditPersonal()" v-if="oListPersonalData.length == 0 && ssPermission.createEnabled"/>
                            <Button label="" :icon="`pi pi-pencil`" v-tooltip.top="`Editar datos personales`" class="p-button-success ml-2" 
                                @click="openEditPersonal()" v-if="oListPersonalData && oListPersonalData.length > 0 && ssPermission.editEnabled"/>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListPersonalData"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="DNI" header="DNI" >
                        <template #body="slotProps">
                            <b v-tooltip.top="`Fecha Vencimiento:\n${ Utilitario.formatoFecha(slotProps.data.documentExpirationDate, 'dd/MM/yyyy') }`">{{ slotProps.data.documentNumber }}</b>
                        </template>
                    </Column>
                    <Column field="OriginCity" header="Ciudad origen">
                        <template #body="slotProps">
                            {{ slotProps.data.province?.name }}
                        </template>
                    </Column>
                    <Column field="OriginCity" header="Tipo trabajador">
                        <template #body="slotProps">
                            {{ slotProps.data.typeOfWorker?.name }}
                        </template>
                    </Column>
                    <Column field="OriginCity" header="Condición mina">
                        <template #body="slotProps">
                            {{ slotProps.data.mineCondition?.name }}
                        </template>
                    </Column>
                    <Column field="OriginCity" header="Puesto mina">
                        <template #body="slotProps">
                            {{ slotProps.data.minePosition }}
                        </template>
                    </Column>
                    <Column field="recordType" header="Antecedentes">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.recordType" :value="slotProps.data.recordType?.name || ''" severity="success" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <br><br>
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">EPPs</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtEPP" 
                    :value="oListPersonalData"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="shirtSize" header="Talla Camisa"></Column>
                    <Column field="pantsSize" header="Talla Pantalon"></Column>
                    <Column field="shoesSize" header="Zapatos"></Column>
                </DataTable>
            </div>
            
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Licencias para mineras</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar licencia`" class="p-button-success ml-2" 
                                @click="openLicenseMine(1)" v-if="oListPersonalData && oListPersonalData.length > 0 && ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                
                <DataTable ref="dtMiningLicense" 
                    :value="oListMiningLicense"
                    dataKey="miningLicenseId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar licencia`" class="p-button-secondary-outlined ml-2" 
                                @click="openLicenseMine(slotProps.data)" v-if="ssPermission.editEnabled" />
                            
                            <Button :icon="slotProps.data.status ? 'pi pi-times-circle' : 'pi pi-power-off'" 
                                    v-tooltip.top="slotProps.data.status ? 'Eliminar cuenta' : 'Activar cuenta'" 
                                    class="p-button-rounded mt-2" 
                                    :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'" 
                                    @click="UpdateStatusMiningLicense(slotProps.data)" 
                                    v-if="ssPermission.deleteEnabled"/>
                        </template>
                    </Column>
                    <Column field="enterprise" header="Minera" >
                        <template #body="slotProps">
                            {{ slotProps.data.enterprise.name || '' }}
                        </template>
                    </Column>
                    <Column field="enterprise" header="Acreditación de operadores" >
                        <template #body="slotProps">
                            {{ slotProps.data.operatorAccreditation.name || '' }}
                        </template>
                    </Column>
                    <Column field="issueDate" header="Fecha Emisión" >
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                </DataTable>
                
            </div>

            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Información bancaria</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar información bancaria`" class="p-button-success ml-2" 
                                @click="openInfoBank(1)" v-if="oListPersonalData && oListPersonalData.length > 0 && ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                
                <DataTable ref="dtInfoBank" 
                    :value="oListInfoBank"
                    dataKey="bankAccountId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar banco`" class="p-button-secondary-outlined ml-2" 
                            @click="openInfoBank(slotProps.data)" v-if="ssPermission.editEnabled" />
                            
                            <Button :icon="slotProps.data.status ? 'pi pi-times-circle' : 'pi pi-power-off'" 
                                    v-tooltip.top="slotProps.data.status ? 'Desactivar cuenta' : 'Activar cuenta'" 
                                    class="p-button-rounded mt-2" 
                                    :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'" 
                                    @click="UpdateStatusInfoBank(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="accountName" header="Cuenta" ></Column>
                    <Column field="currency" header="Moneda" >
                        <template #body="slotProps">
                            {{ slotProps.data.currency?.symbol }} {{ slotProps.data.currency.name }}
                        </template>
                    </Column>
                    <Column field="accountNumber" header="Número de cuenta" ></Column>
                    <Column field="interbankAccountNumber" header="Cuenta interbancaria (CCI)" ></Column>
                    <Column field="bankingEntity" header="Entidad bancaría">
                        <template #body="slotProps">
                            {{ slotProps.data.bankingEntity.name }}
                        </template>
                    </Column>
                    <Column field="status" header="Estado">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                
            </div>
            
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Escala salarial</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar escala salarial`" class="p-button-success ml-2" 
                                @click="openInfoSalary(1)" v-if="oListPersonalData && oListPersonalData.length > 0 && ssPermission.createEnabled" />
                        </div>
                    </template>
                </Toolbar>
                                
                <DataTable ref="dtInfoSalary" 
                    :value="oListSalary"
                    dataKey="salaryInformationId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar escala`" class="p-button-secondary-outlined ml-2"
                                 @click="openInfoSalary(slotProps.data)" v-if="ssPermission.editEnabled" />
                            <Button :icon="slotProps.data.status ? 'pi pi-times-circle' : 'pi pi-power-off'" 
                                    v-tooltip.top="slotProps.data.status ? 'Desactivar escala' : 'Activar escala'" 
                                    class="p-button-rounded mt-2" 
                                    :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'" 
                                    @click="UpdateStatusInfoSalary(slotProps.data)" v-if="ssPermission.deleteEnabled" />
                        </template>
                    </Column>
                    <Column field="scaleValidation" header="Validación escala">
                        <template #body="slotProps">
                            {{ slotProps.data.scaleValidation ? 'Si' : 'No' }}
                        </template>
                    </Column>
                    <Column field="salaryConcept" header="Concepto">
                        <template #body="slotProps">
                            {{ slotProps.data.salaryConcept.name }}
                        </template>
                    </Column>
                    <Column field="salaryDays" header="Días">
                        <template #body="slotProps">
                            {{ slotProps.data.salaryDays || '' }}
                        </template>
                    </Column>
                    <Column field="salaryAmount" header="Monto">
                        <template #body="slotProps">
                            {{ slotProps.data.salaryAmount || '' }}
                        </template>
                    </Column>
                    <Column field="costCenter" header="Centro costos">
                        <template #body="slotProps">
                            {{ slotProps.data.costCenter.name }}
                        </template>
                    </Column>
                    <Column field="status" header="Estado">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Record de servicios</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtProjectRecord" 
                    :value="oListProjectRecord"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="enterpriseName" header="Empresa"></Column>
                    <Column field="projectName" header="Proyecto">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.projectName }}</b>
                            <br>{{ slotProps.data.jobPositionName }}
                        </template>
                    </Column>
                    <Column field="projectMonthPeriodName" header="Mes período"></Column>
                    <Column field="projectYearPeriod" header="Año período"></Column>
                    <Column field="participationServices" header="% participación servicios"></Column>
                    <Column field="participationServicesAll" header="Récord histórico"></Column>
                    <Column field="parallelParticipationServices" header="% participación servicios paralelo"></Column>
                </DataTable>
            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" :ppDocumentId="'PERSON'" @updateBase = "refreshBase"
                    :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppPersonId="Number(pPersonId)" />
        </div>
    </div>
    
    <Dialog v-model:visible="modalPersonal" modal :header="oListPersonalData && oListPersonalData.length > 0 ? 'Editar persona' : 'Nueva persona'" :style="{ width: '45rem' }">
        <AccreditationPersonalEdit @closeModal="closeModalPersonal" 
            :ppPerson="oPersonData" :ppKey="Number(pKey)" :ppPositionId="Number(pPositionId)" :ppProjectId="Number(pProjectId)" /> 
    </Dialog>

    <Dialog v-model:visible="modalInfoBank" modal :header="modalInfoBankAction" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtAccountName" autocomplete="off" v-model="oInfoBank.accountName"
                                :class="{ 'p-invalid' : validatedAccountName }" />
                                <label for="lblAccountName">Nombres cuenta</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtAccountNumber" autocomplete="off" v-model="oInfoBank.accountNumber"
                                :class="{ 'p-invalid' : validatedAccountNumber }" />
                                <label for="lblAccountNumber">Número de cuenta</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <InputText id="txtInterbankAccountNumber" autocomplete="off" v-model="oInfoBank.interbankAccountNumber"
                                :class="{ 'p-invalid' : validatedInterbankAccountNumber }" />
                                <label for="lblInterbankAccountNumber">Número de cuenta interbancaria</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListCurrency" v-model="oInfoBank.currencyId"
                                    optionLabel="name" optionValue="currencyId" class="w-full" />
                                <label for="lblDocumentType">Moneda</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListBank" v-model="oInfoBank.bankingEntityId"
                                    optionLabel="name" optionValue="bankingEntityId" class="w-full" />
                                <label for="lblDocumentType">Entidad Bancaria</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalInfoBank = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveInfoBank()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalSalaryInformation" modal :header="modalSalaryInformationText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Checkbox v-model="oInfoSalary.scaleValidation" inputId="chkScaleValidation" name="chkScaleValidation" :binary="true"/>
                                <label for="lblScaleValidation" class="ml-3">Tiene escala salarial</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6 mt-3" v-if="oInfoSalary.scaleValidation">
                            <FloatLabel>
                                <Dropdown :options="oListSalaryConcept" v-model="oInfoSalary.salaryConceptId" :disabled="!oInfoSalary.scaleValidation"
                                    optionLabel="name" optionValue="salaryConceptId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedSalaryConcept }" />
                                <label for="lblConcepto">Concepto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" v-if="oInfoSalary.scaleValidation"></div>
                        <div class="field col-12 md:col-6" v-if="oInfoSalary.scaleValidation">
                            <FloatLabel>
                                <InputNumber v-model="oInfoSalary.salaryDays" inputId="txtSalaryDays" 
                                    showButtons mode="decimal" :min="0" :max="100" 
                                    :class="{ 'p-invalid' : validatedSalaryDays }" />
                                <label for="lblSalaryDays">Días</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" v-if="oInfoSalary.scaleValidation">
                            <FloatLabel>
                                <InputNumber v-model="oInfoSalary.salaryAmount" inputId="txtSalaryAmount" 
                                    showButtons mode="decimal" :min="0" :max="100000" step="0.5" locale="en-US" :minFractionDigits="2" 
                                    :class="{ 'p-invalid' : validatedSalaryAmount }" />
                                <label for="lblSalaryAmount">Monto</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6" v-if="oInfoSalary.scaleValidation">
                            <FloatLabel>
                                <Dropdown :options="oListCostCenter" v-model="oInfoSalary.costCenterId" 
                                    optionLabel="name" optionValue="costCenterId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedCostCenter }" />
                                <label for="lblCostCenter">Centro de Costos</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalSalaryInformation = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveInfoSalary()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="modalLicenseMine" modal :header="modalLicenseMineAction" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListCompany" v-model="oMiningLicense.enterpriseId"
                                    optionLabel="name" optionValue="enterpriseId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedEnterpriseIdMiningLicense }" />
                                <label for="lblShirtSize">Minera</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Dropdown :options="oListOperatorAccreditation" v-model="oMiningLicense.operatorAccreditationId"
                                    optionLabel="name" optionValue="operatorAccreditationId" class="w-full" 
                                    :class="{ 'p-invalid' : validatedOperatorAccreditationIdMiningLicense }" />
                                <label for="lblOperatorAccreditationIdMiningLicense">Acreditación de operadores</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar showIcon iconDisplay="input" v-model="oMiningLicense.issueDate"
                                    inputId="dtpIssueDate" dateFormat="dd/mm/yy" 
                                    :class="{ 'p-invalid' : validatedIssueDateMiningLicense }" />
                                <label for="lblIssueDate">Fecha emisión</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalLicenseMine = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveMiningLicense()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    <ConfirmDialog group="msgWarning">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, defineProps, defineEmits, watch } from 'vue';
import AccreditationPersonalEdit from './AccreditationPersonalEdit.vue';
import Utilitario from '@/utilitarios/Utilitario';
import ServiceRecordService from '@/service/ServiceRecordService';
import CommonService from '@/service/CommonService';
import BankAccountService from '@/service/BankAccountService';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import SalaryInformationService from '@/service/SalaryInformationService';
import MiningLicenseService from '@/service/MiningLicenseService';
import EnterpriseService from '@/service/EnterpriseService';
import DocumentService from '@/service/DocumentService';

const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const serviceRecordService = new ServiceRecordService();
const commonService = new CommonService();
const bankAccountService = new BankAccountService();
const salaryInformationService = new SalaryInformationService();
const miningLicenseService = new MiningLicenseService();
const enterpriseService = new EnterpriseService();
const documentService = new DocumentService();

const userStore = JSON.parse(store.state.datauser);
const ssPermission = ref({});

const filtros = ref({});
const bCargando = ref(false);
const oListDocumentsPersonal = ref([]);
const oListPersonalData = ref([]);
const oPersonData = ref([]);
const oListProjectRecord = ref([]);

const oListInfoBank = ref([]);
const oListCurrency = ref([]);
const oListBank = ref([]);
const oInfoBank = ref({
    bankAccountId: 0,
    currencyId: 1,
    bankingEntityId: 1
});

const modalPersonal = ref(false);
const modalInfoBank = ref(false);
const modalInfoBankAction = ref('');
const validatedAccountName = ref(false);
const validatedAccountNumber = ref(false);
const validatedInterbankAccountNumber = ref(false);

const modalSalaryInformation = ref(false);
const modalSalaryInformationText = ref('');
const oListSalary = ref([]);
const oListSalaryConcept = ref([]);
const oListCostCenter = ref([]);
const oInfoSalary = ref({
    salaryInformationId: 0,
    salaryConceptId: 1,
    costCenterId: 1
});
const validatedSalaryConcept = ref(false);
const validatedSalaryDays = ref(false);
const validatedSalaryAmount = ref(false);
const validatedCostCenter = ref(false);

const modalLicenseMine = ref(false);
const modalLicenseMineAction = ref('');

const oListMiningLicense = ref([]);
const oMiningLicense = ref({});
const validatedEnterpriseIdMiningLicense = ref(false);
const validatedOperatorAccreditationIdMiningLicense = ref(false);
const validatedIssueDateMiningLicense = ref(false);

const oListCompany = ref([]);
const oListOperatorAccreditation = ref([]);

const pKey = ref(0);
const pPositionId = ref(0);
const pProjectId = ref(0);
const pPersonId = ref(0);

const props = defineProps({
  listPerson: {
    type: Array,
    required: true
  },
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppProjectId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['closeModalPrimary']);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadPersonal();
        LoadRecordService();
        LoadInfoBank();
        LoadInfoSalary();
        LoadMiningLicense();
        LoadDocumentPersonal();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPermission();
    LoadPersonal();
    LoadRecordService();
    LoadCurrency();
    LoadBanking();
    LoadInfoBank();
    LoadInfoSalary();
    LoadSalaryConcept();
    LoadCostCenter();
    LoadMiningLicense();
    LoadEnterpriseByCountry();
    LoadDocumentPersonal();
    LoadOperatorAccreditationService();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPermission = () => {
    ssPermission.value = Utilitario.obtenerPermisos('PR00007');
}

//#region Personal    
const LoadPersonal = () => {
    oListPersonalData.value = props.listPerson;
    if(oListPersonalData.value && oListPersonalData.value.length > 0){
        oPersonData.value = oListPersonalData.value[0];
        pPersonId.value = oPersonData.value.personId;
    }
    
    pPositionId.value = props.ppPositionId;
    pProjectId.value = props.ppProjectId;
    pKey.value++;
    
}

const LoadRecordService = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await serviceRecordService.GetRecordServiceService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListProjectRecord.value = response.data;
            bCargando.value = false;
        } 
    }    
}

const LoadOperatorAccreditationService = async() => {
    bCargando.value = true;    
    const response = await commonService.GetOperatorAccreditationService().then();
    if(response.code == 200){
        oListOperatorAccreditation.value = response.data;
        bCargando.value = false;
    }   
}

const openEditPersonal = () => {
    if(oListPersonalData.value && oListPersonalData.value.length > 0){
        oPersonData.value = oListPersonalData.value[0];
        pKey.value++;
    }
    
    pPositionId.value = props.ppPositionId;
    pKey.value++;
    modalPersonal.value = true
}

const closeModalPersonal = (data) => {
    modalPersonal.value = false;
    LoadPersonal();
    pPersonId.value = data;
    emit('closeModalPrimary', data);
}
//#endregion

//#region Info Bank
const LoadCurrency = async() => {
    bCargando.value = true;
    const response = await commonService.GetCurrencyService().then();
    if(response.code == 200){
        oListCurrency.value = response.data;
        bCargando.value = false;
    }
}

const LoadBanking = async() => {
    bCargando.value = true;
    const response = await commonService.GetBankingService().then();
    if(response.code == 200){
        oListBank.value = response.data;
        bCargando.value = false;
    }
}

const openInfoBank = (action) => {
    modalInfoBankAction.value = action == 1 ? 'Nueva información bancaria' : 'Editar información bancaria';
    
    if(action != 1){
        oInfoBank.value = action;
        oInfoBank.value.currencyId = action.currency.currencyId;
        oInfoBank.value.bankingEntityId = action.bankingEntity.bankingEntityId;
    }
    else{
        oInfoBank.value = {
            bankAccountId: 0,
            currencyId: 1,
            bankingEntityId: 1
        };
    }
    
    modalInfoBank.value = true;
}

const LoadInfoBank = async() => {
    bCargando.value = true;
    if(pPersonId.value > 0){
        const response = await bankAccountService.GetBankAccountByPersonService(pPersonId.value).then();
        if(response.code == 200){
            oListInfoBank.value = response.data;
            bCargando.value = false;
        }
    }    
}

const SaveInfoBank = async() => {
    if(await ValidateInfoBank()){
       await CreateUpdateInfoBank();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateInfoBank = async() => {
    const { ...model } = oInfoBank.value;
    const valid = ref(false);

    if(!model?.accountName){
        validatedAccountName.value = true;
        valid.value = true;
    }
    else{
        validatedAccountName.value = false;
    }

    if(!model?.accountNumber){
        validatedAccountNumber.value = true;
        valid.value = true;
    }
    else{
        validatedAccountNumber.value = false;
    }
    
    if(!model?.interbankAccountNumber){
        validatedInterbankAccountNumber.value = true;
        valid.value = true;
    }
    else{
        validatedInterbankAccountNumber.value = false;
    }

    return !valid.value;
}

const CreateUpdateInfoBank = async() => {
    const { ...model } = oInfoBank.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la información bancaria?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await bankAccountService.CreateUpdateBankAccountService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la información bancaria correctamente`,
                    accept: async() => {
                        LoadInfoBank();
                        modalInfoBank.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Persona:`, error);
        }
      }});
}

const UpdateStatusInfoBank = async(item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';
    const request = {
        bankAccountId: item.bankAccountId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} la cuenta bancaria ${item.accountName}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await bankAccountService.UpdateStatusBankAccountService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} la cuenta bancaria ${item.accountName} correctamente`,
                accept: async() => {
                    LoadInfoBank();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 

const openLicenseMine = (action) => {
    modalLicenseMineAction.value = action == 1 ? 'Nueva licencia' : 'Editar licencia';
    
    if(action != 1){
        oMiningLicense.value = action;
        oMiningLicense.value.enterpriseId = action.enterprise.enterpriseId;
        oMiningLicense.value.operatorAccreditationId = action.operatorAccreditation.operatorAccreditationId;
        oMiningLicense.value.issueDate = Utilitario.formatoDate(action.issueDate);
    }
    else{
        oMiningLicense.value = {
            miningLicenseId: 0,
            issueDate: null
        };
    }
    
    modalLicenseMine.value = true;
}
//#endregion

//#region Salary Information
const LoadInfoSalary = async() => {
    bCargando.value = true;
    if(pPersonId.value > 0){
        const response = await salaryInformationService.GetSalaryInformationService(pPersonId.value).then();
        if(response.code == 200){
            oListSalary.value = response.data;
            bCargando.value = false;
        }
    }    
}

const openInfoSalary = (action) => {
    modalSalaryInformationText.value = action == 1 ? 'Nueva escala salarial' : 'Editar escala salarial';
    
    if(action != 1){
        oInfoSalary.value = action;
        oInfoSalary.value.salaryConceptId = action.salaryConcept.salaryConceptId;
        oInfoSalary.value.costCenterId = action.costCenter.costCenterId;
    }
    else{
        oInfoSalary.value = {
            salaryInformationId: 0            
        };
    }
    
    modalSalaryInformation.value = true;
}

const LoadSalaryConcept = async() => {
    bCargando.value = true;
    const response = await commonService.GetSalaryConceptService().then();
    if(response.code == 200){
        oListSalaryConcept.value = response.data;
        bCargando.value = false;
    }
}

const LoadCostCenter = async() => {
    bCargando.value = true;
    const response = await commonService.GetCostCenterService().then();
    if(response.code == 200){
        oListCostCenter.value = response.data;
        bCargando.value = false;
    }
}

const SaveInfoSalary = async() => {
    if(await ValidateInfoSalary()){
       await CreateUpdateInfoSalary();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateInfoSalary = async() => {
    const { ...model } = oInfoSalary.value;
    const valid = ref(false);

    if(model?.scaleValidation){
        if(!model?.salaryConceptId){
            validatedSalaryConcept.value = true;
            valid.value = true;
        }
        else{
            validatedSalaryConcept.value = false;
        }

        if(!model?.salaryDays){
            validatedSalaryDays.value = true;
            valid.value = true;
        }
        else{
            validatedSalaryDays.value = false;
        }

        if(!model?.salaryAmount){
            validatedSalaryAmount.value = true;
            valid.value = true;
        }
        else{
            validatedSalaryAmount.value = false;
        }

        if(!model?.costCenterId){
            validatedCostCenter.value = true;
            valid.value = true;
        }
        else{
            validatedCostCenter.value = false;
        }
    }

    return !valid.value;
}

const CreateUpdateInfoSalary = async() => {
    const { ...model } = oInfoSalary.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };

    if(!request.scaleValidation){
        request.salaryConceptId = 0;
        request.salaryDays = 0;
        request.salaryAmount = 0;
        request.costCenterId = 0;
    }
    
    confirm.require({
      message: `¿Está seguro de guardar la escala salarial?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await salaryInformationService.CreateUpdateSalaryInformationService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la escala salarial correctamente`,
                    accept: async() => {
                        LoadInfoSalary();
                        modalSalaryInformation.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Escala salarial:`, error);
        }
      }});
}

const UpdateStatusInfoSalary = async(item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';
    const request = {
        salaryInformationId: item.salaryInformationId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} la escala salarial${ item.salaryConcept.name ? ' de ' + item.salaryConcept.name : ''}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await salaryInformationService.UpdateStatusSalaryInformationService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} la escala salarial ${item.salaryConcept.name} correctamente`,
                accept: async() => {
                    LoadInfoSalary();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 
//#endregion

//#region Mining License
const LoadEnterpriseByCountry = () => {
    const countryId = userStore.enterprise.countryId;
    enterpriseService.GetEnterpriseByCountryService(countryId, userStore.user.idUserType).then(
        response => {
            oListCompany.value = response.data;
        }
    );
}

const LoadMiningLicense = async() => {
    bCargando.value = true;
    if(pPersonId.value > 0){
        const response = await miningLicenseService.GetMiningLicenseByPersonIdService(pPersonId.value).then();
        if(response.code == 200){
            oListMiningLicense.value = response.data.filter(x => x.status);
            bCargando.value = false;
        }
    }    
}

const SaveMiningLicense = async() => {
    if(await ValidateMiningLicense()){
       await CreateUpdateMiningLicense();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateMiningLicense = async() => {
    const { ...model } = oMiningLicense.value;
    const valid = ref(false);

    if(!model?.enterpriseId){
        validatedEnterpriseIdMiningLicense.value = true;
        valid.value = true;
    }
    else{
        validatedEnterpriseIdMiningLicense.value = false;
    }
    
    if(!model?.operatorAccreditationId){
        validatedOperatorAccreditationIdMiningLicense.value = true;
        valid.value = true;
    }
    else{
        validatedOperatorAccreditationIdMiningLicense.value = false;
    }

    if(!model?.issueDate){
        validatedIssueDateMiningLicense.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDateMiningLicense.value = false;
    }
    
    return !valid.value;
}

const CreateUpdateMiningLicense = async() => {
    const { ...model } = oMiningLicense.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la licencia?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await miningLicenseService.CreateUpdateMiningLicenseService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la información de licencia correctamente`,
                    accept: async() => {
                        LoadMiningLicense();
                        modalLicenseMine.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Licencia:`, error);
        }
      }});
}

const UpdateStatusMiningLicense = async(item) => {
    const request = {
        miningLicenseId: item.miningLicenseId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de eliminar la licencia de ${item.enterprise.name}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await miningLicenseService.UpdateStatusMiningLicenseService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se eliminó la licencia ${item.enterprise.name} correctamente`,
                accept: async() => {
                    LoadMiningLicense();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 

//#endregion

//#region Documents
const LoadDocumentPersonal = async() => {
    bCargando.value = true;    
    if(pPersonId.value > 0 && pPositionId.value > 0){
        const response = await documentService.GetDocumentPersonService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListDocumentsPersonal.value = response.data.map((item, index) => ({
                ...item,
                documentIndex: index + 1
            }));
            pKey.value++;
            bCargando.value = false;
        } 
    }    
}

const refreshBase = () => {
    LoadDocumentPersonal();
}
//#endregion

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
